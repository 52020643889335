
export interface SnackbarData {
  message: string;
  actionType: SnackbarAction;
  values: any;
  isLoginPage: boolean;
  acknowledgeValue: string;
}

export enum SnackbarAction {
  ResendResetPasswordEmail = 'Resend Email',
  DisconnectGusto = "Disconnect",
  Okay = 'Okay',
  Retry = 'Retry'
}
