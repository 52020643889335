import { Injectable } from '@angular/core';
import * as AuthenticationContext from "adal-angular";
import * as microsoftTeams from "@microsoft/teams-js";
import { environment } from '../../../environments/environment';
import { Keys } from '../../modals/keys/login';
import { LocalStorageService } from '../localstorage/localStorage.service';

// An authentication service that uses the ADAL.js and Teams.js library to sign in users with
// their AAD account. This leverages the AAD v1 endpoint.

@Injectable({ providedIn: 'root' })
export class TeamsAuthService {
  public applicationConfig: any;
  public authContext: AuthenticationContext;

  private tId: String;
  private loginHint: String;

  constructor(private localStorageService: LocalStorageService) {
    // Initialize the Teams SDK
    microsoftTeams.initialize();

    // Check for any context information supplied via our QSPs
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const tenantId = params["tenantId"] || "common";

    // Configure ADAL
    this.applicationConfig = {
      tenant: tenantId,
      clientId: environment.microsoftTeamsAADClientId,
      endpoints: {
        api: environment.microsoftTeamsAADClientId,
      },
      redirectUri: `${window.location.origin}/teams/context/login`,
      cacheLocation: "localStorage",
      navigateToLoginRequestUrl: false,
    };

    this.authContext = new AuthenticationContext(this.applicationConfig);
  }

  login() {
    return new Promise((resolve, reject) => {
      this.ensureLoginHint().then((data) => {
        // Start the login flow
        microsoftTeams.authentication.authenticate({
          url: `${window.location.origin}/teams/context/login?tid=${this.tId}&loginHint=${this.loginHint}`,
          width: 600,
          height: 535,
          successCallback: (result) => {
            this.localStorageService.remove(Keys.LoginKeys.SsoKeys.MSTeamKeys.AuthSuccessCallback);
            resolve({ result: result, data: data });
          },
          failureCallback: (reason) => {
            var localData = this.localStorageService.get(Keys.LoginKeys.SsoKeys.MSTeamKeys.AuthSuccessCallback);
            this.localStorageService.remove(Keys.LoginKeys.SsoKeys.MSTeamKeys.AuthSuccessCallback);

            if (localData) {
              var jsonLocalData = JSON.parse(localData);

              if (jsonLocalData.createdOn && (((new Date().getTime() - jsonLocalData.createdOn) / 60000) <= 5)) {
                resolve({ result: localData, data: data })
              } else {
                reject(reason);
              }
            } else {
              reject(reason);
            }
          },
        });
      });
    });
  }

  logout() {
    this.authContext.logOut();
  }

  getLoginContextDeepLink() {
    return new Promise((resolve, reject) => {
      microsoftTeams.getContext((context) => {
        resolve(context);
      })
    })
  }

  ensureLoginHint() {
    return new Promise((resolve, reject) => {
      microsoftTeams.getContext((context) => {
        const scopes = encodeURIComponent(
          "email openid profile offline_access User.Read"
        );

        var extraQueryParameters = ``;

        if (context.loginHint) {
          extraQueryParameters = `prompt=consent&scope=${scopes}&login_hint=${encodeURIComponent(
            context.loginHint
          )}`;
        } else {
          extraQueryParameters = `prompt=consent&scope=${scopes}`;
        }

        this.authContext.config.extraQueryParameter = extraQueryParameters
        this.tId = context.tid;
        this.loginHint = context.loginHint;

        resolve(context?.subEntityId);
      });
    });
  }
}
