import { RecognitionTag } from '../../modals/recogntion.tags';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })

export class RecognitionFilteringService {
  private recognitionFilterList: BehaviorSubject<string> = new BehaviorSubject(null);
  constructor(private router: Router) { }

  private modifierFilterList(filterList: RecognitionTag[]): any[] {
    let newFilterList = [];
    filterList.forEach((element) => { newFilterList.push({ identifier: element.identifier, type: element.type, tag: element.tag, filterType: element?.filterType, subType : element.subType }) });
    return newFilterList;
  }

  setRecognitionFilterListValueUsingList(filterList: RecognitionTag[]) {
    let modifiedFilterList = this.modifierFilterList(filterList);
    let encodedValues = btoa(unescape(encodeURIComponent(JSON.stringify(modifiedFilterList) as string)));
    this.recognitionFilterList.next(encodedValues);
  }

  public setRecognitionFilterValue(filter: RecognitionTag = null, remove: boolean = false, removeAll: boolean = false) {
    let isChanged: boolean = false;
    let value: string = null;
    if (removeAll) {
      isChanged = true;
      value = btoa(unescape(encodeURIComponent(JSON.stringify([]) as string)));
    }
    else if (filter) {
      let decodedValue = this.recognitionFilterList.value;
      let encodedValue: RecognitionTag[] = decodedValue ? JSON.parse(decodeURIComponent(escape(window.atob(decodedValue)))) : [];
      if (!remove) {
        if (encodedValue.findIndex((element) => { return element.identifier == filter.identifier }) < 0) {
          isChanged = true;
          encodedValue.push(filter);
        }
      }
      else {
        let priorLength = encodedValue.length;
        encodedValue = encodedValue.filter((element) => { return element.identifier != filter.identifier });
        let currentLength = encodedValue.length;
        isChanged = priorLength > currentLength;
      }

      if (encodedValue?.length > 0) {
        let modifiedFilterList = this.modifierFilterList(encodedValue);
        value = btoa(unescape(encodeURIComponent(JSON.stringify(modifiedFilterList) as string)));
      }
      else {
        value = null;
      }

    }
    if (isChanged) {
      if (value?.length > 0) {
        this.recognitionFilterList.next(value);
        setTimeout(() => { this.router.navigate(['account/feed'], { queryParams: { filtering: value } }); });
      }
      else {
        this.router.navigate(['account/feed']);
      }
    }
  }

  public getRecognitionFilterListValue(): Observable<string> {
    return this.recognitionFilterList.asObservable();
  }
}
