export class RsaEncryptPolicy {
    readonly encrypt: boolean = false;
    readonly type: RsaEncryptType;
    readonly params: string[] = [];

    constructor(config?: { encrypt: boolean, type: RsaEncryptType, params: string[] }) {
        if (config?.encrypt) {
            this.encrypt = true;
            this.type = config?.type ?? RsaEncryptType.Class;
            this.params = [...this.params, ...(config.params ?? [])];
        }
    }
}

export enum RsaEncryptType {
    Class = 'Class',
    'Param' = 'Param'
}
