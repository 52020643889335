import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from './reducers';
import { userSelector, accountSelector, loadingConfig, walletConfig, notificationBarDataSelector, sendRewardSelector, insightDashboardFilterSelector, marketplaceFilterSelector } from './selectors';

@Injectable({
    providedIn: 'root'
})
export class StoreService {
    constructor(private store: Store<AppState>) { }

    getStore(): Store<AppState> {
        return this.store;
    }

    getUser() {
        return this.getStore().pipe(select(userSelector));
    }

    getAccount() {
        return this.getStore().pipe(select(accountSelector));
    }

    getLoadingConfig() {
        return this.getStore().pipe(select(loadingConfig));
    }

    getWalletConfig() {
        return this.getStore().pipe(select(walletConfig));
    }

    getNotificationBarData() {
        return this.getStore().pipe(select(notificationBarDataSelector));
    }

    getSendRewardConfig = () => this.getStore().pipe(select(sendRewardSelector));
    
    getInsightsDashboardFilters = () => this.getStore().pipe(select(insightDashboardFilterSelector));
    
    getMarketplaceFilter = () => this.getStore().pipe(select(marketplaceFilterSelector));
}