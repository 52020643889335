import { I } from '@angular/cdk/keycodes';
import { Component, OnInit, Inject, Input, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountApiService, ApiService } from '../../providers/api.service';
import { SnackBarService } from '../../providers/snackbar/snackbar.service';

@Component({
  selector: 'hrSystem-disconnection-confirmation',
  templateUrl: './hrSystem.disconnect.confirm.component.html',
  styleUrls: ['./hrSystem.disconnect.confirm.component.scss']
})

export class HrSystemDisconnectConfirmationComponent {
  public data: any;
  public hrSystemName: string;
  public hrSystem: HRSystem;
  public processing: boolean;

  constructor(private dialogRef: MatDialogRef<HrSystemDisconnectConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) data: any, public _snackBarService: SnackBarService, private _accountApiService: AccountApiService) {
    this.hrSystemName = data?.hrSystemName ?? "Gusto";
    this.hrSystem = data.hrSystem ?? HRSystem.Bamboo
    this.processing = false;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  openSnackbar(message: string) {
    this._snackBarService?.openSnackBar(message);
  }

  disconnect() {
    if (this.hrSystem == HRSystem.Gusto)
      this.disconnectGustoHr()
    else if (this.hrSystem == HRSystem.Bamboo)
      this.disconnectBambooHr()
  }

  disconnectGustoHr() {
    this.processing = true;
    this._accountApiService.disconnectGustoHr()
      .subscribe((response) => {
        this.successResponse("Connection between Wishlist and Gusto is successfully disconnected.");
      }, (error) => {
        this.errorResponse(error.message);
      });
  }
  disconnectBambooHr() {
    this.processing = true;
    this._accountApiService.disconnectBambooHr()
      .subscribe((response) => {
        this.successResponse("Connection between Wishlist and Bamboo Hr is successfully disconnected.")
      }, (error) => {
        this.errorResponse(error.message)
      });
  }

  successResponse(message: string = '') {
    this.openSnackbar(message);
    this.processing = false;
    this.closeDialog()
  }

  errorResponse(message: string = '') {
    this.processing = false;
    this.openSnackbar(message);
    this.closeDialog();
  }
}

export enum HRSystem {
  Gusto = 'Gusto',
  Bamboo = 'BambooHR',
}
