import { Injectable, } from "@angular/core";
import { AccountApiService } from "../api.service";
import { environment } from "../../../environments/environment";
import { UserRole } from './../../modals/keys/variable.keys';
import { StoreService } from "../../store/store.service";
import User from "../../modals/user";
import Account from "../../modals/account";
@Injectable({ providedIn: 'root' })

export class UserTrackingService {
  private isUserTrackingEnable: boolean;
  readonly EMPLOYEE = UserRole.Employee;
  readonly ADMINISTRATOR = UserRole.Administrator;
  readonly REWARD_SENDER = UserRole.RewardSender;
  readonly PROGRAM_MANAGER = UserRole.ProgramManager;
  private intervalRef: NodeJS.Timeout;
  private restCount: number = 0;
  private queuedRequest: any[] = [];
  private batchSize: number = 10;
  private resetIntervalsInSec: number = 60;
  private user: User;
  private account: Account;

  constructor(private store: StoreService, private _accountApiService: AccountApiService) {
    this.store.getUser().subscribe(user => this.user = user);
    this.store.getAccount().subscribe(account => this.account = account);
    this.isUserTrackingEnable = environment.production;
    if (this.isUserTrackingEnable)
      this.startRequestInterval()
  }

  getUserConfigDetails() {
    let userConfigData = {
      userGuid: this.user?.userGuid,
      userEmail: this.user?.email,
      accountId: this.account.accountId,
      department: this.user.department,
      employeeRole: this.getUserRole(),
      operatingSystem: this.getOperatingSystemName(),
      browser: this.getBrowserName()
    }
    return userConfigData;
  }

  private getOperatingSystemName(): string {

    let operatingSystem = "Unknown";
    {
      let userAgent = window.navigator.userAgent || window.navigator.vendor || window['opera'];
      let platform = window.navigator.platform;

      let macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
      let windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
      let iosPlatforms = ['iPhone', 'iPad', 'iPod'];


      if (macosPlatforms.indexOf(platform) !== -1) {
        operatingSystem = 'Mac OS';
      }
      else if (iosPlatforms.indexOf(platform) !== -1) {
        operatingSystem = 'iOS';
      }
      else if (/windows phone/i.test(userAgent)) {
        operatingSystem = "Windows Phone";
      }
      else if (windowsPlatforms.indexOf(platform) !== -1) {
        operatingSystem = 'Windows';
      }
      else if (/Android/i.test(userAgent)) {
        operatingSystem = 'Android';
      }
      else if (!operatingSystem && /Linux/.test(platform)) {
        operatingSystem = 'Linux';
      }
    }
    return operatingSystem;
  }

  private getBrowserName(): string {

    let browserName = "Unknown";
    {
      let userAgent = window.navigator.userAgent || window.navigator.vendor || window['opera'];

      let chrome = /.*(Chrome\/).*(Safari\/).*/g;
      let firefox = /.*(Firefox\/).*/g;
      let safari = /.*(Version\/).*(Safari\/).*/g;
      let opera = /.*(Chrome\/).*(Safari\/).*(OPR\/).*/g;
      let edge = /.*(Chrome\/).*(Safari\/).*(Edge\/).*/g;
      let ie = /.*(Trident\/7).*(rv:).*/g;

      if (opera.exec(userAgent)) {
        browserName = "Opera";
      }
      else if (edge.exec(userAgent)) {
        browserName = "Edge";
      }
      else if (chrome.exec(userAgent)) {
        return "Chrome";
      }
      else if (safari.exec(userAgent)) {
        browserName = "Safari";
      }
      else if (firefox.exec(userAgent)) {
        browserName = "Firefox";
      }
      else if (ie.exec(userAgent)) {
        browserName = "Internet Explorer";
      }
    }
    return browserName;
  }

  private getUserGuid() {
    return this.user.userGuid;
  }



  private getUserRole(): string {
    let roles = this.user && this.user.roles ? this.user.roles : '';
    let userRole: string = this.EMPLOYEE;
    {
      if (roles.indexOf(UserRole.Administrator) > -1 || roles.indexOf(UserRole.FinanceAdministrator) > -1) {
        userRole = 'Administrator';
      }
      else if (roles.indexOf(this.PROGRAM_MANAGER) > -1) {
        userRole = 'ProgramAdmin';
      }
      else if (roles.indexOf(this.REWARD_SENDER) > -1) {
        userRole = 'RewardSender';
      }
    }
    return userRole;
  }

  private async submitRequestOfLogEventData(logEventData: LogEventData) {
    if (logEventData.event && logEventData.description) {
      let userConfigData = this.getUserConfigDetails();
      if (userConfigData) {

        let eventProperties: any = {};
        if (logEventData.attribute) {
          eventProperties = logEventData.attribute
        }
        eventProperties.userEmail = userConfigData.userEmail;
        eventProperties.account = userConfigData.accountId;
        eventProperties.employeeType = userConfigData.employeeRole;
        eventProperties.department = userConfigData.department ? userConfigData.department : 'None';
        eventProperties.operatingSystem = userConfigData.operatingSystem;
        eventProperties.browser = userConfigData.browser;
        eventProperties.description = logEventData.description ? logEventData.description : 'None';

        let event: any = {
          user_id: userConfigData.userGuid,
          event_type: logEventData.event,
          event_properties: eventProperties,
          time: new Date().getTime(),
          os_name: userConfigData.browser,
          device_model: userConfigData.operatingSystem,
          language: 'English'
        };

        this.queuedRequest = [...this.queuedRequest, event];
        if (event.event_type == 'Logout') {
          this.sendAnalyticsBatchRequest();
        } else {
          this.restCount = 0;
          if (!this.intervalRef) {
            this.startRequestInterval();
          }
        }
      }
    }
  }

  startRequestInterval() {
    this.intervalRef = setInterval(() => { this.sendAnalyticsBatchRequest(); }, 1000);
  }

  async sendAnalyticsBatchRequest() {
    let batchRequest = this.queuedRequest.splice(0, this.batchSize);
    if (batchRequest.length > 0) {
      this._accountApiService.userEventTracking(batchRequest).subscribe();
    } else {
      this.restCount += 1;
    }
    if (this.restCount > this.resetIntervalsInSec && batchRequest.length == 0) {
      clearInterval(this.intervalRef);
      this.intervalRef = null;
      this.restCount = 0;
    }
  }

  public async initUserTracking(logEventData: LogEventData) {
    if (this.isUserTrackingEnable)
    {
      this.submitRequestOfLogEventData(logEventData);
    }
  }
}

interface LogEventData {
  event: string;
  description: string;
  attribute: any;
}
