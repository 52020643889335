import { Action } from '@ngrx/store';
import { Recognition } from '../../../modals/recognition';

export class RecognitionRenderFilterQueryParams {
  pageIndex: number;
  pageSize: number;
  filters: string;
}

export enum RecognitionRenderFilterActionTypes {
  RecognitionRenderFilterRequested = "[Recognition_Render_Filter] recognition render requested",
  RecognitionRenderFilterLoaded = "[Recognition_Render_Filter] recognition render loaded",
  RecognitionRenderFilterUpdated = "[Recognition_Render_Filter]  recognition render updated",
  RecognitionRenderFilterDeleted = "[Recognition_Render_Filter] recognition render deleted",
  RecognitionRenderFilterError = "[Recognition_Render_Filter] recognition render loading error",
  RecognitionRenderFilterResetRequested = "[Recognition_Render_Filter] recognition render reset requested",
  RecognitionRenderFilterResetCompleted = "[Recognition_Render_Filter] recognition render reset Completed",
}

export class RecognitionRenderFilterRequested implements Action {
  type = RecognitionRenderFilterActionTypes.RecognitionRenderFilterRequested;
  constructor(public payload: { query: RecognitionRenderFilterQueryParams, loading: boolean }) {
  }
}

export class RecognitionRenderFilterLoaded implements Action {
  type = RecognitionRenderFilterActionTypes.RecognitionRenderFilterLoaded;
  constructor(public payload: { data: Recognition[], hasNextPage: boolean, loading: boolean }) {
  }
}

export class RecognitionRenderFilterLoadingError implements Action {
  type = RecognitionRenderFilterActionTypes.RecognitionRenderFilterError;
  constructor(public payload: { errorMessage?: string }) {
  }
}

export class RecognitionRenderFilterUpdated implements Action {
  type = RecognitionRenderFilterActionTypes.RecognitionRenderFilterUpdated;
  constructor(public payload: { recognitionId: string, recognition: Recognition, hasNextPage: boolean }) {
  }
}

export class RecognitionRenderFilterDeleted implements Action {
  type = RecognitionRenderFilterActionTypes.RecognitionRenderFilterDeleted;
  constructor(public payload: { recognitionId: string, hasNextPage: boolean }) {
  }
}

export class RecognitionRenderFilterResetRequested implements Action {
  type = RecognitionRenderFilterActionTypes.RecognitionRenderFilterResetRequested;
  constructor() { }
}

export class RecognitionRenderFilterResetCompleted implements Action {
  type = RecognitionRenderFilterActionTypes.RecognitionRenderFilterResetCompleted;
  constructor(public payload: { data: Recognition[], hasNextPage: boolean, loading: boolean }) {
  }
}

export type RecognitionRenderFilterActions = RecognitionRenderFilterRequested | RecognitionRenderFilterLoaded | RecognitionRenderFilterLoadingError | RecognitionRenderFilterUpdated | RecognitionRenderFilterDeleted;
