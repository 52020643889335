import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition } from '@angular/material/snack-bar';
import { ToastComponent } from './toast/toast.component';
import { SnackBarService } from '../snackbar/snackbar.service';
import { WindowResizeService } from '../window.resize/window.resize.service';
import { Router } from '@angular/router';
import { SnackbarAction } from '../../modals/snackbar';
import { ToastCloseService } from './toaster.close.service';
import { UtilityService } from '../utility/utility.service';

@Injectable({ providedIn: 'root' })
export class ToastService {

  constructor(private toast: MatSnackBar, private router: Router, private snackBarService: SnackBarService, private windowResizeService: WindowResizeService, private toastCloseService: ToastCloseService, private utilityService: UtilityService) { }

  private isAdminUrl(): boolean {
    let url = this.router.url;
    let splitUrl = url.split(/[/?]/);
    return (splitUrl[2]?.indexOf('admin') > -1);
  }

  private isLoginPageUrl(): boolean {
    let url = this.router.url;
    let splitUrl = url.split(/[/?]/);
    return ((splitUrl[2]?.indexOf('login') > -1) || (splitUrl[2]?.indexOf('resetpassword') > -1) || (splitUrl[2]?.indexOf('onboarding') > -1));
  }

  public openToast(message: string, actionType: SnackbarAction = null, values: any = null, align: MatSnackBarHorizontalPosition = 'right', onlyToaster: boolean = false): void {
    if (this.windowResizeService.isDesktop() && (this.isAdminUrl() || this.isLoginPageUrl() || onlyToaster)) {
      let toastConfig = new MatSnackBarConfig();
      toastConfig.verticalPosition = 'bottom';
      toastConfig.panelClass = ['toaster-component'];
      toastConfig.duration = 6000000;

      if (this.isAdminUrl()) {
        toastConfig.panelClass = ['toaster-component'];
        toastConfig.duration = 6000000;
        toastConfig.horizontalPosition = align;
      }
      else {
        toastConfig.horizontalPosition = 'center';
      }
      toastConfig.data = { message, actionType, values };
      this.toast.openFromComponent(ToastComponent, toastConfig);
    }
    else {
      this.snackBarService.openSnackBar(message, actionType, values, 7000);
    }
  }

  closeToaster() {
    this.toastCloseService.setCloseToastValue(true);
  }
}
