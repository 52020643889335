import { AppState } from './../../store/reducers/index';
import { Store } from '@ngrx/store';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core"
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { TrendingTagsActionTypes, TrendingTagsRequested, TrendingTagsLoaded, TrendingTagsLoadingError } from './trending.tags.action';
import { RecognitionApiService } from '../../providers/api.service/recognition.api.service';

@Injectable()
export class TrendingTagsEffects {

  constructor(private actions$: Actions, private _recognitionApiService: RecognitionApiService, private store: Store<AppState>) { }

  
  loadActivity$ = createEffect(() => this.actions$
    .pipe(
      ofType<TrendingTagsRequested>(TrendingTagsActionTypes.TRENDING_TAGS_REQUESTED),
      mergeMap((action) => {
        return this._recognitionApiService.getTrendingTags(action.payload.query).pipe(
          catchError(error => {
            this.store.dispatch(new TrendingTagsLoadingError({ errorMessage: error.message }));
            return of({
              data: []
            });
          }),
        )
      }),
      map(response => {
        let { data } = response;
        return new TrendingTagsLoaded({ trendingTagList: data.data ? data.data : [], hasNextPage: data.hasNextPage })
      }),
    ))
}

