import { Action } from '@ngrx/store';

export class RewardTag {
  id?: string;
  identifier: string;
  tag: string;
  description: string;
  tagName: string;
  name?: string;
  type: string;
}


export enum RewardTagActionTypes {
  RewardTagRequested = "[REWARD_TAG] Reward Tag goal requested",
  RewardTagLoaded = "[REWARD_TAG] Reward Tag goal loaded"
}

export class RewardTagRequested implements Action {
  readonly type = RewardTagActionTypes.RewardTagRequested
  constructor() { }
}

export class RewardTagLoaded implements Action {
  readonly type = RewardTagActionTypes.RewardTagLoaded
  constructor(public payload: { rewardTag: RewardTag[] }) { }
}

export type RewardTagActions = RewardTagRequested | RewardTagLoaded;

