import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TrendingTag } from '../../modals/recogntion.tags';
import { TrendingTagsActionTypes } from './trending.tags.action';

export interface TrendingTagsState extends EntityState<TrendingTag> {
  hasNextPage?: boolean;
  errorMessage?: string;
  loading?: boolean;
}

export const trendingTagAdapter: EntityAdapter<TrendingTag> = createEntityAdapter<TrendingTag>({
  selectId: (trendingTag: TrendingTag) => trendingTag.identifier
});

const initialTrendingTagState = trendingTagAdapter.getInitialState({
  loading : true
});

export function trendingTagsReducer(state = initialTrendingTagState, action): TrendingTagsState {

  switch (action.type) {

    case TrendingTagsActionTypes.TRENDING_TAGS_REQUESTED:
      return { ...state, hasNextPage: true, loading: true }

    case TrendingTagsActionTypes.TRENDING_TAGS_LOADED:
      return trendingTagAdapter.addMany(action.payload.trendingTagList, { ...state, hasNextPage: action.payload.hasNextPage, loading: false });

    default:
      return { ...state};
  }
}

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal
} = trendingTagAdapter.getSelectors();



