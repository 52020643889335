export class ExternalApp {
  app: string;
  appType: ExternalAppType;
  isEnabled: boolean;
  config: Config[];
  groupAutomatedNotifications: boolean;
}

interface Config {
  configurationId: string
}

export class Slack {
  clientId: string;
  redirectionUri: string;
  state: string;
}

export enum ExternalAppType {
  MicrosoftTeams = 'MicrosoftTeams',
  Slack = 'Slack',
  MicrosoftSharepoint = 'MicrosoftSharepoint'
}

export enum SSOOptionType {
  Okta = 'Okta',
  Google = 'Google',
  Microsoft = 'Microsoft',
  MicrosoftAD = 'MicrosoftAzureAD'
}
