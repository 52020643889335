import { Injectable } from "@angular/core";
import * as microsoftTeams from "@microsoft/teams-js";
import { AuthService } from "../authentication/auth.service";
@Injectable({ providedIn: 'root' })

export class AppPlatformService {

    constructor(private _authService: AuthService) { }

    setIsAppHostedOnMsTeam() {
        let value = this.getIsAppHostedOnMsTeam();
        this._authService.setIsAppHostedOnMsTeam(value);
    }

    public getIsAppHostedOnMsTeam(): boolean {
        // eslint-disable-next-line dot-notation
        const microsoftTeamsLib = microsoftTeams || window["microsoftTeams"];

        if (!microsoftTeamsLib) {
            return false; // the Microsoft Teams library is for some reason not loaded
        }

        if ((window.parent === window.self && (window as any).nativeInterface) ||
            window.name === "embedded-page-container" ||
            window.name === "extension-tab-frame") {
            return true;
        }
        return false;
    };
}