import { ActivityActionTypes } from './notification.action';
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { RecentActivity } from '../../modals/recent.activity';

export interface RecentActivityState extends EntityState<RecentActivity> {
  loading?: boolean;
  hasNextPage?: boolean;
  errorMessage?: string;
  totalCount: number;
  unseenCount: number;
  hasPreviousNotification: boolean;
}

export const adapter: EntityAdapter<RecentActivity> = createEntityAdapter<RecentActivity>({
  selectId: (activity: RecentActivity) => activity.listOfNotificationId[0]
});

const initialActivityState = adapter.getInitialState({
  loading: true,
  hasNextPage: true,
  totalCount: 0,
  unseenCount: 0,
  hasPreviousNotification: false,
});

export function recentActivityReducer(state = initialActivityState, action): RecentActivityState {

  switch (action.type) {
    case ActivityActionTypes.RECENT_ACTIVITY_REQUESTED:
      return { ...state, hasNextPage: true, loading: true, unseenCount: 0, hasPreviousNotification: false }

    case ActivityActionTypes.RECENT_ACTIVITY_LOADED:
      return adapter.addMany(action.payload.recentActivities, { ...state, totalCount: action.payload.totalCount, hasNextPage: action.payload.hasNextPage, loading: false, unseenCount: action.payload.unseenCount, hasPreviousNotification: action.payload.hasPreviousNotification });

    case ActivityActionTypes.RECENT_ACTIVITY_READ_UNREAD:
      return adapter.removeAll({ ...state, hasNextPage: true, loading: true, unseenCount: action.payload.unseenCount, hasPreviousNotification: action.payload.hasPreviousNotification });

    case ActivityActionTypes.RECENT_ACTIVITY_UPDATE:

      return adapter.updateOne(action.payload.activity, { ...state, hasNextPage: true });

    case ActivityActionTypes.RECENT_ACTIVITY_REFRESH:

      return adapter.removeAll({ ...state, hasNextPage: false });

    case ActivityActionTypes.RECENT_ACTIVITY_REFRESH_COMPLETED:

      return adapter.setAll(action.payload.recentActivities, { ...state, totalCount: action.payload.totalCount, hasNextPage: action.payload.hasNextPage, loading: false });

    case ActivityActionTypes.RECENT_ACTIVITY_ERROR:

      return { ...state, errorMessage: action.payload.errorMessage, loading: false, unseenCount: 0, hasPreviousNotification: false }

    case ActivityActionTypes.RECENT_ACTIVITY_SOFT_REFRESH:

      return adapter.setAll(action.payload.recentActivities, { ...state });

    case ActivityActionTypes.RECENT_ACTIVITY_UNSEEN_NOTIFICATION:

      return adapter.updateMany(action.payload.recentActivities, { ...state, unseenCount: action.payload.unseenCount });


    default:
      return { ...state };
  }
}


export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal
} = adapter.getSelectors();
