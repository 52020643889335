import { InjectionToken } from "@angular/core";

export const WL_SNACKBAR_CONFIG = new InjectionToken<WlSnackbarConfigLiteral>('wl-snackbar SNACKBAR_CONFIG');

export interface WlSnackbarConfigLiteral {
    debug?: boolean;
}

export enum WlSnackbarType {
    Normal = 'Normal',
    Alert = "Alert"
}
export enum WlSnackbarInfoType {
    None = "",
    Info = 'Info',
    Warn = "Warn",
    Success = 'Success'
}


export enum WlHorizontalPosition {
    start = 'start',
    center = 'center',
    end = 'end',
    left = 'left',
    right = 'right'
}

export enum WlVerticalPosition {
    top = 'top',
    bottom = 'bottom'
}

export class WlSnackbarData {
    type: WlSnackbarType;
    message: string;
    dismissWithPrimaryAction: string;
    dismissWithSecondaryAction: string;
    infoType: WlSnackbarInfoType;

    constructor() {
        this.message = "";
        this.type = WlSnackbarType.Normal;
        this.dismissWithPrimaryAction = null;
        this.dismissWithSecondaryAction = null;
        this.infoType = null;
    }
}

export class WlSnackbarConfig {
    horizontalPosition: WlHorizontalPosition;
    verticalPosition: WlVerticalPosition;
    duration: number;
    data: WlSnackbarData;

    constructor() {
        this.horizontalPosition = WlHorizontalPosition.left;
        this.verticalPosition = WlVerticalPosition.bottom;
        this.duration = 4000;
        this.data = new WlSnackbarData();
    }
}