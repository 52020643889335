import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { AccountApiService, AdminApiService } from '../api.service';
import { NotificationType } from "../../modals/keys/notification.key";
import { UserGuardService } from '../authentication/user.guard.service';
import { StoreService } from '../../store/store.service';
import User from '../../modals/user';
import { RecognitionApiService } from '../api.service/recognition.api.service';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private isAdminPortalNotificationBeingFetched: boolean;
  private totalUnreadNotificationOfRecognitionPortal: BehaviorSubject<number> = new BehaviorSubject(0);
  private totalUnreadNotificationOfAdminPortal: BehaviorSubject<number> = new BehaviorSubject(0);
  private sendAndManageNotificationSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  private totalReportReadyNotification: BehaviorSubject<number> = new BehaviorSubject(0);
  private totalDepositFundsNotification: BehaviorSubject<number> = new BehaviorSubject(0);
  private totalLowBalanceRewardNotification: BehaviorSubject<number> = new BehaviorSubject(0);
  private totalPendingRewardsNotification: BehaviorSubject<number> = new BehaviorSubject(0);
  private totalRewardRequestNotification: BehaviorSubject<number> = new BehaviorSubject(0);
  private totalThankYouNoteNotification: BehaviorSubject<number> = new BehaviorSubject(0);
  private totalUndeliverableRewardNotification: BehaviorSubject<number> = new BehaviorSubject(0);
  private totalUpcomingRewardNotification: BehaviorSubject<number> = new BehaviorSubject(0);
  private totalDirectOrdersNotification: BehaviorSubject<number> = new BehaviorSubject(0);
  private settingNotification: ReplaySubject<number> = new ReplaySubject();
  private approvalSettingNotification: ReplaySubject<number> = new ReplaySubject();
  private rewardBudgetSettingNotification: ReplaySubject<number> = new ReplaySubject();
  private totalGroupNotification: BehaviorSubject<number> = new BehaviorSubject(0);

  private isViewedTotalReportReadyNotification: boolean;
  private isViewedTotalDepositFundsNotification: boolean;
  private isViewedTotalLowBalanceRewardNotification: boolean;
  private isViewedTotalPendingRewardsNotification: boolean;
  private isViewedTotalRewardRequestNotification: boolean;
  private isViewedTotalThankYouNoteNotification: boolean;
  private isViewedTotalUndeliverableRewardNotification: boolean;
  private isViewedTotalUpcomingRewardNotification: boolean;
  private isViewedTotalDirectoriesNotification: boolean;
  private isViewedTotalApproverNotification: boolean;
  private isViewedTotalGroupLeaderNotification: boolean;
  private notificationList: any;
  private user: User;

  constructor(private adminApiService: AdminApiService, private accountApiService: AccountApiService, private storeService: StoreService, private userGuardService: UserGuardService, private _recognitionApiService: RecognitionApiService) {
    this.notificationList = [];
    this.isViewedTotalReportReadyNotification = false;
    this.isViewedTotalDepositFundsNotification = false;
    this.isViewedTotalLowBalanceRewardNotification = false;
    this.isViewedTotalPendingRewardsNotification = false;
    this.isViewedTotalRewardRequestNotification = false;
    this.isViewedTotalThankYouNoteNotification = false;
    this.isViewedTotalUndeliverableRewardNotification = false;
    this.isViewedTotalUpcomingRewardNotification = false;
    this.isViewedTotalDirectoriesNotification = false;
    this.isViewedTotalApproverNotification = false;
    this.storeService.getUser().subscribe(user => this.user = user);
  }

  public initializeData(getAdminPortalNotification: boolean = true, getRecognitionPortalNotification: boolean = true) {
    if (getAdminPortalNotification) {
      this.getTotalNotificationsOfAdminPortal();
    }
    // if (getRecognitionPortalNotification) {
    //   this.getTotalUnreadNotificationsOfRecognitionPortal();
    // }
  }

  private getTotalNotificationsOfAdminPortal() {
    this.setIsAdminPortalNotificationBeingFetched();
    this.getAllNotificationsOfAdminPortal();
  }

  private setIsAdminPortalNotificationBeingFetched(value: boolean = false) {
    this.isAdminPortalNotificationBeingFetched = value;
  }

  public getIsAdminPortalNotificationBeingFetched(): boolean {
    return this.isAdminPortalNotificationBeingFetched;
  }

  public setUnreadNotificationValueOfRecognitionPortal(value: number = 0) {
    this.totalUnreadNotificationOfRecognitionPortal.next(value);
  }

  public getUnreadNotificationValueOfRecognitionPortal(): Observable<number> {
    return this.totalUnreadNotificationOfRecognitionPortal;
  }

  private setUnreadNotificationValueOfAdminPortal(value: number = 0) {
    this.totalUnreadNotificationOfAdminPortal.next(value);
  }

  public getUnreadNotificationValueOfAdminPortal(): Observable<number> {
    return this.totalUnreadNotificationOfAdminPortal;
  }

  private setUnreadNotificationValueOfRewardsNotification(notification) {
    let totalNotifications = (notification?.upcomingReward ? notification?.upcomingReward.count : 0) +
      (notification?.thankYouNote ? notification?.thankYouNote.count : 0) +
      (notification?.undeliverableReward ? notification?.undeliverableReward.count : 0) + (notification?.rewardRequest ? notification?.rewardRequest.count : 0);

    if (this.userGuardService.isChannelPartnerAccount()) {
      totalNotifications = totalNotifications + (notification?.directOrders ? notification?.directOrders.count : 0);
    }
    {
      this.sendAndManageNotificationSubject.next(totalNotifications);
    }
  }

  public getUnreadNotificationValueOfRewardsNotification(): Observable<number> {
    return this.sendAndManageNotificationSubject.asObservable();
  }

  private UpdateUnreadNotificationValue(notification = this.notificationList) {
    this.setUnreadNotificationValueOfAdminPortal(notification.totalUnreadNotifications ? notification.totalUnreadNotifications : 0);
    this.setUnreadNotificationValueOfRewardsNotification(notification);
  }

  private UpdateUnreadNotificationValueAfterBeingRead(readNotifcationCount) {
    this.notificationList.totalUnreadNotifications = this.notificationList.totalUnreadNotifications - readNotifcationCount;
    this.setUnreadNotificationValueOfAdminPortal(this.notificationList.totalUnreadNotifications ? this.notificationList.totalUnreadNotifications : 0);
    this.setUnreadNotificationValueOfRewardsNotification(this.notificationList);
    this.UpdateUnreadNotificationValue();
  }

  private setTotalReportReadyNotification(value: number = 0) {
    this.totalReportReadyNotification.next(value);
  }

  public getTotalReportReadyNotification(): Observable<number> {
    return this.totalReportReadyNotification;
  }

  public setTotalReportReadyNotificationViewedValue(value: boolean = true) {
    this.isViewedTotalReportReadyNotification = value;
  }

  private setTotalDepositFundsNotification(value: number = 0) {
    this.totalDepositFundsNotification.next(value);
  }

  public getTotalDepositFundsNotification(): Observable<number> {
    return this.totalDepositFundsNotification;
  }

  public setTotalDepositFundsNotificationValueViewedValue(value: boolean = true) {
    this.isViewedTotalDepositFundsNotification = value;
  }

  private setTotalLowBalanceRewardNotification(value: number = 0) {
    this.totalLowBalanceRewardNotification.next(value);
  }

  public getTotalLowBalanceRewardNotification(): Observable<number> {
    return this.totalLowBalanceRewardNotification;
  }

  public setTotalLowBalanceRewardNotificationViewedValue(value: boolean = true) {
    this.isViewedTotalLowBalanceRewardNotification = value;
  }

  private setTotalPendingRewardsNotification(value: number = 0) {
    this.totalPendingRewardsNotification.next(value);
  }

  public getTotalPendingRewardsNotification(): Observable<number> {
    return this.totalPendingRewardsNotification;
  }

  public setTotalPendingRewardsNotificationViewedValue(value: boolean = true) {
    this.isViewedTotalPendingRewardsNotification = value;
  }

  private setTotalRewardRequestNotification(value: number = 0) {
    this.totalRewardRequestNotification.next(value);
  }

  public getTotalRewardRequestNotification(): Observable<number> {
    return this.totalRewardRequestNotification;
  }

  public setTotalRewardRequestNotificationViewedValue(value: boolean = true) {
    this.isViewedTotalRewardRequestNotification = value;
  }

  private setTotalThankYouNoteNotification(value: number = 0) {
    this.totalThankYouNoteNotification.next(value);
  }

  public getTotalThankYouNoteNotification(): Observable<number> {
    return this.totalThankYouNoteNotification;
  }

  public setTotalThankYouNoteNotificationViewedValue(value: boolean = true) {
    this.isViewedTotalThankYouNoteNotification = value;
  }

  private setTotalUndeliverableRewardNotification(value: number = 0) {
    this.totalUndeliverableRewardNotification.next(value);
  }

  public getTotalUndeliverableRewardNotification(): Observable<number> {
    return this.totalUndeliverableRewardNotification;
  }

  public setTotalUndeliverableRewardNotificationViewedValue(value: boolean = true) {
    this.isViewedTotalUndeliverableRewardNotification = value;
  }

  private setTotalUpcomingRewardNotification(value: number = 0) {
    this.totalUpcomingRewardNotification.next(value);
  }

  public getTotalUpcomingRewardNotification(): Observable<number> {
    return this.totalUpcomingRewardNotification.asObservable();
  }

  public setTotalUpcomingRewardNotificationViewedValue(value: boolean = true) {
    this.isViewedTotalUpcomingRewardNotification = true;
  }

  private setTotalDirectOrdersNotification(value: number = 0) {
    this.totalDirectOrdersNotification.next(this.userGuardService.isChannelPartnerAccount() ? value : 0);
  }

  public getTotalDirectOrdersNotification(): Observable<number> {
    return this.totalDirectOrdersNotification.asObservable();
  }

  public setTotalDirectOrdersNotificationViewedValue(value: boolean = true) {
    this.isViewedTotalDirectoriesNotification = value;
  }

  public setApproverNotificationViewedValue(value: boolean = true) {
    this.isViewedTotalApproverNotification = value;
  }

  public setGroupLeaderNotificationViewedValue(value: boolean = true) {
    this.isViewedTotalGroupLeaderNotification = value;
  }

  public setSettingNotificationValue(value: number = 0) {
    this.settingNotification.next(value);
  }

  public getSettingNotificationValue(): Observable<number> {
    return this.settingNotification.asObservable();
  }

  public setApprovalSettingNotificationValue(value: number = 0) {
    this.approvalSettingNotification.next(value);
  }

  public getApprovalSettingNotificationValue(): Observable<number> {
    return this.approvalSettingNotification.asObservable();
  }

  public setRewardBudgetSettingNotificationValue(value: number = 0) {
    this.rewardBudgetSettingNotification.next(value);
  }

  public getRewardBudgetSettingNotificationValue(): Observable<number> {
    return this.rewardBudgetSettingNotification.asObservable();
  }

  public refreshAdminPortalNotificationsValue() {
    this.getTotalNotificationsOfAdminPortal();
  }

  public setTotalGroupNotification(value) {
    this.totalGroupNotification.next(value);
  }

  public getTotalGroupNotification(): Observable<number> {
    return this.totalGroupNotification.asObservable();
  }

  getTotalUnreadNotificationsOfRecognitionPortal() {
    let userRoles = this.user && this.user.roles ? this.user.roles : '';
    if (userRoles.indexOf('EmployeeLogin') > -1) {
      let params = {
        pageSize: 1,
        pageIndex: 0,
        notificationStatus: 'Unread'
      };
      this._recognitionApiService.getNotificationsList(params)
        .subscribe((response) => {
          if (response) {
            this.setUnreadNotificationValueOfRecognitionPortal(response.totalCount);
          }
        });
    }
    else {
      this.setUnreadNotificationValueOfRecognitionPortal();
    }
  }

  private getAllNotificationsOfAdminPortal() {
    if (this.userGuardService.doesAccountantHaveAccess() || this.userGuardService.doesProgramAdminHaveAccess() || this.userGuardService.doesRewardSenderHaveAccess()) {
      this.accountApiService.getNotificationsList()
        .subscribe((response) => {
          if (response) {
            this.setIsAdminPortalNotificationBeingFetched(true);
            this.notificationList = response;
            this.setNotificationAccordingToPage(response);
          }
        });
    }
    else {
      this.setUnreadNotificationValueOfAdminPortal();
    }
  }

  private setNotificationAccordingToPage(notification: AdminNotificationDataDto = this.notificationList) {
    {
      this.UpdateUnreadNotificationValue();
    }
    {
      this.setTotalReportReadyNotification(notification.reportReady && notification.reportReady.count ? notification.reportReady.count : 0);
      this.setTotalDepositFundsNotification(notification.depositFunds && notification.depositFunds.count ? notification.depositFunds.count : 0);
      this.setTotalLowBalanceRewardNotification(notification.lowBalance && notification.lowBalance.count ? notification.lowBalance.count : 0);
      this.setTotalPendingRewardsNotification(notification.pendingRewards && notification.pendingRewards.count ? notification.pendingRewards.count : 0);
      this.setTotalUndeliverableRewardNotification(notification.undeliverableReward && notification.undeliverableReward.count ? notification.undeliverableReward.count : 0);
      this.setTotalUpcomingRewardNotification(notification.upcomingReward && notification.upcomingReward.count ? notification.upcomingReward.count : 0);
      this.setTotalThankYouNoteNotification(notification.thankYouNote && notification.thankYouNote.count ? notification.thankYouNote.count : 0);
      this.setTotalRewardRequestNotification(notification.rewardRequest && notification.rewardRequest.count ? notification.rewardRequest.count : 0);
      this.setTotalDirectOrdersNotification(notification.directOrders && notification.directOrders.count ? notification.directOrders.count : 0)
      this.setSettingNotificationValue((notification.approver?.count ?? 0) + (notification.rewardBudgetManager?.count ?? 0));
      this.setApprovalSettingNotificationValue(notification.approver?.count ?? 0);
      this.setRewardBudgetSettingNotificationValue(notification.rewardBudgetManager?.count ?? 0);
      this.setTotalGroupNotification(notification.groupLeader && notification.groupLeader.count ? notification.groupLeader.count : 0);
    }
  }

  public getNotificationActionIdList(notificationType): string[] {
    let notificationActionIdList: string[] = [];
    if (notificationType == NotificationType.PendingReward && this.notificationList.pendingRewards && this.notificationList.pendingRewards.count) {
      notificationActionIdList = getNotificationActionIdList(this.notificationList.pendingRewards.ids);
    }
    if (notificationType == NotificationType.LowBalance && this.notificationList.lowBalance && this.notificationList.lowBalance.count) {
      notificationActionIdList = getNotificationActionIdList(this.notificationList.lowBalance.ids);
    }
    if (notificationType == NotificationType.DepositFund && this.notificationList.depositFunds && this.notificationList.depositFunds.count) {
      notificationActionIdList = getNotificationActionIdList(this.notificationList.depositFunds.ids);
    }
    if (notificationType == NotificationType.ReportReady && this.notificationList.reportReady && this.notificationList.reportReady.count) {
      notificationActionIdList = getNotificationActionIdList(this.notificationList.reportReady.ids);
    }
    if (notificationType == NotificationType.ThankYouNote && this.notificationList.thankYouNote && this.notificationList.thankYouNote.count) {
      notificationActionIdList = getNotificationActionIdList(this.notificationList.thankYouNote.ids);
    }
    if (notificationType == NotificationType.RequestReward && this.notificationList.rewardRequest && this.notificationList.rewardRequest.count) {
      notificationActionIdList = getNotificationActionIdList(this.notificationList.rewardRequest.ids);
    }
    if (notificationType == NotificationType.UndeliverableReward && this.notificationList.undeliverableReward && this.notificationList.undeliverableReward.count) {
      notificationActionIdList = getNotificationActionIdList(this.notificationList.undeliverableReward.ids);
    }
    if (notificationType == NotificationType.UpcomingReward && this.notificationList.upcomingReward && this.notificationList.upcomingReward.count) {
      notificationActionIdList = getNotificationActionIdList(this.notificationList.upcomingReward.ids);
    }
    if (notificationType == NotificationType.DirectOrders && this.notificationList.directOrders && this.notificationList.directOrders.count) {
      notificationActionIdList = getNotificationActionIdList(this.notificationList.directOrders.ids);
    }
    if (notificationType == NotificationType.GroupLeader && this.notificationList?.groupLeader && this.notificationList.groupLeader.count) {
      notificationActionIdList = getNotificationActionIdList(this.notificationList.groupLeader.ids);
    }

    return notificationActionIdList;

    function getNotificationActionIdList(idList): string[] {
      let notificationActionIdList: string[] = [];
      if (idList && idList.length > 0) {
        for (let index = 0; index < idList.length; index++) {
          if (idList[index].notificationActionIds && idList[index].notificationActionIds.length > 0) {
            for (let actionIndex = 0; actionIndex < idList[index].notificationActionIds.length; actionIndex++) {
              notificationActionIdList.push(idList[index].notificationActionIds[actionIndex]);
            }
          }
        }
      }
      return notificationActionIdList;
    }
  }

  public setNotificationReadValue(notificationType: string = undefined) {
    let notificationIdList = [];
    let readNotifcationCount = 0;
    if (notificationType) {
      if (notificationType == NotificationType.PendingReward && this.isViewedTotalPendingRewardsNotification && this.notificationList.pendingRewards && this.notificationList.pendingRewards.count) {
        notificationIdList = getNotificationIdList(this.notificationList.pendingRewards.ids);
        readNotifcationCount = this.notificationList.pendingRewards.count;
        this.notificationList.pendingRewards.count = 0;
        this.notificationList.pendingRewards.ids = [];
        this.setTotalPendingRewardsNotification();
      }

      if (notificationType == NotificationType.LowBalance && this.isViewedTotalLowBalanceRewardNotification && this.notificationList.lowBalance && this.notificationList.lowBalance.count) {
        notificationIdList = getNotificationIdList(this.notificationList.lowBalance.ids);
        readNotifcationCount = this.notificationList.lowBalance.count;
        this.notificationList.lowBalance.count = 0;
        this.notificationList.lowBalance.ids = [];
        this.setTotalLowBalanceRewardNotification();
      }

      if (notificationType == NotificationType.DepositFund && this.isViewedTotalDepositFundsNotification && this.notificationList.depositFunds && this.notificationList.depositFunds.count) {
        notificationIdList = getNotificationIdList(this.notificationList.depositFunds.ids);
        readNotifcationCount = this.notificationList.depositFunds.count;
        this.notificationList.depositFunds.count = 0;
        this.notificationList.depositFunds.ids = [];
        this.setTotalDepositFundsNotification();
      }

      if (notificationType == NotificationType.ReportReady && this.isViewedTotalReportReadyNotification && this.notificationList.reportReady && this.notificationList.reportReady.count) {
        notificationIdList = getNotificationIdList(this.notificationList.reportReady.ids);
        readNotifcationCount = this.notificationList.reportReady.count;
        this.notificationList.reportReady.count = 0;
        this.notificationList.reportReady.ids = [];
        this.setTotalReportReadyNotification();
      }

      if (notificationType == NotificationType.ThankYouNote && this.isViewedTotalThankYouNoteNotification && this.notificationList.thankYouNote && this.notificationList.thankYouNote.count) {
        notificationIdList = getNotificationIdList(this.notificationList.thankYouNote.ids);
        this.notificationList.thankYouNote.count = 0;
        this.notificationList.thankYouNote.ids = [];
        this.setTotalThankYouNoteNotification();
      }

      if (notificationType == NotificationType.UndeliverableReward && this.isViewedTotalUndeliverableRewardNotification && this.notificationList.undeliverableReward && this.notificationList.undeliverableReward.count) {
        notificationIdList = getNotificationIdList(this.notificationList.undeliverableReward.ids);
        this.notificationList.undeliverableReward.count = 0;
        this.notificationList.undeliverableReward.ids = [];
        this.setTotalUndeliverableRewardNotification();
      }

      if (notificationType == NotificationType.RequestReward && this.isViewedTotalRewardRequestNotification && this.notificationList.rewardRequest && this.notificationList.rewardRequest.count) {
        notificationIdList = getNotificationIdList(this.notificationList.rewardRequest.ids);
        this.notificationList.rewardRequest.count = 0;
        this.notificationList.rewardRequest.ids = [];
        this.setTotalRewardRequestNotification();
      }

      if (notificationType == NotificationType.UpcomingReward && this.isViewedTotalUpcomingRewardNotification && this.notificationList.upcomingReward && this.notificationList.upcomingReward.count) {
        notificationIdList = notificationIdList.concat(getNotificationIdList(this.notificationList.upcomingReward.ids));
        this.notificationList.upcomingReward.count = 0;
        this.notificationList.upcomingReward.ids = [];
        this.setTotalUpcomingRewardNotification();
      }

      if (notificationType == NotificationType.DirectOrders && this.isViewedTotalDirectoriesNotification && this.notificationList.directOrders && this.notificationList.directOrders.count) {
        notificationIdList = notificationIdList.concat(getNotificationIdList(this.notificationList.directOrders.ids));
        this.notificationList.directOrders.count = 0;
        this.notificationList.directOrders.ids = [];
        this.setTotalDirectOrdersNotification();
      }
      if (notificationType == NotificationType.Approver && this.isViewedTotalApproverNotification && this.notificationList.approver && this.notificationList.approver.count) {
        notificationIdList = notificationIdList.concat(getNotificationIdList(this.notificationList.defaultApprover.ids));
        readNotifcationCount = this.notificationList.defaultApprover.count
        this.notificationList.defaultApprover.count = 0;
        this.notificationList.defaultApprover.ids = [];
        this.setSettingNotificationValue();
      }
    }
    else {
      if (this.isViewedTotalPendingRewardsNotification && this.notificationList.pendingRewards && this.notificationList.pendingRewards.count) {
        notificationIdList.concat(getNotificationIdList(this.notificationList.pendingRewards.ids));
        readNotifcationCount += this.notificationList.pendingRewards.count;
        this.notificationList.pendingRewards.count = 0;
        this.notificationList.pendingRewards.ids = [];
        this.setTotalPendingRewardsNotification();
      }

      if (this.isViewedTotalLowBalanceRewardNotification && this.notificationList.lowBalance && this.notificationList.lowBalance.count) {
        notificationIdList = notificationIdList.concat(getNotificationIdList(this.notificationList.lowBalance.ids));
        readNotifcationCount += this.notificationList.lowBalance.count;
        this.notificationList.lowBalance.count = 0;
        this.notificationList.lowBalance.ids = [];
        this.setTotalLowBalanceRewardNotification();
      }

      if (this.isViewedTotalDepositFundsNotification && this.notificationList.depositFunds && this.notificationList.depositFunds.count) {
        notificationIdList = notificationIdList.concat(getNotificationIdList(this.notificationList.depositFunds.ids));
        readNotifcationCount += this.notificationList.depositFunds.count;
        this.notificationList.depositFunds.count = 0;
        this.notificationList.depositFunds.ids = [];
        this.setTotalDepositFundsNotification();
      }

      if (this.isViewedTotalReportReadyNotification && this.notificationList.reportReady && this.notificationList.reportReady.count) {
        notificationIdList = notificationIdList.concat(getNotificationIdList(this.notificationList.reportReady.ids));
        readNotifcationCount += this.notificationList.reportReady.count;
        this.notificationList.reportReady.count = 0;
        this.notificationList.reportReady.ids = [];
        this.setTotalReportReadyNotification();
      }
    }
    {
      this.UpdateUnreadNotificationValueAfterBeingRead(readNotifcationCount);
    }

    this.setNotificationReadValueApiCall(notificationIdList);

    function getNotificationIdList(idList): string[] {
      let notificationIdList: string[] = [];
      if (idList && idList.length > 0) {
        for (let index = 0; index < idList.length; index++) {
          if (idList[index].notificationId) {
            notificationIdList.push(idList[index].notificationId);
          }
        }
      }
      return notificationIdList;
    }
  }

  public setNotificationReadValueUsingActionIdList(notificationActionIdList: string[], notificationType) {
    let notification: any;
    let readNotificationCount: number = 0;
    if (notificationActionIdList && notificationActionIdList.length > 0 && notificationType) {
      switch (notificationType) {
        case NotificationType.ThankYouNote:
          if (this.notificationList.thankYouNote && this.notificationList.thankYouNote.count) {
            notification = getNotificationIdList(this.notificationList.thankYouNote.ids, notificationActionIdList);
            this.notificationList.thankYouNote.ids = notification.newIdList;
            this.notificationList.thankYouNote.count = this.notificationList.thankYouNote.ids.length;
            this.setTotalThankYouNoteNotification(this.notificationList.thankYouNote.count);
            readNotificationCount += 0;
          }
          break;
        case NotificationType.UndeliverableReward:
          if (this.notificationList.undeliverableReward && this.notificationList.undeliverableReward.count) {
            notification = getNotificationIdList(this.notificationList.undeliverableReward.ids, notificationActionIdList);
            this.notificationList.undeliverableReward.ids = notification.newIdList;
            this.notificationList.undeliverableReward.count = this.notificationList.undeliverableReward.ids.length;
            this.setTotalUndeliverableRewardNotification(this.notificationList.undeliverableReward.count);
            readNotificationCount += 0;
          }
          break;
        case NotificationType.RequestReward:
          if (this.notificationList.rewardRequest && this.notificationList.rewardRequest.count) {
            notification = getNotificationIdList(this.notificationList.rewardRequest.ids, notificationActionIdList);
            this.notificationList.rewardRequest.ids = notification.newIdList;
            this.notificationList.rewardRequest.count = this.notificationList.rewardRequest.ids.length;
            this.setTotalRewardRequestNotification(this.notificationList.rewardRequest.count);
            readNotificationCount += 0;
          }
          break;
        case NotificationType.UpcomingReward:
          if (this.notificationList.upcomingReward && this.notificationList.upcomingReward.count) {
            notification = getNotificationIdList(this.notificationList.upcomingReward.ids, notificationActionIdList);
            this.notificationList.upcomingReward.ids = notification.newIdList;
            this.notificationList.upcomingReward.count = this.notificationList.upcomingReward.ids.length;
            this.setTotalUpcomingRewardNotification(this.notificationList.upcomingReward.count);
            readNotificationCount += 0;
          }
          break;
        case NotificationType.DirectOrders:
          if (this.notificationList.directOrders && this.notificationList.directOrders.count) {
            notification = getNotificationIdList(this.notificationList.directOrders.ids, notificationActionIdList);
            this.notificationList.directOrders.ids = notification.newIdList;
            this.notificationList.directOrders.count = this.notificationList.directOrders.ids.length;
            this.setTotalDirectOrdersNotification(this.notificationList.directOrders.count);
            readNotificationCount += 0;
          }
          break;
        case NotificationType.GroupLeader:
          if (this.notificationList.groupLeader && this.notificationList.groupLeader.count) {
            let previousNotificationCount = this.notificationList.groupLeader.count;
            notification = getNotificationIdList(this.notificationList.groupLeader.ids, notificationActionIdList);
            this.notificationList.groupLeader.ids = notification.newIdList;
            this.notificationList.groupLeader.count = this.notificationList.groupLeader.ids.length;
            this.setTotalGroupNotification(this.notificationList.groupLeader.count);
            readNotificationCount += (previousNotificationCount - (this.notificationList?.groupLeader?.count ? this.notificationList.groupLeader.count : 0));
          }
          break;
      }
      {
        this.UpdateUnreadNotificationValueAfterBeingRead(readNotificationCount);
      }

      this.setNotificationReadValueApiCall(notification?.notificationIdList);
    }

    function getNotificationIdList(OriginalIdList, actionIdList): any {
      let notificationIdList: string[] = [];
      let newOriginalIdList = []
      if (OriginalIdList && OriginalIdList.length > 0 && actionIdList && actionIdList.length > 0) {
        newOriginalIdList = JSON.parse(JSON.stringify(OriginalIdList));
        for (let index = 0; index < OriginalIdList.length; index++) {
          if (OriginalIdList[index].notificationId && (OriginalIdList[index].notificationActionIds && OriginalIdList[index].notificationActionIds.length > 0)) {
            for (let actionIndex = 0; actionIndex < OriginalIdList[index].notificationActionIds.length; actionIndex++) {
              if ((actionIdList.indexOf(OriginalIdList[index].notificationActionIds[actionIndex]) > -1)) {
                if ((notificationIdList.indexOf(OriginalIdList[index].notificationId)) < 0) { notificationIdList.push(OriginalIdList[index].notificationId); }
                let notificationIndex = newOriginalIdList.findIndex((element) => { return element.notificationId == OriginalIdList[index].notificationId });
                if (notificationIndex > -1) {
                  newOriginalIdList.splice(notificationIndex, 1);
                }
              }
            }
          }
        }
      }
      return { notificationIdList: notificationIdList, newIdList: newOriginalIdList };
    }
  }

  private setNotificationReadValueApiCall(notificationIdList) {
    if (notificationIdList && notificationIdList.length > 0) {
      let params = {
        notificationIds: notificationIdList,
        markRead: true,
        markAllNotificationRead: false
      }
      this.accountApiService.markNotificationsAsReadUnread(params)
        .subscribe((response) => { }, (error) => { });
    }
  }
}

export type AdminNotificationDataDto = {
  reportReady: NotificationResponse;
  depositFunds: NotificationResponse;
  lowBalance: NotificationResponse;
  pendingRewards: NotificationResponse;
  rewardRequest: NotificationResponse;
  thankYouNote: NotificationResponse;
  undeliverableReward: NotificationResponse;
  rewardSend: NotificationResponse;
  upcomingReward: NotificationResponse;
  directOrders: NotificationResponse;
  totalUnreadNotifications: number;
  approver: NotificationResponse;
  groupLeader: NotificationResponse;
  rewardBudgetManager: NotificationResponse;
}

export type NotificationResponse = {
  message: string;
  count: number;
  ids: NotificationIdDto[];
}

export type NotificationIdDto = {
  notificationId: number;
  notificationActionIds: number[]
}
