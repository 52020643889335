import { Action } from "@ngrx/store";
import UserBudget from '../../../modals/User.Budget';

export enum NewFeedActionTypes {
    UserBudgetRequested = "[NEWS_FEED_USER_BUDGET ] user budget requested",
    UserBudgetLoaded = "[NEWS_FEED_USER_BUDGET ] user budget loaded",
    UserBudgetResetRequested = "[NEWS_FEED_USER_BUDGET ] user budget reset requested",
    UserBudgetResetCompleted = "[NEWS_FEED_USER_BUDGET ] user budget loaded reset completed",
    UserBudgetLoadingError = "[NEWS_FEED_USER_BUDGET ] user budget error",
    NewsFeedLastUpdateTime = "[NEWS_FEED ] Last Updated At",
}

export class UserBudgetRequested implements Action {
    readonly type = NewFeedActionTypes.UserBudgetRequested;
    constructor() { }
}

export class UserBudgetLoaded implements Action {
    readonly type = NewFeedActionTypes.UserBudgetLoaded;
    constructor(public payload: { userBudget: UserBudget, isLoaded: boolean, lastRewardReceivedByUserName : string, lastRewardSentDaysAgo : number }) {
    }
}

export class UserBudgetResetRequested implements Action {
    readonly type = NewFeedActionTypes.UserBudgetResetRequested;
    constructor() { }
}

export class UserBudgetResetCompleted implements Action {
    readonly type = NewFeedActionTypes.UserBudgetResetCompleted;
    constructor(public payload: { userBudget: UserBudget }) {
    }
}

export class UserBudgetLoadingError implements Action {
    readonly type = NewFeedActionTypes.UserBudgetLoadingError;
    constructor(public payload: { errorMessage: string }) {
    }
}

export class NewsFeedLastUpdatedAt {
    readonly type = NewFeedActionTypes.NewsFeedLastUpdateTime;
    constructor(public payload: { lastUpdatedAt: string }) { }
}

export type NewFeedActions = NewFeedActionTypes | UserBudgetLoaded;
