import {Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class SnackbarCloseService {

  public closeSnackbar = new BehaviorSubject<boolean>(false);

  public closeToast() {
    this.closeSnackbar.next(true);
  }
}
