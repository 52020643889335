import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createSelector, select } from '@ngrx/store';
import { AppState } from '../reducers';

export enum FeeDetailsActionTypes {
    REACTION_DETAILS_SET = "[Reaction Details] Set Reaction Details",
}

export class FeedConfigDetails implements Action {
    readonly type = FeeDetailsActionTypes.REACTION_DETAILS_SET;
    constructor(public payload: { reactionDetails: ReactionDetails[], hasCustomSurveys?: boolean, feedReminders?: any, feedTypeConfigs?: any[], customSurveyData?: any[] }) {
    }
}

export type ReactionDetails = {
    active: boolean;
    name: string;
    reactionIdentifier: string;
    reactionUniCode: string;
    reactionUrl: string;
    type: string;
}
export interface ReactionDetailsInitialState extends EntityState<ReactionDetails> {
    loading: boolean,
    hasCustomSurveys: boolean,
    feedReminders: any,
    feedTypeConfigs: any[],
    customSurveyData: any[]
}

export const reactionAdapter: EntityAdapter<ReactionDetails> = createEntityAdapter<ReactionDetails>({
    selectId: (reaction: ReactionDetails) => reaction.reactionIdentifier,
});

const initialReactionDetailsState = reactionAdapter.getInitialState({
    loading: false,
    hasCustomSurveys: false,
    feedReminders: null,
    feedTypeConfigs: [],
    customSurveyData: []
});

export function feedConfigReducer(state = initialReactionDetailsState, action): ReactionDetailsInitialState {

    switch (action.type) {

        case FeeDetailsActionTypes.REACTION_DETAILS_SET:
            let payload = action.payload;
            return reactionAdapter.addMany(payload.reactionDetails, { ...state, loading: false, hasCustomSurveys: payload.hasCustomSurveys, feedReminders: payload.feedReminders, feedTypeConfigs: payload.feedTypeConfigs, customSurveyData: payload.customSurveyData });

        default:
            return { ...state };
    }
}



const selectFeesConfigDetailState = (state: AppState) => state.feedConfigReducer;

const selectors = reactionAdapter.getSelectors();

export const selectAllReactions = createSelector(
    selectFeesConfigDetailState,
    selectors.selectAll
);

export const selectHasCustomSurveys = createSelector(
    selectFeesConfigDetailState,
    survey => survey.hasCustomSurveys
);

export const selectCustomSurveyData = createSelector(
    selectFeesConfigDetailState,
    survey => survey.customSurveyData
);

export const selectConfigLoading = createSelector(
    selectFeesConfigDetailState,
    entity => entity.loading
);
export const selectFeedReminders = createSelector(
    selectFeesConfigDetailState,
    entity => entity.feedReminders
);
export const selectFeedTypeConfigs = createSelector(
    selectFeesConfigDetailState,
    entity => entity.feedTypeConfigs
);


