import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { LocalStorageService } from '../localstorage/localStorage.service';
import { WindowResizeService } from '../window.resize/window.resize.service';
import { UserGuardService } from './user.guard.service';
import { UserLocalStorageKey } from '../../modals/keys/local.storage';
import { AccountPageRoute, AdminPageRoute, RewardPageRoute } from '../../common/page.route';
import { environment } from 'projects/recognition/src/environments/environment';

@Injectable({ providedIn: 'root' })
export class RouteGuardLogoutService {
  constructor(private authenticationService: AuthService, private localStorageService: LocalStorageService) { }
  logout(storeCurrentRoute: boolean = true) {
    if (storeCurrentRoute) {
      let redirectUrl = window.location.href;
      this.localStorageService.remove(UserLocalStorageKey.RemoveUrl);
      this.localStorageService.set(UserLocalStorageKey.RedirectUrl, redirectUrl);
    }

    this.authenticationService.logout();
  }
}

@Injectable({ providedIn: 'root' })
export class AuthenticationGuardService {
  constructor(private userGuardService: UserGuardService, private routeGuardLogoutService: RouteGuardLogoutService) { }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.userGuardService.isLoggedIn()) { return true; }

    this.routeGuardLogoutService.logout();
    return false;
  }
}

@Injectable({ providedIn: 'root' })
export class AccountRouteGuardService {
  constructor(private router: Router, private userGuardService: UserGuardService, private routeGuardLogoutService: RouteGuardLogoutService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.userGuardService.doesUserHaveAccessAccountPortal()) {
      return true;
    }
    if (this.userGuardService.doesUserHaveAccessAdminPortal()) {
      this.router.navigate([AdminPageRoute.Home]);
    }
    else if (this.userGuardService.doesWalletUserHaveAccess()) {
      this.router.navigate([RewardPageRoute.UserReward]);
    }
    else {
      this.routeGuardLogoutService.logout();
    }
    return false;
  }
}

@Injectable({ providedIn: 'root' })
export class AdminRouteGuardService {
  constructor(private router: Router, private userGuardService: UserGuardService, private routeGuardLogoutService: RouteGuardLogoutService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.userGuardService.doesUserHaveAccessAdminPortal()) {
      return true;
    }

    if (this.userGuardService.doesUserHaveAccessAccountPortal()) {
      this.router.navigate([AccountPageRoute.Feed]);
    }
    else if (this.userGuardService.doesWalletUserHaveAccess()) {
      this.router.navigate([RewardPageRoute.UserReward]);
    }
    else {
      this.routeGuardLogoutService.logout();
    }
    return false;
  }
}

@Injectable({ providedIn: 'root' })
export class AdministratorsRouteGuardService {
  constructor(private router: Router, private userGuardService: UserGuardService, private windowResizeService: WindowResizeService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.userGuardService.isUserRoleAdministrators()) { return true };

    this.router.navigate([AdminPageRoute.Home]);

    return false;
  }
}

@Injectable({ providedIn: 'root' })
export class NonAdministratorsRouteGuardService {
  constructor(private router: Router, private userGuardService: UserGuardService, private windowResizeService: WindowResizeService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.windowResizeService.isDesktop()) {
      if (!this.userGuardService.isUserRoleAdministrators() || this.userGuardService.isUserManager) { return true };
    }

    this.router.navigate([AdminPageRoute.Home]);
    return false;
  }
}

@Injectable({ providedIn: 'root' })
export class ChannelPartnerRouteGuardService {
  constructor(private router: Router, private userGuardService: UserGuardService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.userGuardService.isChannelPartnerAccount()) { return true };

    this.router.navigate([AdminPageRoute.Home]);
    return false;
  }
}

@Injectable({ providedIn: 'root' })
export class WishlistLabRouteGuardService {
  constructor(private router: Router, private userGuardService: UserGuardService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.userGuardService.doesUserHaveAccessWishlistLab()) { return true };

    this.router.navigate([AdminPageRoute.Home]);
    return false;
  }
}

@Injectable({ providedIn: 'root' })
export class ManagerRouteGuardService {
  constructor(private router: Router, private userGuardService: UserGuardService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.userGuardService.doesRewardSenderHaveAccess() || this.userGuardService.doesProgramAdminHaveAccess()) { return true };
    this.router.navigate([AdminPageRoute.Reward]);
    return false;
  }
}

@Injectable({ providedIn: 'root' })
export class ProgramAdminRouteGuardService {
  constructor(private router: Router, private userGuardService: UserGuardService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.userGuardService.doesProgramAdminHaveAccess()) { return true };

    this.router.navigate([AdminPageRoute.Reward]);
    return false;
  }
}

@Injectable({ providedIn: 'root' })
export class AccountantWithManagerRouteGuardService {
  constructor(private router: Router, private userGuardService: UserGuardService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.userGuardService.doesRewardSenderHaveAccess() || this.userGuardService.doesProgramAdminHaveAccess() || this.userGuardService.doesAccountantHaveAccess()) { return true };

    this.router.navigate([AdminPageRoute.Reward]);
    return false;
  }
}

@Injectable({ providedIn: 'root' })
export class AccountantWithProgramAdminRouteGuardService {
  constructor(private router: Router, private userGuardService: UserGuardService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.userGuardService.doesAccountantHaveAccess() || this.userGuardService.doesProgramAdminHaveAccess()) { return true };

    this.router.navigate([AdminPageRoute.Reward]);
    return false;
  }
}

@Injectable({ providedIn: 'root' })
export class AccountWithProgramAdminRouteGuardService {
  constructor(private router: Router, private userGuardService: UserGuardService, private windowResizeService: WindowResizeService, private routeGuardLogoutService: RouteGuardLogoutService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.userGuardService.doesUserHaveAccessAdminPortal() && this.userGuardService.doesUserHaveAccessAccountPortal()) {
      if (this.windowResizeService.isDesktop()) {
        if (this.userGuardService.doesProgramAdminHaveAccess()) {
          return true;
        }
      }

      this.router.navigate([AccountPageRoute.Feed]);
      return false;
    }
    this.routeGuardLogoutService.logout(false);
    return false;
  }
}


export interface ComponentCanDeactivate {
  canDeactivate: () => any
}

@Injectable({
  providedIn: 'root'
})
export class SaveChangesGuardService {
  canDeactivate(component: ComponentCanDeactivate): boolean {
    return component?.canDeactivate()
  }
}

@Injectable({ providedIn: 'root' })
export class RewardSomeOneRouteGuardService {
  constructor(private router: Router, private userGuardService: UserGuardService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.userGuardService.doesRewardSenderHaveAccess() || this.userGuardService.doesProgramAdminHaveAccess()) { return true };

    this.router.navigate([RewardPageRoute.UserReward]);
    return false;
  }
}

@Injectable({ providedIn: 'root' })
export class SelfServiceRewardProgramRouteGuardService {
  constructor(private router: Router, private userGuardService: UserGuardService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.userGuardService.isAccountHaveSelfServiceProgramAllowed()) { return true };

    this.router.navigate([AdminPageRoute.Reward]);
    return false;
  }
}

@Injectable({ providedIn: 'root' })
export class RecognitionRouteGuardService {
  constructor(private router: Router, private userGuardService: UserGuardService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.userGuardService.isRecognitionEnabled()) { return true };

    this.router.navigate([AdminPageRoute.Layout]);
    return false;
  }
}

@Injectable({ providedIn: 'root' })
export class HasManagerOrAdminAccess {
  constructor(private router: Router, private userGuardService: UserGuardService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.userGuardService.isUserManager() || this.userGuardService.doesProgramAdminHaveAccess() || this.userGuardService.doesRewardSenderHaveAccess()) { return true };

    this.router.navigate([AdminPageRoute.Layout]);
    return false;
  }
}

