<div class="toaster">
  <div class="snack-bar-message">
    <span [innerHtml]="snackbar.message"></span>
  </div>
  <div class="snack-bar-actions">
    <div class="text-right">
      <div class="btn-action" *ngIf="snackbar.actionType">
        <button mat-button class="primary-btn"
          (click)="callSnackbarAction(snackbar.actionType)">{{snackbar.actionType}}</button>
      </div>
      <div class="close-action">
        <mat-icon class="close-icon material-icons-round" (click)="closeToaster();">cancel</mat-icon>
      </div>
    </div>
  </div>
</div>