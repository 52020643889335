export const environment = {
  production: false,
  development: true,
  isDebug: true,
  env: 'beta',
  apiUrl: 'https://beta.enjoywishlist.com/',
  rewardPortalUrl: 'https://betarewards.enjoywishlist.com/',
  recognitionPortalUrl: 'https://betarecognition.enjoywishlist.com/',
  microsoftTeamsAADClientId: 'd8c49165-e999-45f7-93e4-24b0f191e224',
  googleMapApiKey: 'AIzaSyBJyb7pEhya-jJ9vNnp_9ZYF_awos7DYoM',
  quartzBaseUrl: 'https://betascheduler.enjoywishlist.com/',
  enableWorkerService: true,
  googleTagManagerId: null,
  googleClientId: "132463959318-2gce55elt3ca7od7iv51vvktfhsk1dpj.apps.googleusercontent.com",
  azureFunctionUrl: 'https://wl-func-beta.azurewebsites.net/',
  rsaEncryptKey: `-----BEGIN PUBLIC KEY-----
  MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC9PDXAzePK3HpWwKY5AyB/RoIr
  g1+Pj6s7VvjXSQVAnNjMJ5QI5JApiWy+Nd9NjEo65AZtS8524coh9/QMEjFEdQ8O
  6IRNhetwHfC2fkUBLp0BrQghmGowhln5W/4gUL3eEny9QBF388Y77FwB3QMW/b9V
  JnARI6lpxuN6Q5DD+QIDAQAB
  -----END PUBLIC KEY-----`,
  recaptchV3SiteValue: "6LeorPMUAAAAAH0auMLn7AU7IVgg3fJIwe-5VlCO",
  amplitudeApiKey: "0357e1d2cbc369ef136bddcbf07f56ed",
  sentryDns: "https://6c2965c59218ee4a4ccae9d7ce18b672@o4507819318706176.ingest.us.sentry.io/4507966541201408"
};
