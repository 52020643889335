import { AppState } from '../../../store/reducers/index';
import { Store } from '@ngrx/store';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core"
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { RecognitionRenderNormalRequested, RecognitionRenderNormalActionTypes, RecognitionRenderNormalLoaded, RecognitionRenderNormalLoadingError } from './recognition.render.normal.actions';
import { RecognitionHandlerService } from '../../../providers/recognition/recognition.handler';
import { of } from 'rxjs';
import { NewsFeedLastUpdatedAt } from '../../newsfeed/newsfeed.component/newsfeed.actions';
import { RecognitionApiService } from '../../../providers/api.service/recognition.api.service';

@Injectable()
export class RecognitionRenderNormalEffects {

  constructor(private actions$: Actions, private recognitionHandlerService: RecognitionHandlerService, private store: Store<AppState>, private _recognitionApiService: RecognitionApiService) { }

  
  loadRecognition$ = createEffect(() => this.actions$
    .pipe(
      ofType<RecognitionRenderNormalRequested>(RecognitionRenderNormalActionTypes.RecognitionRenderNormalRequested),
      mergeMap(action => this._recognitionApiService.getRecognitionFeedOfAccount(action.payload.query)
        .pipe(
          catchError(error => {
            this.store.dispatch(new RecognitionRenderNormalLoadingError({ errorMessage: error.message }));
            return of({
              data: []
            });
          }),
        )),
      map((result) => {
        this.store.dispatch(new NewsFeedLastUpdatedAt({ lastUpdatedAt: (new Date()).toString() }));
        return new RecognitionRenderNormalLoaded({ data: this.recognitionHandlerService.handlerRecognitionData(result?.data?.data ?? []), hasNextPage: (result?.data?.data?.length > 0), loading: false })
      })
    ))
}
