
export enum NotificationType {
  ThankYouNote = 'ThankYouNote',
  UndeliverableReward = 'UndeliverableReward',
  RequestReward = 'RequestReward',
  PendingReward = 'PendingReward',
  LowBalance = 'LowBalance',
  DepositFund = 'DepositFund',
  ReportReady = 'ReportReady',
  UpcomingReward = 'UpcomingReward',
  DirectOrders = 'DirectOrders',
  GroupLeader = 'GroupLeader',
  Approver = 'Approver',
  ManagerNotification = "ManagerNotification",
}
