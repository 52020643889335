import { AppState } from './../../store/reducers/index';
import { select, Store } from '@ngrx/store';
import { filter, map, mergeMap, withLatestFrom, catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core"
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { OverTimeRequested, UsageInsightActionTypes, OverTimeLoaded, StarsRequested, StarsLoaded, TagRequested, TagLoaded, TagUpdateRequested, TagUpdated, OverTimeLoadError, TagLoadError, StarsLoadError } from './usage.insights.actions';
import { isOverTimeLoadedSelector, selectIsStarsLoadedSelector, selectIsTagLoadedSelector } from './usage.insights.selectors';
import { of } from 'rxjs';
import { RecognitionApiService } from '../../providers/api.service/recognition.api.service';

@Injectable()
export class UsageInsightsffects {

    constructor(private actions$: Actions, private _recognitionApiService: RecognitionApiService, private store: Store<AppState>) { }

    
    loadOverTime$ = createEffect(() => this.actions$
        .pipe(
            ofType<OverTimeRequested>(UsageInsightActionTypes.UsageInsightOverTimeRequested),
            withLatestFrom(this.store.pipe(select(isOverTimeLoadedSelector))),
            filter(([action, isLoaded]) => !isLoaded),
            mergeMap(action => this._recognitionApiService.getRecognitionOverTime().pipe(
                catchError(error => {
                    this.store.dispatch(new OverTimeLoadError({ errorMessage: error.message }));
                    return of({
                        data: []
                    });
                })
            )),
            map((result) => {
                return new OverTimeLoaded({ data: result.data })
            })
        ))

    
    loadStars$ = createEffect(() => this.actions$
        .pipe(
            ofType<StarsRequested>(UsageInsightActionTypes.StarsRequested),
            withLatestFrom(this.store.pipe(select(selectIsStarsLoadedSelector))),
            filter(([action, isLoaded]) => !isLoaded),
            mergeMap(action => this._recognitionApiService.getRecognitionTopStarRewardLeader()
                .pipe(
                    catchError(error => {
                        this.store.dispatch(new StarsLoadError({ errorMessage: error.message }))
                        return of({
                            receivers: [],
                            senders: []
                        });
                    })
                )),
            map((result) => {
                return new StarsLoaded(result)
            })
        ))

    
    loadInsightTags$ = createEffect(() => this.actions$
        .pipe(
            ofType<TagRequested>(UsageInsightActionTypes.UsageInsightTagRequested),
            withLatestFrom(this.store.pipe(select(selectIsTagLoadedSelector))),
            filter(([action, isLoaded]) => (!isLoaded)),
            mergeMap(action => this._recognitionApiService.getRecognitionUsageDataTags(action[0]['queryParam'])
                .pipe(
                    catchError(error => {
                        this.store.dispatch(new TagLoadError({ errorMessage: error.message }))
                        return of({
                            data : []
                        });
                    })
                )),
            map((result) => {
                return new TagLoaded({ data: result.data })
            })
        ))

    
    InsightUpdateTags$ = createEffect(() => this.actions$
        .pipe(
            ofType<TagUpdateRequested>(UsageInsightActionTypes.UsageInsightTagUpdateRequested),
            mergeMap(action => {
                return this._recognitionApiService.getRecognitionUsageDataTags(action['queryParam'])
            }),
            map((result) => {
                return new TagUpdated({ data: result.data })
            })
        ))
}
