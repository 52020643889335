import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MarketplaceRoute } from "../../common/page.route/page.route";
import { AuthService } from "../authentication/auth.service";
import { LocalStorageService } from "../localstorage/localStorage.service";
import { UserLocalStorageKey } from "../../modals/keys/local.storage";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class UrlUtilityService {
  constructor(private authService: AuthService, private _router: Router, private _localStorageService: LocalStorageService) { }
  private readonly currentRelativeUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  goToWalletMarketplace(): void {
    this._router.navigateByUrl(MarketplaceRoute.Home);
  }

  goToMarketplace(url: string): void {
    this.openUrlInNewTab(url, false, false);
  }

  openUrlInNewTab(url: string, checkMsTeam?: boolean, sameTab?: boolean) {
    let teams = this.authService.getIsAppHostedOnMsTeam();
    let target = sameTab || (checkMsTeam && teams) ? "_self" : "_blank";
    window.open(url, target);
  }

  getInviteIdentifier() {
    let redirectUrl = this._localStorageService.get(UserLocalStorageKey.RedirectUrl);
    if (redirectUrl) {
      try {
        const url = new URL(redirectUrl);
        let identifier = url.searchParams.get('identifier')?.replace(/^"|"$/g, '');
        if (identifier) return identifier;

        return url.searchParams.get('addGiftCard')?.replace(/^"|"$/g, '');
      } catch { }
    }
    return null;
  }

  getRedirectUrl = () => this._localStorageService.get(UserLocalStorageKey.RedirectUrl);

  getQueryParamSubStr = (url: string) => {
    let urlParts = url.split('?');
    if (urlParts.length > 1) return urlParts[1];
    else return null;
  }

  getQueryParamValue(searchParam: string, urlString: string) {
    if (!searchParam || !urlString) return null;
    try {
      const url = new URL(urlString);
      return url.searchParams.get(searchParam)?.replace(/^"|"$/g, '');
    } catch {
      return null;
    }
  }

  getCurrentRelativeUrl = (): Observable<string> => this.currentRelativeUrl.asObservable();

  setCurrentRelativeUrl = (value) => this.currentRelativeUrl.next(value);

  isCurrentRouteExists = (url: string, routes: string[]) => routes?.some(x => url?.indexOf(x) >= 0);
}
