export type Account = {
  accountId: number;
  accountStatus: AccountStatus;
  companyLogo: string;
  accountName: string;
  inviteTypesAllowed: string[];
  feedStatsWidgetVisible: boolean;
  isAccountSettingsEnabled: boolean;
  isChannelPartnerAccount: boolean;
  isInternationalEnabled: boolean;
  isWalletRedemptionAllowed: boolean;
  isWishlistLabAllowed: boolean;
  isHrSystemEnabled: boolean;
  isSpotRewardAllowed: boolean;
  isCreditCardEnabled: boolean;
  hasCustomSurveys: boolean;
  isCustomRewardEnabled: boolean;
  isDefaultUserRoleEnabled: boolean;
  defaultRoles: string[];
  accountLaunchDateUtc: string;
  isRecognitionEnabled: boolean;
  isSeeBudgetAllowed: boolean;
  setAlternateEmail: boolean;
  isSpotRewardAllowedForSender: boolean;
  isOnboardingSurveyRequired: boolean;
  hasRewardSelfService: boolean;
  name?: string;
  isLeaderBoardEnabled: boolean;
  notificationBannerData?: string;
  isRewardSpecificityAllowed: boolean;
  isSpotProgramSelectionEnabled: boolean;
  isRecognitionAiEnabled: boolean;
  isApprovalProcessAllowed?: boolean;
  primaryApprover?: string;
  cpType?: CPType;
  accountSecurityKey: string;
  isSmsLoginEnabled: boolean;
  isPrivacyPolicyAccepted:boolean;
}

export enum AccountStatus {
  Closed = 'Closed',
  Active = 'Active',
  PreLaunch = 'PreLaunch'
}

export type WalletConfig = {
  walletBalance: number;
  currencyCode: string;
}

export enum CPType {
  None,
  V1 = "V1",
  V2 = "V2"
}

export default Account;
