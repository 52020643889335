import { FilteringActionType, PopularSearchLoadingError, PopularSearchRequested, PopularSearchLoaded } from './filtering.actions';
import { ApiService } from '../../../../providers/api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from "@angular/core";
import { AppState } from '../../../../store/reducers';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { RecognitionApiService } from 'projects/recognition/src/app/providers/api.service/recognition.api.service';

@Injectable()
export class PopularSearchEffects {

  constructor(private actions$: Actions, private _recognitionApiService: RecognitionApiService, private store: Store<AppState>) { }

  
  loadPopularSearch$ = createEffect(() => this.actions$
    .pipe(
      ofType<PopularSearchRequested>(FilteringActionType.PopularSearchRequested),
      mergeMap(action => this._recognitionApiService.getPopularSearchList()
        .pipe(
          catchError(error => {
            this.store.dispatch(new PopularSearchLoadingError(error.message));
            return of({
              data: []
            });
          }),
        )),
      map((result) => {
        let { data } = result;
        return new PopularSearchLoaded({ data: data ? data : [] });
      })
    ))
}
