import { AppState } from '../../store/reducers';
import { Store } from '@ngrx/store';
import { map, mergeMap } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { AccountApiService } from '../../providers/api.service';
import { RewardTagActionTypes, RewardTagLoaded, RewardTagRequested } from './reward.tag.actions';

@Injectable()
export class RewardTagEffects {

  constructor(private actions$: Actions, private accountApiService: AccountApiService, private store: Store<AppState>) { }

  
  loadTags$ = createEffect(() => this.actions$
    .pipe(
      ofType<RewardTagRequested>(RewardTagActionTypes.RewardTagRequested),
      mergeMap(action => this.accountApiService.getRewardTagList()),
      map((result) => {
        return new RewardTagLoaded({ rewardTag: (result?.data ? result?.data : []) });
      })
    ))
}

