import { Member, OverTime, Tags } from '../../modals/UsageInsights';

import { UsageInsightActionTypes } from './usage.insights.actions';
import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';

export interface OverTimeState extends EntityState<OverTime> {
    isLoaded: boolean;
    errorMessage?: string;
}
export interface TagsState extends EntityState<Tags> {
    isLoaded: boolean;
    errorMessage?: string;
}

export const overTimeadapter: EntityAdapter<OverTime> = createEntityAdapter<OverTime>({
    selectId: (overtime: OverTime) => overtime.month
});

export type StarState = {
    receivers: Member[];
    senders: Member[];
    isLoaded: boolean;
    errorMessage?: string;
}

export const initialStarState: StarState = {
    receivers: [],
    senders: [],
    isLoaded: false,
}


export const tagadapter: EntityAdapter<Tags> = createEntityAdapter<Tags>({
    selectId: (tag: Tags) => tag.tag
});

const intialOverTimeState = overTimeadapter.getInitialState({
    isLoaded: false
});
const intialTagState = tagadapter.getInitialState({
    isLoaded: false
});

export function overTimeReducer(state: OverTimeState = intialOverTimeState, action): OverTimeState {

    switch (action.type) {

        case UsageInsightActionTypes.UsageInsightOverTimeLoaded:

            return overTimeadapter.addMany(action.payload.data, { ...state, isLoaded: true });

        case UsageInsightActionTypes.UsageInsightOverTimeLoadError:

            return { ...state, isLoaded: false, errorMessage: action.payload.errorMessage }

        default:

            return { ...state };
    }
}

export function starReducer(state: StarState = initialStarState, action): StarState {

    switch (action.type) {

        case UsageInsightActionTypes.StarsLoaded:
            let data = action.payload;
            return { ...data, isLoaded: true };

        case UsageInsightActionTypes.StarsLoadingError:

            return { ...state, isLoaded: false, errorMessage: action.payload.errorMessage };

        default:

            return { ...state };
    }
}

export function tagChartReducer(state: TagsState = intialTagState, action): TagsState {

    switch (action.type) {

        case UsageInsightActionTypes.UsageInsightTagLoaded:

            return tagadapter.addMany(action.payload.data, { ...state, isLoaded: true })

        case UsageInsightActionTypes.UsageInsightTagUpdated:

            return tagadapter.updateMany(action.payload.data, { ...state, isLoaded: true })

        case UsageInsightActionTypes.UsageInsightTagLoadError:

            return { ...state, isLoaded: false, errorMessage: action.payload.errorMessage }

        default:
            return { ...state };
    }
}

export const {
    selectAll
} = overTimeadapter.getSelectors();



