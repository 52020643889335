import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { FilteringActionType } from './filtering.actions';
import { RecognitionTag } from '../../../../modals/recogntion.tags';

export interface PopularSearchedState extends EntityState<RecognitionTag> {
  loading?: boolean;
  loaded?: boolean;
  errorMessage?: string;
}

export const popularSearchedStateAdapter: EntityAdapter<RecognitionTag> = createEntityAdapter<RecognitionTag>({
  selectId: (popular: RecognitionTag) => popular.identifier
});

const initialPopularSearchedState = popularSearchedStateAdapter.getInitialState({
  loaded: false
});

export function PopularSearchedStateReducer(state = initialPopularSearchedState, action): PopularSearchedState {
  switch (action.type) {
    case FilteringActionType.PopularSearchRequested:
      return { ...state, loading: true }

    case FilteringActionType.PopularSearchLoaded:
      return popularSearchedStateAdapter.addMany(action.payload.data, { ...state, loading: false });
    default:
      return { ...state }
  }
}

export const {
  selectAll
} = popularSearchedStateAdapter.getSelectors();


