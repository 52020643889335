import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { RewardTag, RewardTagActionTypes } from './reward.tag.actions';

export interface RewardTagState extends EntityState<RewardTag> {
}

export const rewardTagAdapter: EntityAdapter<RewardTag> = createEntityAdapter<RewardTag>({
  selectId: (rewardTag: RewardTag) => rewardTag.identifier
});

const intialRewardTagState = rewardTagAdapter.getInitialState();

export function rewardTagReducer(state = intialRewardTagState, action): RewardTagState {

  switch (action.type) {

    case RewardTagActionTypes.RewardTagLoaded:

      return rewardTagAdapter.addMany(action.payload.rewardTag, { ...state });

    default:
      return { ...state };
  }
}

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal
} = rewardTagAdapter.getSelectors();



