import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { CoworkerCacheService } from '../../../../../coworker-service';
import User from '../../modals/user';
import { StoreService } from '../../store/store.service';
import { AccountApiService } from '../api.service';
import { RecognitionApiService } from '../api.service/recognition.api.service';

@Injectable({ providedIn: 'root' })
export class SearchCoWorkerService {
  private getCoworkerList$: Subscription;
  private limitOfSearchKeywordOnExisting: number;
  private limitOfSearchKeywordOnEmpty: number;
  private user: User;
  constructor(public coworkerCacheService: CoworkerCacheService, public _accountApiService: AccountApiService, private storeService: StoreService, private _recognitionApiService: RecognitionApiService) {
    this.limitOfSearchKeywordOnExisting = 4;
    this.limitOfSearchKeywordOnEmpty = 1
    this.storeService.getUser().subscribe(x => this.user = x);
  }

  private isCachedExpired(): boolean {
    return this.coworkerCacheService.isCacheExpired();
  }

  private getCoworkerListFromCache(keyword, isSelfAllowed: boolean): any[] {
    let coworkerList = this.coworkerCacheService.getCoWorkerDetails(keyword);
    return this.setModificationOfCoworkerData(coworkerList, isSelfAllowed);
  }

  private getCoworkerListFromApi(keyword: string = "", isAdminFetching: boolean, selfSearchAllowed:boolean = false) {
    if (isAdminFetching) {
      return this._accountApiService.getAutoCompletePeopleRoster({ searchKeyword: keyword, pagesize: 1000 })
    }
    else {
      return this._recognitionApiService.getCoworkerListBySearchKeyword({ searchKeyword: keyword, pagesize: 1000 })
    }
  }

  private setModificationOfCoworkerData(coworkerList: any[] = [], isSelfAllowed: boolean): any[] {
    coworkerList.map((coworker) => {
      if (!coworker.isEligibleForReward && coworker.sendReward) {
        coworker.isEligibleForReward = coworker.sendReward;
      }
      if (!coworker.isEligibleForRecognition) {
        coworker.isEligibleForRecognition = ((!coworker.isGroup && coworker.email?.trim().length > 0) || coworker.isGroup);
      }
    });

    return this.filterBasedOnIsSelfAllowedValue(coworkerList, isSelfAllowed);
  }

  private filterBasedOnIsSelfAllowedValue(coworkerList: any[] = [], isSelfAllowed: boolean): any[] {
    if (!isSelfAllowed) {
      let loggedInUser = this.user;
      if (loggedInUser?.userGuid) {
        coworkerList.forEach((element) => { if (element.guid) { element.userGuid = element.guid; } else { element.guid = element.userGuid; } });
        coworkerList = coworkerList.filter((coworker) => { return (!coworker.isGroup && coworker.guid != loggedInUser?.userGuid) || coworker.isGroup });
      }
    }
    return coworkerList;
  }

  getCoworkerListByKeywordCache(keyword: string = "", isSelfAllowed: boolean = false, isAdminFetching: boolean = false, onInit: boolean = false): Promise<any[]> {
    return new Promise((resolve) => {
      let regex = new RegExp("&nbsp;");
      keyword = keyword?.replace(regex, " ");
      keyword = keyword?.trim();
      let coworkerList: any[] = [];
      coworkerList = this.getCoworkerListFromCache(keyword, isSelfAllowed);
      resolve(coworkerList);
    });
  }

  getCoworkerListByKeywordFromServer(keyword: string = "", coworkerList: any[] = [], isSelfAllowed: boolean = false, isAdminFetching: boolean = false, onInit: boolean = false, selfSearchAllowed:boolean = false): Promise<any[]> {
    return new Promise((onFulFilled) => {
      if ((onInit && this.isCachedExpired()) || (coworkerList.length == 0 && keyword?.length > this.limitOfSearchKeywordOnEmpty) || (coworkerList.length > 0 && coworkerList.length < 200 && keyword?.length > this.limitOfSearchKeywordOnExisting)) {
        if (this.getCoworkerList$) { this.getCoworkerList$.unsubscribe(); }
        this.getCoworkerList$ = this.getCoworkerListFromApi(keyword, isAdminFetching, selfSearchAllowed).subscribe((response) => {
          let data = response?.data ? response.data : [];
          data = this.setModificationOfCoworkerData(data, isSelfAllowed);
          this.coworkerCacheService.setCoWorkerDetails(data);
          coworkerList = this.getCoworkerListFromCache(keyword, isSelfAllowed);
          onFulFilled(coworkerList);
        }, (error) => {
          coworkerList = this.getCoworkerListFromCache(keyword, isSelfAllowed);
          onFulFilled(coworkerList);
        });
      }
      else {
        coworkerList = this.getCoworkerListFromCache(keyword, isSelfAllowed);
        onFulFilled(coworkerList);
      }
    });
  }

  clearCachedCoworkerData() {
    this.coworkerCacheService.removeCoWorkerCachedData();
  }
}
