import { EventEmitter, Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { environment } from '../../../environments/environment';
import { signalRConnectionUrl, SignalRChannelType } from '../../modals/keys/signalR.key';
import { LocalStorageService } from '../localstorage/localStorage.service';
import { HttpTransportType } from '@microsoft/signalr';

@Injectable({ providedIn: 'root' })
export class SignalRService {
  private hubConnection: signalR.HubConnection;
  private apiUrl: string;
  private quartzJob: EventEmitter<any>;

  constructor() {
    this.apiUrl = environment.quartzBaseUrl;
    this.quartzJob = new EventEmitter<any>();
  }

  public initOnOffConnection(connect: boolean) {
    if (connect) {
      this.initHubConnection();
      this.startHubConnection();
    }
    else {
      this.destroyHubConnection();
    }
  }

  private initHubConnection() {
    let connectionUrl = this.apiUrl + signalRConnectionUrl;
    this.hubConnection = new signalR.HubConnectionBuilder().withUrl(connectionUrl, { skipNegotiation: true, transport: HttpTransportType.WebSockets, withCredentials : true }).withAutomaticReconnect().build();
  }

  private startHubConnection() {
    if (this.hubConnection) {
      this.hubConnection.start().then(() => { }).catch((error) => {
        setTimeout(() => {
          if (environment.production) {
            this.startHubConnection();
          }
        }, 3000);
      });
    }
  }

  private destroyHubConnection() {
    this.hubConnection?.stop();
  }

  private async turnOffHubConnection(ChannelName: SignalRChannelType) {
    this.hubConnection?.off(ChannelName);
  }

  private async turnOnHubConnection(ChannelName: SignalRChannelType) {
    if (this.hubConnection) {
      this.hubConnection.on(ChannelName, (data: any) => {
        this.setFetchedValueFromHubConnection(ChannelName, data);
      });
    }
  }

  private setFetchedValueFromHubConnection(ChannelName: SignalRChannelType, value: any) {
    let response: any = null;
    try { response = JSON.parse(value); }
    catch (e) { response = value; }

    switch (ChannelName) {
      case SignalRChannelType.SignalRChannel:
        this.quartzJob.emit(response);
    }
  }

  public turnOnOffHubConnection(ChannelName: SignalRChannelType, turn: boolean) {
    if (turn) {
      this.turnOnHubConnection(ChannelName);
    }
    else {
      this.turnOffHubConnection(ChannelName);
    }
  }

  public getFetchedValueFromHubConnection(ChannelName): EventEmitter<any> {
    switch (ChannelName) {
      case SignalRChannelType.SignalRChannel:
        return this.quartzJob;
      default: return new EventEmitter<any>();
    }
  }
}
