import { createAction, createFeatureSelector, createReducer, on, props } from "@ngrx/store";
import { ExploreRewardProductDto, PreferredSubCategoryProduct } from "./explore-rewards.model";

export interface ExploreRewardState {
    favoriteProducts: ExploreRewardProductDto[];
    preferredSubCategoryProducts: PreferredSubCategoryProduct[];
    trendingProducts: ExploreRewardProductDto[];
    isLoaded: boolean
}

export const setExploreRewardFeed = createAction('[Explore Reward] Set Feed', props<ExploreRewardState>());

const initialExploreRewardState: ExploreRewardState = { favoriteProducts: [], preferredSubCategoryProducts: [], trendingProducts: [], isLoaded: false };

export const exploreRewardReducer = createReducer(
    initialExploreRewardState,
    on(setExploreRewardFeed, (state, payload) => ({
        ...state,
        ...payload,
    }))
);

export const selectExploreRewardState = createFeatureSelector<ExploreRewardState>('exploreReward');