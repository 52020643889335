import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarAction } from '../../modals/snackbar';
import { SnackBarComponent } from '../snackbar/snackbar/snackbar.component';

@Injectable({ providedIn: 'root' })
export class SnackBarService {

  public constructor(private snackbar: MatSnackBar) { }

  public openSnackBar(message: string, actionType: SnackbarAction = null, values: any = null, duration: number = 2000, isLoginPage: boolean = false): MatSnackBarRef<SnackBarComponent> {
    let snackBarConfig = new MatSnackBarConfig();
    snackBarConfig.panelClass = ['snackbar-component'];
    snackBarConfig.verticalPosition = 'bottom';
    snackBarConfig.horizontalPosition = 'left';
    snackBarConfig.duration = duration;
    snackBarConfig.data = { message, actionType, values: values, isLoginPage };
    let ref = this.snackbar.openFromComponent(SnackBarComponent, snackBarConfig);
    return ref;
  }

  public closeSnackBar() {
    this.snackbar.dismiss();
  }
}
