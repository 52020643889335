import { map, mergeMap } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { BusinessGoalsLoaded, BusinessGoalsRequested, BusinessGoalActionTypes } from './business.goal.actions';
import { of } from 'rxjs';
import { UserGuardService } from '../../providers/authentication/user.guard.service';
import { RecognitionApiService } from '../../providers/api.service/recognition.api.service';

@Injectable()
export class BusinessGoalEffects {

  constructor(private _actions$: Actions, private _recognitionApiService: RecognitionApiService, private _userGuardService: UserGuardService) { }

  
  loadTags$ = createEffect(() => this._actions$
    .pipe(
      ofType<BusinessGoalsRequested>(BusinessGoalActionTypes.BusinessGoalRequested),
      mergeMap(action => (this._userGuardService.doesUserHaveAccessAccountPortal() ? this._recognitionApiService.getBusinessGoalList() : of({ data: [] }))),
      map((result) => {
        return new BusinessGoalsLoaded({ goalList: (result?.data?.length ? result.data : []) })
      })
    ))
}

