import { Injectable } from "@angular/core";
import { AzureAnalyticsDto, AzurePlatformType, AzureProductTrackingEvent, AzureCategoryType, AzureStatusCodes } from "projects/assets/model/azure";
import { environment } from "projects/recognition/src/environments/environment";
import { of } from "rxjs";
import { ConfigService } from "./config.service";

@Injectable({ providedIn: 'root' })
export class AzureApiService {
    constructor(private configService: ConfigService) { }

    async postProductDetailAnalytics(productAttrGuid: string, eventname: AzureProductTrackingEvent, startDate: Date, endDate: Date, errorMessage: string = '', statusCodes: AzureStatusCodes = AzureStatusCodes.Ok, productName: string = '', capacityType: string = '', capacityValue: number = null, categoryType: AzureCategoryType = null) {
        return of({});
        let data: AzureAnalyticsDto = new AzureAnalyticsDto();
        data.productAttrGuid = productAttrGuid;
        data.eventName = eventname;
        data.errorMessage = errorMessage;
        data.type = AzurePlatformType.WalletMarketPlace;
        data.statusCodes = statusCodes;
        data.capacityType = capacityType;
        data.capacityValue = capacityValue;
        data.productName = productName;
        data.rewardType = this.GetCategoryType(categoryType);
        try {
            data.eventStartTimeUtc = startDate ? startDate.toISOString() : "";
            data.eventEndTimeUtc = endDate ? endDate.toISOString() : "";
        }
        catch {
            data.eventStartTimeUtc = "";
            data.eventEndTimeUtc = "";
        }

        this.postUserAnalyticsOfProduct(data);
    }

    private GetCategoryType(productType: AzureCategoryType) {
        let rewardType = productType;
        if (productType) {
            switch (productType) {
                case AzureCategoryType.ViatorV2:
                case AzureCategoryType.Viator:
                case AzureCategoryType.FareHarbor:
                case AzureCategoryType.AutoCode:
                    rewardType = AzureCategoryType.Experience;
                    break;
                case AzureCategoryType.DreamshipV2:
                case AzureCategoryType.Swag:
                case AzureCategoryType.Physical:
                    rewardType = AzureCategoryType.Swag;
                    break;
            }
        }

        return rewardType;
    }

    async postLocationAnalytics(locationData: any) {
        let data = { locationData: { name: locationData?.shortName, placeId: locationData?.placeId, state: locationData?.state, countryName: locationData?.countryName, Latitude: locationData?.lat, Longitude: locationData?.long }, PlatformType: AzurePlatformType.WalletMarketPlace };
        this.postUserAnalyticsOfLocation(data);
    }

    private postUserAnalyticsOfProduct(params) {
        return this.configService?.post('api/user/analytics', params, null, null, null, null, environment.azureFunctionUrl)?.subscribe();
    }

    private postUserAnalyticsOfLocation(params) {
        return this.configService?.post('api/user/location/analytics', params, null, null, null, null, environment.azureFunctionUrl)?.subscribe();
    }
}