import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class KinectionService {
  private myStateRefreshSubject: Subject<boolean> = new Subject();

  public setMyStatsRefreshRequired(val: boolean): void {
    this.myStateRefreshSubject.next(val);
  }

  public getMyStatsRefreshRequired(): Observable<boolean> {
    return this.myStateRefreshSubject.asObservable();
  }
}
