import { Client } from '@microsoft/microsoft-graph-client';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { MSOAuthSettings } from '../../modals/keys/login';

@Injectable({ providedIn: "root" })

export class AuthServiceMSSSO {
  public authenticated: boolean;
  public user: User;
  constructor(private msalService: MsalService) {  }
  // Prompt the user to sign in and
  // grant consent to the requested permission scopes
  async signIn(): Promise<void> {
    let result = await this.msalService.loginPopup(MSOAuthSettings).toPromise()
      .catch((reason) => {
        // this.toastService.openToast('Failed to sign in!');
      });
    if (result) {
      this.authenticated = true;
      this.user = new User();
      this.user.displayName = result.account.name;
      this.user.email = result.account.username;
    }
  }

  async openMicrosoftDialog(): Promise<void> {
    this.msalService.loginRedirect();
  }
  // Sign out
  signOut(): void {
    this.msalService.logout();
    this.user = null;
    this.authenticated = false;
  }

  // Silently request an access token
  async getAccessToken(): Promise<string> {
    let result = await this.msalService.acquireTokenSilent(MSOAuthSettings).toPromise()
      .catch((reason) => {
       alert('Failed to get access token!');
      });
    if (result) {
      return result.accessToken;
    }
    return null;
  }

  private async getUser(): Promise<User> {
    if (!this.authenticated) return null;
    let graphClient = Client.init({
      // Initialize the Graph client with an auth
      // provider that requests the token from the
      // auth service
      authProvider: async (done) => {
        let token = await this.getAccessToken()
          .catch((reason) => {
            done(reason, null);
          });

        if (token) {
          done(null, token);
        } else {
          done("Could not get an access token", null);
        }
      }
    });
    // Get the user from Graph (GET /me)
    let graphUser = await graphClient.api('/me').get();

    let user = new User();
    user.displayName = graphUser.displayName;
    // Prefer the mail property, but fall back to userPrincipalName
    user.email = graphUser.mail || graphUser.userPrincipalName;
    return user;
  }
}

export class User {
  displayName: string;
  email: string;
  avatar: string;
}
