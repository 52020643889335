import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class WindowResizeService {
  public deviceName: BehaviorSubject<string>;
  private deviceWidth: number;

  constructor() {
    this.deviceName = new BehaviorSubject("Desktop");
  }
  public detectWindowSize(width) {
    this.deviceWidth = width
    if (width >= 1024) {
      this.deviceName.next("Desktop");
    }
    else if (width < 1023 && width >= 768) {
      this.deviceName.next("Tablet");
    }
    else {
      this.deviceName.next("Mobile");
    }
  }

  public getDeviceName(): Observable<string>{
    return this.deviceName.asObservable();
  }

  public isBigDesktop(): boolean {
    return this.deviceWidth >= 1280;
  }

  public isDesktop(): boolean {
    return this.deviceName.value == 'Desktop'
  }

  public isTablet(): boolean {
    return this.deviceName.value == 'Tablet'
  }

  public isMobile(): boolean {
    return this.deviceName.value == 'Mobile'
  }
}
