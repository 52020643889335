import { Injectable } from '@angular/core';
import { WlWalkThrough } from 'projects/wl-walk-through';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class WlWalkThroughValueService {
  private newsFeedWalkThrough: BehaviorSubject<WlWalkThrough>;
  private nextNewsFeedWalkThrough: BehaviorSubject<WlWalkThrough>;
  private completedNewsFeedWalkThrough: BehaviorSubject<boolean>;
  private sendNewRewardWalkThrough: BehaviorSubject<WlWalkThrough>;
  private nextSendNewRewardWalkThrough: BehaviorSubject<WlWalkThrough>;
  private completedSendNewRewardWalkThrough: BehaviorSubject<boolean>;
  private isFeedWalkthroughInitialized: BehaviorSubject<boolean>;

  constructor() {
    this.newsFeedWalkThrough = new BehaviorSubject(null);
    this.nextNewsFeedWalkThrough = new BehaviorSubject(null);
    this.completedNewsFeedWalkThrough = new BehaviorSubject(false);
    this.sendNewRewardWalkThrough = new BehaviorSubject(null);
    this.nextSendNewRewardWalkThrough = new BehaviorSubject(null);
    this.completedSendNewRewardWalkThrough = new BehaviorSubject(false);
    this.isFeedWalkthroughInitialized = new BehaviorSubject(false);
  }

  setNewsFeedFeatureWalkThroughValue(value: WlWalkThrough) {
    this.newsFeedWalkThrough.next(value);
  }

  getNewsFeedFeatureWalkThroughValue(): Observable<WlWalkThrough> {
    return this.newsFeedWalkThrough.asObservable();
  }

  setNextNewsFeedFeatureWalkThroughValue(value: WlWalkThrough) {
    this.nextNewsFeedWalkThrough.next(value);
  }

  getNextNewsFeedFeatureWalkThroughValue(): Observable<WlWalkThrough> {
    return this.nextNewsFeedWalkThrough.asObservable()
  }

  setCompletedNewsFeedFeatureWalkThroughValue(value: boolean) {
    this.completedNewsFeedWalkThrough.next(value);
  }

  getCompletedNewsFeedFeatureWalkThroughValue(): Observable<boolean> {
    return this.completedNewsFeedWalkThrough.asObservable()
  }

  setSendNewRewardFeatureWalkThroughValue(value: WlWalkThrough) {
    this.sendNewRewardWalkThrough.next(value);
  }

  getSendNewRewardFeatureWalkThroughValue(): Observable<WlWalkThrough> {
    return this.sendNewRewardWalkThrough.asObservable();
  }

  setNextSendNewRewardWalkThroughValue(value: WlWalkThrough) {
    this.nextSendNewRewardWalkThrough.next(value);
  }

  getNextSendNewRewardWalkThroughValue(): Observable<WlWalkThrough> {
    return this.nextSendNewRewardWalkThrough.asObservable()
  }

  setCompletedSendNewRewardWalkThroughValue(value: boolean) {
    this.completedSendNewRewardWalkThrough.next(value);
  }

  getCompletedSendNewRewardWalkThroughValue(): Observable<boolean> {
    return this.completedSendNewRewardWalkThrough.asObservable()
  }

  setInitializeFeedWalkThrough(value: boolean) {
    return this.isFeedWalkthroughInitialized.next(value);
  }

  getInitializeFeedWalkThrough(): Observable<boolean> {
    return this.isFeedWalkthroughInitialized.asObservable();
  }
}
