import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RsaEncryptionService } from '../service';

@Injectable({
  providedIn: 'root'
})
export class EncryptInterceptor implements HttpInterceptor {
  constructor(private _rsaEncryptionService: RsaEncryptionService) { }
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this._rsaEncryptionService.intercept(request, next);
  }
}
