<div class="snack-bar" [ngClass]="data?.type == AlterSnackbarType ? 'snack-bar-alert' : 'snack-bar-normal'">
    <ng-container *ngIf="data?.infoType">
        <div class="snack-bar-icon">
            <ng-container [ngSwitch]="data.infoType">
                <ng-container *ngSwitchCase="InfoIconType">
                    <img [src]="image_icon_warn_fafron" alt="">
                </ng-container>
                <ng-container *ngSwitchCase="WarnIconType">
                    <img [src]="image_icon_waiting_clock" alt="">
                </ng-container>
                <ng-container *ngSwitchCase="SuccessIconType">
                    <img [src]="image_icon_success_tick_green" alt="">
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="data?.message">
        <div class="snack-bar-message">
            <span [innerHTML]="data?.message | sanitizeHtml"></span>
        </div>
    </ng-container>
    <ng-container>
        <div class="snack-bar-actions">
            <ng-container *ngIf="data?.dismissWithSecondaryAction">
                <button mat-button (click)="dismissedWithSecondaryAction()">{{data.dismissWithSecondaryAction}}</button>
            </ng-container>
            <ng-container *ngIf="data?.dismissWithPrimaryAction">
                <button mat-button (click)="dismissedWithPrimaryAction()">{{data?.dismissWithPrimaryAction}}</button>
            </ng-container>
            <ng-container *ngIf="!(data?.dismissWithSecondaryAction || data?.dismissWithPrimaryAction)">
                <button mat-button (click)="snackbarDismiss()">
                    <mat-icon class="material-icons-round">
                        close
                    </mat-icon>
                </button>
            </ng-container>
        </div>
    </ng-container>
</div>