import { Action } from '@ngrx/store';
import { OverTime, Tags } from '../../modals/UsageInsights';

export class RecognitionQueryParams {
    pageIndex: number;
    pageSize: number;
}

export enum UsageInsightActionTypes {
    UsageInsightOverTimeRequested = "[USAGE_INSIGHTS] over time requested",
    UsageInsightOverTimeLoaded = "[USAGE_INSIGHTS] over time loaded",
    UsageInsightOverTimeLoadError = "[USAGE_INSIGHTS] over time load error",
    UsageInsightTagRequested = "[USAGE_INSIGHTS] tag requested",
    UsageInsightTagLoaded = "[USAGE_INSIGHTS] tag loaded",
    UsageInsightTagLoadError = "[USAGE_INSIGHTS] tag loading error",
    UsageInsightTagUpdateRequested = "[USAGE_INSIGHTS] tag update requested",
    UsageInsightTagUpdated = "[USAGE_INSIGHTS] tag updated",
    StarsRequested = "[USAGE_INSIGHTS] stars requested",
    StarsLoaded = "[USAGE_INSIGHTS] stars loaded",
    StarsLoadingError = "[USAGE_INSIGHTS] stars loading error"
}

export class OverTimeRequested implements Action {
    readonly type = UsageInsightActionTypes.UsageInsightOverTimeRequested;
    constructor() {
    }
}

export class OverTimeLoaded implements Action {
    readonly type = UsageInsightActionTypes.UsageInsightOverTimeLoaded;
    constructor(public payload: { data: OverTime[] }) {
    }
}
export class OverTimeLoadError implements Action {
    readonly type = UsageInsightActionTypes.UsageInsightOverTimeLoadError;
    constructor(public payload: { errorMessage : string }) {
    }
}

export class TagRequested implements Action {
    readonly type = UsageInsightActionTypes.UsageInsightTagRequested;
    constructor(public queryParam: any) {
    }
}

export class TagLoaded implements Action {
    readonly type = UsageInsightActionTypes.UsageInsightTagLoaded;
    constructor(public payload: { data: Tags[] }) {
    }
}
export class TagLoadError implements Action {
    readonly type = UsageInsightActionTypes.UsageInsightTagLoadError;
    constructor(public payload: { errorMessage : string }) {
    }
}

export class TagUpdateRequested implements Action {
    readonly type = UsageInsightActionTypes.UsageInsightTagUpdateRequested
    constructor(public queryParam: any) {
    }
}

export class TagUpdated implements Action {
    readonly type = UsageInsightActionTypes.UsageInsightTagUpdated;
    constructor(public payload: { data: Tags[] }) {
    }
}

export class StarsRequested implements Action {
    readonly type = UsageInsightActionTypes.StarsRequested;
    constructor() {
    }
}

export class StarsLoaded implements Action {
    readonly type = UsageInsightActionTypes.StarsLoaded;
    constructor(public payload: { data: any }) {
    }
}
export class StarsLoadError implements Action {
    readonly type = UsageInsightActionTypes.StarsLoadingError;
    constructor(public payload: { errorMessage: any }) {
    }
}

export type UsageInsightActions = OverTimeRequested | OverTimeLoaded | TagRequested | TagLoaded | StarsRequested | StarsLoaded;