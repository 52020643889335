import { Injectable } from '@angular/core';
import { environment } from 'projects/recognition/src/environments/environment';

declare let setCookieItem: any;
declare let getCookieItem: any;
declare let getAllCookieItem: any;
declare let deleteCookieItem: any;
declare let deleteAllCookieItem: any;

@Injectable({ providedIn: 'root' })
export class CookieStorageService {

  set(name: string, value: string, expiresOrOptions?: number | Date | any, path?: string, domain?: string, secure?: boolean, sameSite?: 'Lax' | 'None' | 'Strict'): void {
    setCookieItem(name, value, expiresOrOptions, path, domain, secure, sameSite);
  }

  get(name: string): string {
    return getCookieItem(name);
  }

  getAll(): { [key: string]: string } {
    return getAllCookieItem();
  }

  delete(name: string, path?: string, domain?: string, secure?: boolean, sameSite: 'Lax' | 'None' | 'Strict' = 'Lax'): void {
    deleteCookieItem(name, path, domain, secure, sameSite);
  }

  deleteAll(path?: string, domain?: string, secure?: boolean, sameSite: 'Lax' | 'None' | 'Strict' = 'Lax'): void {
    deleteAllCookieItem(path, domain, secure, sameSite);
  }
}
