export let defaultErrorMessage = 'An error occurred while processing your request, please contact support@enjoywishlist.com.';
export let unauthorizedAccessResourceErrorMessage = 'You don\'t have access of this resource.';
export let permissionErrorMessage = 'An error occurred while fetching user permissions, please try again later or contact support@enjoywishlist.com.';
export let ErrorWhileLoggingInMessage = 'An error occurred while login, please contact support@enjoywishlist.com';
export let TfaDeactivatedError = 'User has been deactivated. Please contact support@enjoywishlist.com';
export let CoworkerDetailReponseError = 'Unable to process request, due to invalid user detail(s).';

export enum NotImplementedFunctionality {
  Account = 'This functionality is not currently available, please contact support@enjoywishlist.com',
  Corp = 'This functionality is not currently available, please contact Product team.'
}
export let reactionUnavailableMessage = "Reaction is not available. Please contact support@enjoywishlist.com.";
export let noAdultCapacityTypeErrorMessage = "Unable to onboard product, due to invalid capacity type.";

export enum AuthorizationError {
  AccessTokenEmpty = 'Access token is not available.',
  RefreshTokenEmpty = 'Refresh token is not available.',
  Failed = 'Authorization has failed. Please reload to try to initiate authorization again.',
  AccessDenied = 'You are not authorised to access this resource. Please contact your programme administrator to request this feature.'
}

export enum ProductError {
  NotAvailable = 'The product is not available to book.',
  NotEnoughBalance = 'You’ll be able to book travel when you have $750 in your wallet.'
}

export enum ApiError {
  message = 'An error occurred while processing the request, please contact support@enjoywishlist.com.'
}
