import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FullScreenPostType } from '../../modals/keys/variable.keys';
import { FeedType, RecognitionReminder } from '../../modals/recognition';

@Injectable({ providedIn: 'root' })

export class RecognitionService {
  private isRecognitionFeedUpdated: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isRecognitionFeedFetchMore: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private hideRewardButtonObject: BehaviorSubject<string> = new BehaviorSubject(null);
  private recognitionNotification: BehaviorSubject<string> = new BehaviorSubject(null);
  private navigateRefreshRequired: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private markNotificationAsReadFromRecognition: BehaviorSubject<string> = new BehaviorSubject(null);
  private openSharedPost: Subject<object> = new Subject();
  private recognitionReminder: Subject<RecognitionReminder> = new Subject<RecognitionReminder>();
  private togglePostSub: BehaviorSubject<{ _feedpostType: FeedType, state: string }> = new BehaviorSubject<{ _feedpostType: FeedType, state: string }>(null);

  private showBottomSheets: BehaviorSubject<Record<string, boolean>> = new BehaviorSubject({ showFilterBottomSheet: false, showNotificationBottomSheet: false });

  public setRecognitionFeedUpdatedValue(value: boolean = true) {
    this.isRecognitionFeedUpdated.next(value);
  }

  public getRecognitionFeedUpdatedValue(): Observable<boolean> {
    return this.isRecognitionFeedUpdated.asObservable();
  }

  public setIsRecognitionFeedFetchMoreValueAdded(value: boolean = true) {
    this.isRecognitionFeedFetchMore.next(value);
  }

  public getIsRecognitionFeedFetchMoreValue(): Observable<boolean> {
    return this.isRecognitionFeedFetchMore.asObservable();
  }

  public setHideRewardButton(recognitionId) {
    this.hideRewardButtonObject.next(recognitionId);
  }

  public getHideRewardButton(): Observable<any> {
    return this.hideRewardButtonObject.asObservable();
  }

  public setRecognitionNotification(notificationId: string = null) {
    this.recognitionNotification.next(notificationId);
  }

  public getRecognitionNotification(): Observable<any> {
    return this.recognitionNotification.asObservable();
  }

  public getIsNewsFeedRefreshRequired(): Observable<boolean> {
    return this.navigateRefreshRequired.asObservable();
  }

  public setNewsFeedRefreshRequired(value) {
    return this.navigateRefreshRequired.next(value);
  }

  public setNotificationToMarkAsRead(notificationId: string) {
    this.markNotificationAsReadFromRecognition.next(notificationId)
  }

  public getNotificationToMarkAsRead(): Observable<string> {
    return this.markNotificationAsReadFromRecognition.asObservable();
  }

  public setOpenSharedPostByID(guid: string, index: number = 0, type = FullScreenPostType.Normal): void {
    let data = { guid: guid, index: index, type: type }
    this.openSharedPost.next(data);
  }

  public getOpenSharedPostByID(): Observable<object> {
    return this.openSharedPost.asObservable();
  }

  public getReminders() {
    return this.recognitionReminder.asObservable();
  }
  public setReminders(reminders) {
    this.recognitionReminder.next(reminders);
  }

  public setBottomSheetShow(showFilterBottomSheet: boolean, showNotificationBottomSheet: boolean) {
    this.showBottomSheets.next({ showFilterBottomSheet, showNotificationBottomSheet })
  }

  public getBottomSheetShow(): Observable<Record<string, boolean>> {
    return this.showBottomSheets.asObservable();
  }

  setTogglePostSub(value: { _feedpostType: FeedType, state: string }) {
    this.togglePostSub.next(value);
  }

  getTogglePostSub(): Observable<{ _feedpostType: FeedType, state: string }> {
    return this.togglePostSub.asObservable();
  }
}
