export enum CategoryName {
    Marketplace = "Marketplace",
    Experience = 'Experience',
    GiftCard = 'GiftCard',
    LiveEvent = 'LiveEvent',
    Travel = 'Travel',
    Custom = 'Custom',
    Swag = 'Physical',
    Viator = 'Viator',
    ViatorV2 = 'ViatorV2',
    AutoCode = 'AutoCode',
    FareHarbor = 'FareHarbor',
    Hotel = 'Hotel',
    DreamshipV2 = "DreamshipV2",
    Physical = "Physical",
    Amazon = "Amazon",
    PhysicalProduct = "PhysicalProduct",
    OnlineExperience = "OnlineExperience",
    FoodAndBev = "FoodAndBev"
}

export enum CategoryBehavior {
    General = "General",
    GiftCard = "GiftCard",
    External = "External",
    Company = "Company",
    Hotel = "Hotel",
    Travel = "Travel",
    Amazon = "Amazon",
    Custom = "Custom",
    Wallet = "Wallet",
}

export interface CommonCategoryKeys {
    id?: number;
    name?: string;
    displayName: string;
    identifier?: string;
    categoryId?: number;
    parentSubCategoryId?: number;
}
export interface MarketplaceSubCategory extends CommonCategoryKeys {
    subCategories: MarketplaceSubCategory[];
}

export interface MarketplaceCategory extends CommonCategoryKeys {
    imageFileName?: string;
    iconImages?: string[];
    menuName?: string;
    identifier: string;
    description?: string;
    behaviour: CategoryBehavior;
    headerName?: string;
    validForKeywordSearch?: boolean,
    subCategories?: MarketplaceSubCategory[]
    isMarketplaceRenderingEnabled?: boolean;
}

export interface CategoriesResponseDto {
    balanceUsd: number;
    isUserUnableToBookProduct: boolean;
    lockedProducts: LockedProduct[];
    productTypes: { images: string[], category: MarketplaceCategory }[];
    redirectTo: string;
}

interface LockedProduct {
    attrIdentifier: string;
    categoryIdentifier: string;
    tagNameIdentifier: string;
}


export interface ProductSubCategory {
    id: number;
    name: string;
    displayName: string;
    description: string;
    iconFilename: string;
    selected?: boolean;
}