import { RsaEncryptionClass } from "../encrypted";
import { RsaEncryptPolicy, RsaEncryptType } from "../policy";

/**
 * Class decorator that makes it easy to insert transparent transform
 * encryption onto a data transfer object.
 **/
export const RsaEncryptedClass = (): ClassDecorator => {
    return (constructor) => { constructor.prototype._rsaEncryptPolicy = new RsaEncryptPolicy({ encrypt: true, type: RsaEncryptType.Class, params: [] }); };
}

/**
 * Property decorator that makes it easy to modify values
 * encryption onto a data transfer key.
 **/
export const RsaEncryptedParam = (): PropertyDecorator => {
    return (target: Object, propertyKey: string) => {
        let params: string[] = target?.constructor?.prototype?._rsaEncryptPolicy?.params.length > 0 ? target?.constructor?.prototype?._rsaEncryptPolicy.params : [];
        params = [...params, ...[propertyKey]];
        target.constructor.prototype._rsaEncryptPolicy = new RsaEncryptPolicy({ encrypt: true, type: RsaEncryptType.Param, params: params});
    }
}
