import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { RsaEncryptionClass } from '../encrypted';
import { RSA_ENCRYPT_KEY } from '../model/rsa-encryption.model';
import { RsaEncryptPolicy, RsaEncryptType } from '../policy';

@Injectable({
  providedIn: 'root'
})

export class RsaEncryptionService {

  constructor(@Optional() @Inject(RSA_ENCRYPT_KEY) private rsaEncryptKey: string = null) { }

  getEncryptedValue(valueToEncrypt: string) {
    return this.rsaEncryptKey ? RsaEncryptionClass.encrypted(this.rsaEncryptKey, valueToEncrypt) : valueToEncrypt
  }

  private getEncryptedRequest(body: any): any {
    if (body?._rsaEncryptPolicy && body._rsaEncryptPolicy instanceof RsaEncryptPolicy) {
      let policy: RsaEncryptPolicy = body._rsaEncryptPolicy;
      if (policy.encrypt) {
        let newBody = {};
        if (policy.type == RsaEncryptType.Class) {
          Object.keys(body).forEach(key => { newBody[key] = this.getEncryptedValue(body[key]) });
        }
        else {
          newBody = body;
          policy.params.forEach((key) => {
            newBody[key] = this.getEncryptedValue(body[key]);
          })
        }
        return newBody;
      }
    }

    return body;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newBody = this.getEncryptedRequest(request.body);
    request = request = request.clone({ body: newBody });
    return next.handle(request);
  }
}
