import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoworkerCacheService } from './providers/coworker/coworker.cache.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    CoworkerCacheService,
  ]
})

export class CoworkerCacheServiceModule {
  static forRoot(): ModuleWithProviders<CoworkerCacheServiceModule> {
    return {
      ngModule: CoworkerCacheServiceModule,
      providers: [CoworkerCacheService]
    };
  }
}
