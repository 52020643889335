import { Action } from '@ngrx/store';
import { QueryParams } from '../../modals/QueryParams';
import ThankyouNotes from '../../modals/ThankyouNotes';
export enum ThankYouNotesActionType {
  thankYouNotesRequested = "[THANK_YOU_NOTES] ThankYouNotes Requested",
  thankYouNotesLoad = "[THANK_YOU_NOTES] ThankYouNotes loaded",
  thankYouNotesReset = "[THANK_YOU_NOTES] ThankYouNotes reset requested",
  thankYouNotesResetCompleted = "[THANK_YOU_NOTES] ThankYouNotes reset completed",
  thankYouNotesLoadingError = "[THANK_YOU_NOTES] ThankYouNotes Api Call error"
}

export class ThankYouNotesRequested implements Action {
  readonly type = ThankYouNotesActionType.thankYouNotesRequested
  constructor(public payload: { params: QueryParams }) { }
}

export class ThankYouNotesLoaded implements Action {
  readonly type = ThankYouNotesActionType.thankYouNotesLoad
  constructor(public payload: { notes: ThankyouNotes[], hasNextPage: boolean }) { }
}

export class ThankYouNotesResetRequested implements Action {
  readonly type = ThankYouNotesActionType.thankYouNotesRequested
  constructor() { }
}

export class ThankYouNotesResetCompleted implements Action {
  readonly type = ThankYouNotesActionType.thankYouNotesResetCompleted
  constructor(public payload: { notes: ThankyouNotes[], hasNextPage: boolean }) { }
}

export class ThankYouNotesLoadingError implements Action {
  readonly type = ThankYouNotesActionType.thankYouNotesLoadingError
  constructor(public payload: { errorMessage: string }) { }
}

export type ThankYouNoteTypes = ThankYouNotesRequested | ThankYouNotesLoaded;
