import { AdminPageRoute, BasicPageRoute, RewardPageRoute } from '../../common/page.route';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from '../localstorage/localStorage.service';
import { ApiService } from '../api.service/normal.api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserRole } from '../../modals/keys/variable.keys';
import { ProviderLocalStorageKey, UserCookieStorageKey, UserLocalStorageKey } from '../../modals/keys/local.storage';
import { defaultErrorMessage, unauthorizedAccessResourceErrorMessage } from '../../modals/keys/error.key';
import { UserGuardService } from './user.guard.service';
import { Keys } from '../../modals/keys/login';
import { CookieStorageService } from '../cookiestorage/cookieStorage.service';
import { SearchCoWorkerService } from '../coworker/search.coworker';
import { AccountConfigService } from '../permission/account.config.service';
import { SameSite } from 'projects/assets/cookie';
import { StoreService } from '../../store/store.service';
import { update_user_details } from '../../store/actions/user.action';
import { ToastService } from '../toast/toast.service';
import { AccountApiService } from '../api.service';

@Injectable({ providedIn: 'root' })

export class AuthService {
  private currentUser: any;
  private newUser: any;
  private contentHeaders = new HttpHeaders();

  constructor(private localStorageService: LocalStorageService, private storeService: StoreService, public router: Router, private apiService: ApiService, private accountConfigService: AccountConfigService, private toastService: ToastService, private activatedRoute: ActivatedRoute, private cookieStorageService: CookieStorageService, private userGuardService: UserGuardService, private searchCoWorkerService: SearchCoWorkerService, private _accountApiService: AccountApiService) {

    this.contentHeaders.append('Accept', 'application/json');
    this.contentHeaders.append('Content-Type', 'application/json');
    this.currentUser = {};
    this.newUser = { email: '', roles: '[]', permissions: '[]', isFinalLogin: '[]', accounts: '[]', userId: null, isAccountSettingsEnabled: false };
  }

  checkUserTfaStatus(params: object) {
    return this.apiService.checkUserTfaStatus(params);
  }

  sendLoginRegistrationNotification(payload) {
    return this.apiService.sendLoginRegistration(payload);
  }

  validateCommunicationEmail(payload) {
    return this.apiService.validateCommunicationEmail(payload);
  }
  
  setCommunicationEmail(payload) {
    return this.apiService.setCommunicationEmail(payload);
  }

  shootOptForUserLogin(params) {
    return this.apiService.shootOptForUserLogin(params);
  }

  login(user, request) {
    return this.apiService.login(user, request);
  }

  signUpUserOnBoarding(payload: object) {
    return this.apiService.signUpUserOnBoarding(payload);
  };
  
  registerUserBoarding(payload: object) {
    return this.apiService.registerOnboardingUser(payload);
  };

  getNewAccessToken() {
    let isMsTeams = this.getIsAppHostedOnMsTeam();
    return this.apiService.getNewAccessToken({ isMsTeams }).pipe(map((response: any) => {
      this.isSSOloginRequestInProcessComplete();
      this.setAccessTokenRefreshToken(response);
      return response;
    }),
      catchError((error: any) => {
        this.logout(true);
        this.isSSOloginRequestInProcessComplete();
        return throwError(error);
      })
    );
  }

  impersonate(user) {
    return this._accountApiService.impersonate(user);
  }

  isSSOloginRequestInProcess(): boolean {
    let ssoType = this.localStorageService.get(UserLocalStorageKey.SsoLoginType);
    if (ssoType) { return true; }
    return false;
  }


  isSSOloginRequestInProcessComplete() {
    this.localStorageService.remove(UserLocalStorageKey.SsoLoginType);
  }

  ssoRequestProceedForValidation(data, ssoType, redirect = false) {
    if (ssoType) {
      this.localStorageService.set(UserLocalStorageKey.SsoLoginType, ssoType);
      this.localStorageService.set('login_through', ssoType);
    }
    else {
      this.localStorageService.remove(UserLocalStorageKey.SsoLoginType);
    }

    if (redirect)
      window.location = data.url ? data.url : data;
  }

  ssoLoginValidate(params) {
    return this.apiService.ssoLoginValidate(params);
  }

  externalLogin(idToken, otp, provider, tenantId: string = '') {
    return this.apiService.externalLogin(idToken, otp, provider, tenantId);
  }

  oktaLoginValidate(requestParams) {
    return this._accountApiService.oktaLoginValidate(requestParams).pipe(
      map((response: any) => {
        this.isSSOloginRequestInProcessComplete();
        return response;
      }),
      catchError((error: any) => {
        this.isSSOloginRequestInProcessComplete();
        return throwError(error);
      })
    );
  }

  public emailResetPasswordLink(userName: string, continueUrl: string = null, accountIdentifierKey?: string) {
    return this.apiService.emailResetPasswordLink(userName, continueUrl, accountIdentifierKey);
  }

  public emailResetPasswordLinkForLoggedInUser() {
    return this._accountApiService.emailResetPasswordLinkForLoggedInUser();
  }

  public userResetPassword(request) {
    return this.apiService.userResetPassword(request);
  }

  public registerLocation(request) {
    return this.apiService.registerLocation(request);
  }

  public postLoginTask(accountUser: any, goToContinueUrl: boolean = true, notificationId: string = '', proceedForLogin: boolean = true, teamsEntityTabKey: string = '') {
    try {
      if (accountUser && JSON.parse(accountUser.isFinalLogin)) {
        let accountUserRoles = JSON.parse(accountUser.roles);

        let accountSwitch = this.localStorageService.get(UserLocalStorageKey.ChangeUser);

        if (this.isGuestUserLoggedIn(accountUserRoles)) {
          throw new Error(UserLocalStorageKey.User);
        }

        accountUser.accounts = this.getLoggedUserAccountList(accountUser.accounts);
        this.saveUserInformation(accountUser);
        this.setAuthenticationToken(accountUser);

        if (proceedForLogin) {
          this.goToLoggedInUrl(accountUser, accountSwitch, accountUserRoles, goToContinueUrl, notificationId, teamsEntityTabKey);
        }
      }
      else {
        throw new Error(UserLocalStorageKey.User);
      }
    }
    catch (e) {
      this.showToasterMessage(defaultErrorMessage);
      this.logout();
    }
  }

  private isGuestUserLoggedIn(accountUserRoles): boolean {
    return accountUserRoles?.length == 1 && accountUserRoles?.indexOf(UserRole.Guests) > 0;
  }

  private isLogInAccessAllowed(accountUserRoles): boolean {
    return (accountUserRoles?.length > 0) && (accountUserRoles.includes(UserRole.ProgramManager) || accountUserRoles.includes(UserRole.Accountant) || accountUserRoles.includes(UserRole.RewardSender) || accountUserRoles.includes(UserRole.EmployeeLogin) || accountUserRoles.includes(UserRole.Administrator) || accountUserRoles.includes(UserRole.FinanceAdministrator) || accountUserRoles.includes(UserRole.WalletUser))
  }

  private getLoggedUserAccountList(accountList: string): string {
    try {
      let filterList = accountList ? JSON.parse(accountList) : [];
      filterList = filterList.filter((account) => { return account.Version == 2 });

      return JSON.stringify(filterList);
    }
    catch {
      return '[]';
    }
  }

  private saveUserInformation(loggedUser: any): void {
    let { roles, email } = loggedUser;
    this.localStorageService.saveUserDetails({ roles, email, name });
  }

  private goToLoggedInUrl(accountUser: any, accountSwitch: boolean | string, accountUserRoles: string[], goToContinueUrl: boolean, notificationId: string, teamsEntityTabKey: string = '') {
    accountSwitch = accountSwitch ? JSON.parse(accountSwitch as string) : false;
    let continueUrl = this.getContinueUrl();
    let requestId = this.getRequestId();

    if (this.isLogInAccessAllowed(accountUserRoles)) {
      this.goToImpersonateLoggedInUrl(accountUser, goToContinueUrl, continueUrl, requestId, notificationId, teamsEntityTabKey);
    }
    else if (continueUrl) {
      this.goToSingleLoggedInUrl(accountUser.access_token, goToContinueUrl, continueUrl, requestId);
      return;
    }
    else {
      if (accountSwitch) {
        this.goToAccessDeniedPage();
      }
      else {
        this.logout(true);
        this.localStorageService.remove(UserLocalStorageKey.ChangeUser);
        this.toastService.openToast(unauthorizedAccessResourceErrorMessage);
      }
    }
  }

  private goToImpersonateLoggedInUrl(accountUser, goToContinueUrl: boolean, continueUrl: string, requestId: string, notificationId: string = null, teamsEntityTabKey: string = '') {
    this.clearUserPermissions();

    if (accountUser && accountUser.access_token && accountUser.impersonateUserId && accountUser.impersonateUserId != "0" && !continueUrl) {
      let url = this.router.url;

      this.localStorageService.set(UserLocalStorageKey.AccountSwitched, true);

      let doesUserHaveAccessAccountPortal = this.userGuardService.doesUserHaveAccessAccountPortal();
      let doesUserHaveAccessAdminPortal = this.userGuardService.doesUserHaveAccessAdminPortal();
      let doesWalletUserHaveAccess = this.userGuardService.doesWalletUserHaveAccess();

      let origin = window.origin;
      let newUrl = window.location.href;

      if (!doesUserHaveAccessAccountPortal && doesUserHaveAccessAdminPortal && (url.indexOf('account') > -1)) {
        newUrl = `${origin}/admin/home`;
      }
      else if (!doesUserHaveAccessAdminPortal && doesUserHaveAccessAccountPortal && (url.indexOf('admin') > -1) || url.indexOf('account/feed') >= 0) {
        newUrl = `${origin}/account/feed`;
      }
      else if (doesWalletUserHaveAccess) {
        newUrl = `${origin}/admin/reward/my-rewards`;
      }

      window.open(newUrl, "_self"); // using directly to remove circular dependency.
    }
    else {
      if (continueUrl) {
        this.goToSingleLoggedInUrl(accountUser.access_token, goToContinueUrl, continueUrl, requestId);
        return;
      } else {
        this.goToNormalLoggedInUrl(notificationId, teamsEntityTabKey);
      }
    }
  }

  public goToSingleLoggedInUrl(accessToken, goToContinueUrl = true, continueUrl: string = null, requestId: string = null) {
    continueUrl = !continueUrl ? this.getContinueUrl() : continueUrl;
    if (goToContinueUrl && continueUrl) {
      this.localStorageService.remove(UserLocalStorageKey.ContinueUrl);

      let url: string = null;

      if (this.localStorageService.get(UserLocalStorageKey.AccountSwitched)) {
        url = decodeURI(continueUrl);
      }
      else {
        url = decodeURI(continueUrl);
      }

      this.localStorageService.remove(UserLocalStorageKey.AccountSwitched);

      let params: any = {};
      params.requestId = requestId;
      params.redirectUri = continueUrl;

      this.apiService.generateAccessCode(params)
        .subscribe((data) => {
          url = `${url}?requestId=${requestId}&accessCode=${data.data}`;
          this.removeRequestIdAndRedirectUrl();
          window.open(url, "_self"); // using directly to remove circular dependency.
          return;
        }, (error) => {
          this.toastService.openToast('An error occurred while login, please contact support@enjoywishlist.com');
        });
    }
    else {
      this.goToNormalLoggedInUrl();
    }
  }

  private goToNormalLoggedInUrl(notificationId: string = null, teamsEntityTabKey: string = '') {
    let redirectUrl = this.localStorageService.get(UserLocalStorageKey.RedirectUrl);
    this.localStorageService.remove(UserLocalStorageKey.RedirectUrl);
    this.localStorageService.remove(UserLocalStorageKey.RemoveUrl);
    if (redirectUrl) {
      try {
        window.location = redirectUrl;
      }
      catch {
        this.goToLoggedInPage(notificationId, teamsEntityTabKey)
      }
    }
    else {
      this.goToLoggedInPage(notificationId, teamsEntityTabKey)
    }
  }

  private goToLoggedInPage(notificationId: string, teamsEntityTabKey: string = '') {
    let doesUserHaveAccessAccountPortal = this.userGuardService.doesUserHaveAccessAccountPortal();
    let doesUserHaveAccessAdminPortal = this.userGuardService.doesUserHaveAccessAdminPortal();
    let doesWalletUserHaveAccess = this.userGuardService.doesWalletUserHaveAccess();

    if (doesUserHaveAccessAccountPortal) {
      if (teamsEntityTabKey == Keys.LoginKeys.SsoKeys.MSTeamKeys.RewardEntityTab) {
        this.router.navigate([RewardPageRoute.UserReceivedReward], { queryParams: { identifier: window.atob(notificationId) } });
      }
      else {
        var url = notificationId ? `account/feed/${notificationId}` : 'account/feed';
        this.router.navigate([url]);
      }
    }
    else if (doesUserHaveAccessAdminPortal) {
      this.router.navigate([AdminPageRoute.Home]);
    }
    else if (doesWalletUserHaveAccess) {
      this.router.navigate([RewardPageRoute.UserReward]);
    }
    else {
      this.router.navigate([BasicPageRoute.AccessDenied]);
    }
  }

  getQueryParams() {
    const urlSearchParams = new URLSearchParams(window.location.search) as any;
    const params = Object.fromEntries(urlSearchParams.entries());
    return params;
  }

  public getContinueUrl(url: string = null): string {
    let continueUrl = url ? url : this.getQueryParams()?.redirectUri;

    if (continueUrl) {
      this.localStorageService.set(UserLocalStorageKey.ContinueUrl, continueUrl);
    }
    else {
      continueUrl = this.localStorageService.get(UserLocalStorageKey.ContinueUrl);
    }
    return continueUrl;
  }

  public getRequestId(): string {
    let requestId = this.getQueryParams()?.requestId;

    if (requestId) {
      this.localStorageService.set(UserLocalStorageKey.RequestId, requestId);
    }
    else {
      requestId = this.localStorageService.get(UserLocalStorageKey.RequestId);
    }
    return requestId;
  }

  public getAccessTokenFromStorage() {
    return this.localStorageService.get(UserLocalStorageKey.AccessToken);
  }

  public setIsAppHostedOnMsTeam(value: boolean): void {
    this.localStorageService.set(ProviderLocalStorageKey.MicrosoftTeamsProvider, value);
  }

  public getIsAppHostedOnMsTeam(): boolean {
    let isMsTeams = this.localStorageService.get(ProviderLocalStorageKey.MicrosoftTeamsProvider) ?? false;
    return isMsTeams;
  }

  private setAuthenticationToken(accountUser) {
    this.setAccessTokenRefreshToken(accountUser);
    if (!this.localStorageService.get(UserLocalStorageKey.AccountSwitched)) {
      this.setLoginCookie();
    }
    this.changeUser(accountUser);
  }

  setAccessTokenRefreshToken(accountUser) {
    this.setExpirationTime(accountUser.expires_in);
    this.localStorageService.remove(UserLocalStorageKey.TokenExpired);
  }


  goToLoginPage(queryParams: any = null, checkMsTeamLogin: boolean = true) {
    let isProviderMsTeam: boolean = false;
    if (checkMsTeamLogin && !queryParams) {
      isProviderMsTeam = this.getIsAppHostedOnMsTeam();
    }
    this.clearLoggedInDetails();
    if (isProviderMsTeam) {
      this.router.navigate(["teams/manual/login"]);
    }
    else {
      this.router.navigate(["account/login"], { queryParams: queryParams ? queryParams : "" });
    }
  }

  logout(removeCookie: boolean = true) {
    var continueUrl = this.activatedRoute.snapshot.queryParamMap.get('continue');

    if (removeCookie) {
      this.removeLoginCookie();
    }

    this.goToLoginPage(continueUrl ? { continue: encodeURI(continueUrl) } : null);
    this.changeUser(this.newUser);
  }

  clearLoggedInDetails() {
    this.localStorageService.remove(UserLocalStorageKey.User);
    this.localStorageService.remove(UserLocalStorageKey.AccessToken);
    this.localStorageService.remove(UserLocalStorageKey.AccountSwitched);
    this.localStorageService.remove(UserLocalStorageKey.UserConfig);
    this.localStorageService.remove(UserLocalStorageKey.ChangeUser);
    this.localStorageService.remove(UserLocalStorageKey.GuestLogin);
    this.localStorageService.remove(UserLocalStorageKey.TokenExpired);
    this.localStorageService.remove(UserLocalStorageKey.AccessDeniedPage);
    this.localStorageService.remove(Keys.LoginKeys.SsoKeys.MSSharepointKeys.CookieKey);
    this.localStorageService.remove(UserLocalStorageKey.ExpiresIn);
    this.localStorageService.remove(UserLocalStorageKey.PageLoadCount);
    this.clearUserPermissions();
  }

  clearUserPermissions() {
    this.clearCachedCoworkerData();
    this.accountConfigService.clearUserPermissionDetails();
  }

  clearCachedCoworkerData() {
    this.searchCoWorkerService.clearCachedCoworkerData();
  }

  changeUser(user) {
    this.currentUser.email = user.email;
    this.currentUser.name = user.name;
    this.currentUser.impersonateUserId = user.impersonateUserId;
    this.currentUser.roles = JSON.parse(user.roles);
    this.currentUser.isFinalLogin = user.isFinalLogin ? JSON.parse(user.isFinalLogin) : false;
    this.currentUser.accounts = user.accounts ? user.accounts : [];
    this.currentUser.userId = user.userId ? JSON.parse(user.userId) : null;
    this.currentUser.isAccountSettingsEnabled = user.isAccountSettingsEnabled ? JSON.parse(user.isAccountSettingsEnabled) : false;
  }

  setLoginCookie() {
    let data = this.generateRandomNumber().toString();
    let expiryDate = this.setExpiryDateOfCookies();
    this.localStorageService.set(UserLocalStorageKey.UuishlistLoginId, data);

    if (environment.isDebug && !environment.development) {
      this.cookieStorageService.set(UserCookieStorageKey.LoginIdDebug, data, expiryDate, '/', UserCookieStorageKey.EnvLocalhost, true, SameSite.None);
    } else {
      this.cookieStorageService.set(UserCookieStorageKey.LoginIdOriginal + environment.env, data, expiryDate, '/', UserCookieStorageKey.EnvOriginal, true, SameSite.None);
    }
  }

  public getLoginCookies(): string {
    if (environment.isDebug && !environment.development) {
      return this.cookieStorageService.get(UserCookieStorageKey.LoginIdDebug);
    }
    else {
      return this.cookieStorageService.get(UserCookieStorageKey.LoginIdOriginal + environment.env);
    }
  }

  removeLoginCookie() {
    if (environment.isDebug && !environment.development) {
      this.cookieStorageService.delete(UserCookieStorageKey.LoginIdDebug, '/', UserCookieStorageKey.EnvLocalhost);
    } else {
      this.cookieStorageService.delete(UserCookieStorageKey.LoginIdOriginal + environment.env, '/', UserCookieStorageKey.EnvOriginal, true, SameSite.None);
    }
  }

  generateRandomNumber() {
    return 100000 + Math.floor(Math.random() * 900000);
  }

  goToAccessDeniedPage() {
    this.localStorageService.set(UserLocalStorageKey.AccessDeniedPage, true);
    this.router.navigate(['access-denied']);
    this.toastService.openToast('Oops! It seems like you cannot access this resource at the moment. Please contact support or try again later.')
  }

  setSsoRefreshToken() {
    let token = this.cookieStorageService.get(UserCookieStorageKey.SSOKey);
    if (token) {
      this.removeSsoRefreshToken();
    }
  }

  removeSsoRefreshToken() {
    if (environment.isDebug && !environment.development) {
      this.cookieStorageService.delete(UserCookieStorageKey.SSOKey, '/', UserCookieStorageKey.EnvLocalhost);
    } else {
      this.cookieStorageService.delete(UserCookieStorageKey.SSOKey, '/', UserCookieStorageKey.EnvOriginal);
    }
  }

  setExpirationTime(seconds) {
    let date = new Date();
    date.setSeconds(date.getSeconds() + JSON.parse(seconds) - 60);
    this.localStorageService.set(UserLocalStorageKey.ExpiresIn, date);
  }

  isAccessTokenValid() {
    let expireDate = this.localStorageService.get(UserLocalStorageKey.ExpiresIn);
    if (!expireDate) { this.logout(true); return false; }
    if (new Date(expireDate) < new Date()) {
      this.localStorageService.set(UserLocalStorageKey.TokenExpired, true);
      return false;
    }
    else {
      this.localStorageService.remove(UserLocalStorageKey.TokenExpired); return true;
    }
  }

  getAccessTokenUsingRefreshToken() {
    return this.getNewAccessToken().pipe(
      map((event: any) => {
        //save new Token
        this.setAccessTokenRefreshToken(event);
        return event;
      }), catchError(err => {
        this.showToasterMessage(err.error_description);
        throw err;
      }));
  }

  showToasterMessage(message) {
    this.localStorageService.remove(UserLocalStorageKey.TokenExpired);
    setTimeout(() => { this.toastService.openToast(message); }, 500);
  }

  updateUserProfileImage(imageUploaded: boolean = false, imageUrl: string = "") {
    let userProfileUrl = "";
    if (imageUploaded && imageUrl) {
      userProfileUrl = imageUrl;
    } else {
      userProfileUrl = "";
    }
    this.storeService.getStore().dispatch(update_user_details({ userProfileUrl }));
  }

  setExpiryDateOfCookies() {
    let days = 365; //Set expiration date to 1 years.
    let expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + days);
    return expiryDate;
  }

  removeRequestIdAndRedirectUrl() {
    this.localStorageService.remove(UserLocalStorageKey.ContinueUrl); // removed continue user before changing the redirect url to avoid circular redirect
    this.localStorageService.remove(UserLocalStorageKey.RequestId);   // removed request id before changing the redirect url to avoid circular redirect
  }

  invalidateDevices(payload) {
    return this.apiService.invalidateDevices(payload);
  }
}
