import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EncryptInterceptor } from './interceptor/rsa-encryption.interceptor';
import { RSA_ENCRYPT_KEY } from './model/rsa-encryption.model';
import { RsaEncryptionService } from './service/rsa-encryption.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    RsaEncryptionService
  ]
})

export class RsaEncryptionModule {
  static forRoot(rsaEncryptKey: string): ModuleWithProviders<RsaEncryptionModule> {
    return {
      ngModule: RsaEncryptionModule,
      providers: [
        { provide: RSA_ENCRYPT_KEY, useValue: rsaEncryptKey },
        { provide: HTTP_INTERCEPTORS, useClass: EncryptInterceptor, multi: true }
      ],
    };
  }
}
