import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { LocalStorageService } from '../localstorage/localStorage.service';
import { map, catchError } from 'rxjs/operators';
import { CatalogApiService } from '../api.service/catalog.api.service';

@Injectable({ providedIn: 'root' })


export class UserLocationService {
  public landingPage: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private localStorageService: LocalStorageService, private _catalogApiService: CatalogApiService) {
  }

  getUserLocationFromIpAddress() {
    return this._catalogApiService.getUserLocationFromIp()
      .pipe(
        map((response: any) => {
          let userlocationDetails = {
            latitude: response.latitude,
            longitude: response.longitude,
            placeName: response.placeName,
            countryCode: response.countryCode,
            date: new Date()
          }
          this.localStorageService.set('user_location_details', userlocationDetails);
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
}
