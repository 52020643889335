import { AppState } from './../../store/reducers/index';
import { Store, select } from '@ngrx/store';
import { filter, map, mergeMap, withLatestFrom, catchError } from 'rxjs/operators';
import { UserStatsRequested, UserStatsActionType, UserStatsLoaded, UserStatsLoadingError } from './user.stats.action';
import { Injectable } from "@angular/core"
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { ApiService } from '../../providers/api.service';
import { isStatsLoaded } from './user.stats.selector';
import { of } from 'rxjs';
import { RecognitionApiService } from '../../providers/api.service/recognition.api.service';

@Injectable()
export class StatsEffects {

    constructor(private actions$: Actions, private _recognitionApiService: RecognitionApiService, private store: Store<AppState>) { }

    
    loadStats$ = createEffect(() => this.actions$
        .pipe(
            ofType<UserStatsRequested>(UserStatsActionType.UserStatsRequested),
            withLatestFrom(this.store.pipe(select(isStatsLoaded))),
            filter(([action, statsLoaded]) => (!statsLoaded || action.isRefreshRequired)),
            mergeMap(action => this._recognitionApiService.getUserStats().pipe(
                catchError(error => {
                    this.store.dispatch(new UserStatsLoadingError({ errorMessage: error.message }));
                    return of(null);
                }),
            )),
            map(stats => {
                let loaded = stats ? true : false;
                return new UserStatsLoaded({ stats: stats, statsLoaded: loaded })
            })
        ))
}