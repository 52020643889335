import { CompanyValue, CompanyValueActionTypes } from './company.value.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface CompanyValueState extends EntityState<CompanyValue> {
}

export const companyValueAdapter: EntityAdapter<CompanyValue> = createEntityAdapter<CompanyValue>({
  selectId: (companyValue: CompanyValue) => companyValue.identifier
});

const intialCompanyValueState = companyValueAdapter.getInitialState();

export function companyValueReducer(state = intialCompanyValueState, action): CompanyValueState {

  switch (action.type) {

    case CompanyValueActionTypes.CompanyValueLoaded:

      return companyValueAdapter.addMany(action.payload.tagList, { ...state });

    default:
      return { ...state };
  }
}

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal
} = companyValueAdapter.getSelectors();


