import { Action } from '@ngrx/store';

export class BusinessGoal {
  id?: string;
  identifier: string;
  tag: string;
  description: string;
  tagName: string;
  type: string;
  name?: string;
}

export enum BusinessGoalActionTypes {
  BusinessGoalRequested = "[BUSINESS_GOAL] business goal requested",
  BusinessGoalLoaded = "[BUSINESS_GOAL] business goal loaded"
}

export class BusinessGoalsRequested implements Action {
  readonly type = BusinessGoalActionTypes.BusinessGoalRequested
  constructor() { }
}

export class BusinessGoalsLoaded implements Action {
  readonly type = BusinessGoalActionTypes.BusinessGoalLoaded
  constructor(public payload: { goalList: BusinessGoal[] }) { }
}

export type BusinessGoalActions = BusinessGoalsRequested | BusinessGoalsLoaded;

