export const EmailRegex: RegExp = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]+$/

export const IsoDateRegex: RegExp = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/

export const ShopifyHostNameRegex: RegExp = /[a-zA-Z0-9][a-zA-Z0-9\-]*\.myshopify\.com[\/]?/

export const SSOUrlRegex = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'

export const AsciiRegex: RegExp = /^[\x00-\x7F]*$/
export const phoneNumberRegex: RegExp = /^\+?(\d{1,3})[-.\s]??(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})(?: *x(\d+))?$/
export const PasswordRegex: RegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-~_+=|{}[\]()<>,'.":]).{8,}$/
export const containsCapitalAndLowerChars: RegExp = /[A-Z]/
export const containsSpecialChar: RegExp = /[#?!@$%^&*\-~_+=|{}[\]()<>,'.":]/
export const containsNumber: RegExp = /[0-9]/