import { AppState } from './../../store/reducers/index';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core"
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { AccountApiService, ApiService } from '../../providers/api.service';
import { UserRewardActionTypes, UserRewardLoadingError, UserRewardRequested, UserRewardLoaded } from './user.rewards.actions';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { RecognitionApiService } from '../../providers/api.service/recognition.api.service';

@Injectable()
export class UserRewardsEffects {

  constructor(private actions$: Actions, private _accountApiService: AccountApiService, private store: Store<AppState>) { }

  
  loadRewards$ = createEffect(() => this.actions$
    .pipe(
      ofType<UserRewardRequested>(UserRewardActionTypes.RewardRequested),
      mergeMap((action) => {
        return this._accountApiService.getUserRewards(action.payload.queryParams)
          .pipe(
            catchError(error => {
              this.store.dispatch(new UserRewardLoadingError({ errorMessage: error?.message }))
              return of({
                data: []
              })
            })
          )
      }),
      map(rewards => {
        return new UserRewardLoaded({ userReward: rewards?.data?.data ? rewards.data.data : [], hasNextPage: rewards?.data?.hasNextPage, isLoaded: true })
      }),
    ))
}
