import { AppState } from './../../store/reducers/index';
import { Store } from '@ngrx/store';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core"
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { ThankYouNotesRequested, ThankYouNotesActionType, ThankYouNotesLoaded, ThankYouNotesLoadingError } from './thankyou.notes.action';
import { of } from 'rxjs';
import { RecognitionApiService } from '../../providers/api.service/recognition.api.service';
import { AccountApiService } from '../../providers/api.service';

@Injectable()
export class ThankYouNotesEffects {

  constructor(private actions$: Actions, private _accountApiService: AccountApiService, private store: Store<AppState>) { }

  
  loadThankYoutNotes$ = createEffect(() => this.actions$
    .pipe(
      ofType<ThankYouNotesRequested>(ThankYouNotesActionType.thankYouNotesRequested),
      mergeMap(action => this._accountApiService.getThankyouNotes(action.payload.params)
        .pipe(
          catchError(error => {
            this.store.dispatch(new ThankYouNotesLoadingError({ errorMessage: error.message }))
            return of({
              data : []
            })
          })
        )),
      map(result => {
       return new ThankYouNotesLoaded({ notes: result.data, hasNextPage: result?.hasNextPage })})
    ))
}
