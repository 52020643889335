import { createReducer, on } from "@ngrx/store";
import { SendRewardType, update_send_reward_modal_data } from "../actions/send-reward.action";

export const initialSendRewardState: SendRewardType = {
    openSendNewReward: false,
    identifiers: []
}

const _sendRewardReducer = createReducer(
    initialSendRewardState,
    on(update_send_reward_modal_data, (state, props) => { return { ...state, ...props } }),
);

export function SendRewardReducer(state, action) {
    return _sendRewardReducer(state, action);
}