import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";

@Injectable({ providedIn: "root" })
export class AdminApiService {
  constructor(private configService: ConfigService) { }

  getViatorDestinations = (destinationName: string) => this.configService.get(`api/v2/admin/viator/v2/destination/${destinationName}/data/get`);
  getViatorCategories = (params) => this.configService.get('api/v2/admin/viator/v2/tags/data/get', params);
  getViatorProduct = (params) => this.configService.get('api/v2/admin/viator/v2/products/data/get', params);
  getViatorProductOptions =(productCode: string, destinationId: string, priceStartsFrom: number, onboardMode) => this.configService.get(`api/v2/admin/viator/v2/product/${productCode}/product-options/get?onboardMode=` + onboardMode, { destinationId, priceStartsFrom });
  getExternalProductDetailsToOnboard = (params) => this.configService.get('api/v2/admin/wishlist/external/product/details/get', params);
  getUserPrototypeList = (productId) => this.configService.get("api/v2/admin/%r1/fareharbor/user/prototypeList".replace("%r1", productId));
  getUserWishlistExternalList = (weProvider, productGuid) => this.configService.get(`api/v2/admin/${productGuid}/wishlistExternal/attribute/list`, { weProvider });
  getAccountListByKeyword =(companyName: string) =>this.configService.get('api/v2/admin/user/manager/accounts/{companyName}/get'.replace('{companyName}', companyName));
  addEditUserByCorp =(params) => this.configService.post('api/v2/admin/user/manager/add/edit', params);
  getUserAllInformationByIdentifier = (userIdentifier) => this.configService.get('api/v2/admin/user/manager/user/{userIdentifier}/details/get'.replace('{userIdentifier}', userIdentifier));
  impersonateUserByCorp = (params) => this.configService.post('api/v2/admin/user/manager/impersonate/validate', params);
  sendRegistrationEmailByCorp = (params) => this.configService.post(`api/v2/admin/user/manager/registration/email/send`, params);
  resetUserCredentialsByCorp = (params) => this.configService.post(`api/v2/admin/user/reset/credentials`, params);
  validateOtpByCorp = (param) => this.configService.post("api/admin/admin/otp/verify", param);
  sendConfirmationEmail = (email: string, attributeGuid) => this.configService.post(`api/v2/admin/confirmation/email/send/preview?email=${email}&attributeGuid=${attributeGuid}`, {});
  removeAutoCodeDenominations = (identifier) => this.configService.delete(`api/v2/admin/product/autocode/denominations/${identifier}/delete`);
  sendThankYouMessage = (params) => this.configService.post('api/v2/b2b/thank-you-note', params);
  triggerCustomSurveyByCorp = (params) => this.configService.get(`api/v2/admin/email/trigger/onetime`, params);
  setAlternateEmail = (payload) => this.configService.post(`api/v2/admin/alternate/email/set`, payload);
  getBehaviourCategories = (behaviour = "") => this.configService.get(`api/v2/admin/category`, { behaviour });
  getBestBuyDetails = (sku: string) => this.configService.get(`api/v2/admin/bestbuy/products/sku/${sku}`);
  getBestBuyProducts = (pageSize: number, pageIndex: number, serializedFilters: string) => this.configService.get("api/v2/admin/bestbuy/onboarded/products", { pageSize, pageIndex, serializedFilters });
  updateStatus = (identifier: string, status: boolean) => this.configService.put('api/v2/admin/bestbuy/product/activate/deactivate', { identifiers: [identifier], Activate: status });
}
