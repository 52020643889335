import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../authentication/auth.service';
import { LocalStorageService } from '../localstorage/localStorage.service';
import { UserLocalStorageKey } from '../../modals/keys/local.storage';


@Injectable({ providedIn: 'root' })
export class ErrorHandleService {
  constructor(private authenticationService: AuthService, private localStorageService: LocalStorageService) { }
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(catchError((errorObject) => {
      let error = { error: '', status: 0, message: '', error_description: '' };
      if (!((errorObject.status == undefined) || (errorObject.status == 0 && errorObject.statusText == "Unknown Error") || (errorObject.status == 200 && errorObject.statusText == "OK"))) {
        error = this.getErrorerror(errorObject);
        if (error.status === 400) {
          if (error.error === 'authorization_denied' && error.message === 'Cannot impersonate this user.') {
            error.message = 'Cannot impersonate this user.';
          }
          else {
            if (error.message === 'User configuration is not supported or invalid for the given request.' || error.message === 'User is null or invalid. Please contact support@enjoywishlist.com.' ||
              error.message === 'Current login method is not supported.') {
              error.message = 'You are not allowed login through SSO, please contact support@enjoywishlist.com.';
              this.authenticationService.logout();
            }
          }
        }
        else if (error.status === 403) {
          if (error && error.status == 403 && error.error == 'You are not authorized to access this resource') {
            error.message = 'You are not authorized to access this resource.';
            if (!this.localStorageService.get(UserLocalStorageKey.AccessDeniedPage)) { this.authenticationService.goToAccessDeniedPage(); }
          }
          else {
            if (error.error == 'authorization_denied') {
              error.message = 'You are not authorized to login.';
            }
            else {
              error.message = 'Session has expired, please login again.';
            }
            this.authenticationService.logout();
          }
        }
      }
      else {
        error.message = "An error occurred while processing the request, please contact support@enjoywishlist.com.";
      }

      error.error_description = error.message;
      return throwError(error);
    }));
  }

  private getErrorerror(errorObject) {
    let error = { error: '', status: 0, message: '', error_description: '' };
    {
      if (errorObject.status) {
        error.status = errorObject.status;
      }
      else if (errorObject.error.status) {
        error.status = errorObject.error.status;
      }
      else if (errorObject.error.error.status) {
        error.status = errorObject.error.error.status;
      }
    }
    {
      if (errorObject && (typeof errorObject == 'string')) {
        error.error = this.modifyErrorKey(errorObject);
        error.message = errorObject;
      }
      else if ((typeof errorObject?.error == 'string') || (errorObject.error == undefined && (errorObject.message || errorObject.error_description))) {
        error.error = this.modifyErrorKey(errorObject?.error);
        error.message = errorObject?.message ? errorObject?.message : errorObject?.error_description;
      }
      else if ((typeof errorObject?.error?.error == 'string') || (!(errorObject?.error?.error) && (errorObject?.error?.message || errorObject?.error?.error_description))) {
        error.error = this.modifyErrorKey(errorObject?.error?.error);
        error.message = errorObject?.error?.message ? errorObject?.error?.message : errorObject?.error?.error_description;
      }
      else if ((typeof errorObject?.error?.error?.error == 'string') || (!(errorObject?.error?.error?.error) && (errorObject?.error?.error?.message || errorObject?.error?.error?.error_description))) {
        error.error = this.modifyErrorKey(errorObject?.error?.error?.error);
        error.message = errorObject?.error.error.message ? errorObject?.error?.error?.message : errorObject?.error?.error?.error_description;
      }
      error.error_description = error.message;
    }
    return error;
  }

  public modifyErrorKey(error: string = '') {
    return ErrorMessages[error] ?? error
  }
}


export const ErrorMessages = {
  InvalidClient: 'invalid_client',
  InvalidGrant: 'invalid_grant',
  IpBlackListed: 'Ip BlackListed',
  IpWhiteListRequired: 'Ip WhiteList required',
  AuthorizationDenied: 'authorization_denied',
  ResetGrant: 'Reset Grant',
  UserDeactivated: 'User Deactivated',
  InvalidOtp: 'tfa_invalid_otp',
  TfaNotActivated: 'Tfa_Not_Activated',
  TfaVerificationRequired: 'tfa_totp_verification',
  UserDeleted: 'tfa_user_deleted',
  InvalidClientCaps: 'Invalid Client',
  TfaSmsVerificationRequired: 'tfa_sms_totp_verification',
  SmsOtpVerify: 'tfa_sms_otp_verification',
  SmsInactive: 'tfa_not_activated',
  SetLoginTfa: 'set_login_tfa',
  QrCode: 'qr_code',
  IpValidation: 'IpValidation',
  TfaNotRequired: 'tfa_Not_Required',
  UserTempBlackListed: 'user_temp_black_listed',
  IPTemporaryBlacklist: 'ip_temporary_blacklist',
  Error: 'Error',
  AccountSecurityKeyRequired: 'account_security_key_required'
}