import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { Recognition } from '../../../modals/recognition';
import { RecognitionRenderFilterActionTypes } from './recognition.render.filter.actions';

export interface RecognitionRenderFilterState extends EntityState<Recognition> {
  hasNextPage?: boolean;
  loading?: boolean;
  loaded?: boolean;
  errorMessage?: string;
  isDataAvailable?: boolean;
}

export const adapter: EntityAdapter<Recognition> = createEntityAdapter<Recognition>({
  selectId: (recognition: Recognition) => recognition.guid
});

const intialRecognitionState = adapter.getInitialState({
  hasNextPage: true
});

export function recognitionRenderFilterReducer(state = intialRecognitionState, action): RecognitionRenderFilterState {

  switch (action.type) {

    case RecognitionRenderFilterActionTypes.RecognitionRenderFilterRequested:
      return { ...state, hasNextPage: true, loading: true }

    case RecognitionRenderFilterActionTypes.RecognitionRenderFilterLoaded:
      return adapter.addMany(action.payload.data, { ...state, hasNextPage: action.payload.hasNextPage, loading: false, isDataAvailable: (action.payload.data?.length > 0 ? true : false) });

    case RecognitionRenderFilterActionTypes.RecognitionRenderFilterUpdated:
      const updateRecognition: Update<Recognition> = {
        id: action.payload.recognitionId,
        changes: action.payload.recognition
      };
      return adapter.updateOne(updateRecognition, { ...state, hasNextPage: action.payload.hasNextPage, loading: false })

    case RecognitionRenderFilterActionTypes.RecognitionRenderFilterDeleted:
      return adapter.removeOne(action.payload.recognitionId, { ...state, hasNextPage: action.payload.hasNextPage, loading: false });

    case RecognitionRenderFilterActionTypes.RecognitionRenderFilterResetRequested:
      return adapter.setAll([], { ...state, hasNextPage: false, loading: false });

    case RecognitionRenderFilterActionTypes.RecognitionRenderFilterResetCompleted:
      return adapter.setAll(action.payload.data, { ...state, hasNextPage: action.payload.hasNextPage, loading: false, isDataAvailable: (action.payload.data?.length > 0 ? true : false) });

    case RecognitionRenderFilterActionTypes.RecognitionRenderFilterError:
      return { ...state, errorMessage: action.payload.errorMessage, loading: false };

    default:
      return { ...state, hasNextPage: true };
  }
}

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal
} = adapter.getSelectors();
