import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RecognitionApiService {
    constructor(private configService: ConfigService) { }
    getCoworkerListBySearchKeyword(param) {
        return this.configService.get("api/v2/recognition/employees", param);
    }
    getCompanyValuesList() {
        return this.configService.get("api/v2/recognition/tags");
    }
    getBusinessGoalList() {
        return this.configService.get("api/v2/recognition/business-goal");
    }
    markAccountNotificationsAsReadUnread(params) {
        return this.configService.post("api/v2/recognition/notifications/mark/read/unread", params);
    }
    getNotificationsList(params) {
        return this.configService.get("api/v2/recognition/notifications/paged/get", params);
    }
    getRecognitionAndKinectionInsightData(params) {
        return this.configService.get("api/v2/recognition/program/data/recognition-kinection/sent/get", params);
    }
    getEngahementRecognitonAnnounementData(params) {
        return this.configService.get("api/v2/recognition/program/data/engagement/sent", params);
    }
    getCoreValueInsightData(params) {
        return this.configService.get("api/v2/recognition/program/data/corevalues/sent/get", params);
    }
    getBusinessGoalInsightData(params) {
        return this.configService.get("api/v2/recognition/program/data/businessgoals/sent/get", params);
    }
    getUserInfluencerInsightData(params) {
        return this.configService.get("api/v2/recognition/program/data/infleuncer/get", params);
    }
    getUserActivityInsightData(params) {
        return this.configService.get("api/v2/recognition/program/data/activity/get", params);
    }
    getRecognitionPrenetrationInsightData(params) {
        return this.configService.get("api/v2/recognition/program/data/recognition-penetration/sent/get", params);
    }
    getCustomDataInsightData(params) {
        return this.configService.get("api/v2/recognition/program/data/custom-data/sent/get", params);
    }
    commonDownloadInsightData(params) {
        return this.configService.get("api/v2/recognition/program/data/common/download", params);
    }
    getAllKinectionsOfAccount(params) {
        return this.configService.get("api/v2/recognition/kinect/all", params);
    }
    dismissReminder(identifier: string) {
        return this.configService.post(`api/v2/recognition/reminder/${identifier}/delete`, null)
    }
    getCompanySurveyFor(params) {
        return this.configService.get(`api/v2/recognition/custom-survey/get`, params);
    }
    submitCompanySurveyForm(payload) {
        return this.configService.post('api/v2/recognition/custom-survey/submit', payload);
    }
    getCompanySurveyList(params) {
        return this.configService.get('api/v2/recognition/custom-survey/insights', params);
    }
    uploadSharePostFiles(feedIdentifier: string, files: File[] = [], gifIdentifiers: string[] = [], mediaMetaData: string[]) {
        let formData = new FormData();
        if (files.length) {
            files.forEach(file => { formData.append('file', file, file.name) });
        }
        if (gifIdentifiers.length) {
            formData.append('gifIdentifiers', JSON.stringify(gifIdentifiers));
        }
        if (mediaMetaData.length) {
            formData.append('mediaMetadata', JSON.stringify(mediaMetaData));
        }
        return this.configService.post(`api/v2/recognition/feed/${feedIdentifier}/media/upload`, formData, {}, null, true, 'events');
    }
    addPostToFeed(request) {
        return this.configService.post(`api/v2/recognition/feed/create`, request);
    }
    getCombinedFeedData(params) {
        return this.configService.get(`api/v2/recognition/home`, params);
    }
    getProfilePictureDefaultImages() {
        return this.configService.get('api/v2/recognition/default/profile/images');
    }
    updateUserProfile(params) {
        let serializedParam = Object.keys(params).map((key) => { return encodeURIComponent(key) + "=" + (encodeURIComponent((params[key] ?? ''))); }).join("&");
        return this.configService.put(`api/v2/recognition/profile`, serializedParam, { "Content-Type": "application/x-www-form-urlencoded", });
    }
    getUserProfileAbout(params) {
        return this.configService.get("api/v2/recognition/profile/about", params);
    }
    getLeaderboardYtdData = () => this.configService.get(`api/v2/recognition/feed/leaderboard`);

    getRewardsToExplore = () => this.configService.get(`api/v2/recognition/explore/rewards`);

    toggleUserLevelAI(enable: boolean) {
        return this.configService.put(`api/v2/recognition/update/ai/setting?enableRecognitionAI=${enable}`, null);
    }
    getAIPromptMessage(payload) {
        return this.configService.post(`api/v2/recognition/get/automated/recognition`, payload);
    }
    rephraseGetAIPromptMessage(payload) {
        return this.configService.post(`api/v2/recognition/get/automated/recognition`, payload);
    }
    getRecognitionOverTime() {
        return this.configService.get("api/v2/recognition/stats/overtime");
    }
    getRecognitionUsageDataTags(params) {
        return this.configService.get("api/v2/recognition/stats/tags", params);
    }
    getRecognitionTopStarRewardLeader() {
        return this.configService.get("api/v2/recognition/stats/stars");
    }
    getUserProfile(params) {
        return this.configService.get("api/v2/recognition/profile", params);
    }
    getRewardSiteList(params, recognitionId: string = null) {
        return this.configService.post("api/v2/recognition/rewards?recognitionIdentifier=&r1".replace("&r1", recognitionId), params);
    }
    getCustomReaction() {
        return this.configService.get('api/v2/recognition/reaction/custom/get');
    }
    removeCustomReaction(identifier: string) {
        return this.configService.delete(`api/v2/recognition/reactions/custom/${identifier}/remove`)
    }
    getAnnouncementDetails(isUserSetting) {
        return this.configService.get(`api/v2/recognition/announcement/settings/get/${isUserSetting}`);
    }
    updateAccountAnnouncementDetails(payload, isUserSetting: boolean) {
        return this.configService.post('api/v2/recognition/announcement/settings/update?isUserSetting=' + isUserSetting, payload);
    }
    getRecognitionFeedOfAccount(params): Observable<any> {
        return this.configService.get("api/v2/recognition/feed", params);
    }
    getRecognitionFeedOfUser(params) {
        return this.configService.get("api/v2/recognition/feed/me", params);
    }
    deleteRecognition(recognitionId) {
        return this.configService.delete("api/v2/recognition/&r1".replace("&r1", recognitionId));
    }
    updateRecognitionMessage(params) {
        let formData = new FormData();
        formData.append("request", JSON.stringify(params));
        return this.configService.put("api/v2/recognition/&r1/update".replace("&r1", params.recognitionId), formData, {});
    }
    getCoworkerListForReward(params) {
        return this.configService.get("api/v2/recognition/&r1/reward/employees".replace("&r1", params.recognitionId), params);
    }
    attachRewardToExistingRecognition(recognitionId: string, params: object) {
        let formData = new FormData();
        formData.append("request", JSON.stringify(params));
        return this.configService.put("api/v2/recognition/&r1/reward/attach".replace("&r1", recognitionId), formData, {});
    }
    getCoworkerListToKinectBySearchKeyword(params) {
        return this.configService.get(`api/v2/recognition/${params.recognitionId}/kinect/employees`, params);
    }
    addKinectToRecognition(params) {
        let formData = new FormData();
        formData.append("request", JSON.stringify(params));
        return this.configService.post(`api/v2/recognition/${params.recognitionId}/kinection/create`, formData, {});
    }
    getReactionOfRecognition(params) {
        return this.configService.get(`api/v2/recognition/${params.recognitionId}/reactions`, params);
    }
    addReactionToRecognition(params) {
        let formData = new FormData();
        formData.append("request", params.reactionIdentifier);
        return this.configService.put(`api/v2/recognition/${params.recognitionId}/react?replyId=${params.replyId ? params.replyId : ''}`, formData, {});
    }
    removeReactionToRecognition(params) {
        return this.configService.delete(`api/v2/recognition/${params.recognitionId}/react?replyId=${params.replyId ? params.replyId : ''}`);
    }
    deleteProfileImage() {
        return this.configService.delete("api/v2/recognition/delete/profile/image");
    }
    addCommentToRecognition(recognitionId, image, request) {
        let formData = new FormData();
        formData.append("request", JSON.stringify(request));
        if (image) {
            formData.append("image", image, image.name);
        }
        return this.configService.post("api/v2/recognition/&r1/comment/create".replace("&r1", recognitionId), formData, {});
    }
    saveEditCommentToRecognition(recognitionId, commentId, image, request) {
        let formData = new FormData();
        formData.append("request", JSON.stringify(request));
        if (image) {
            formData.append("image", image, image.name);
        }
        return this.configService.put("api/v2/recognition/&r1/comment/&r2".replace("&r1", recognitionId).replace("&r2", commentId), formData, {});
    }
    getCommentsAndKinections(params) {
        return this.configService.get("api/v2/recognition/&r1/replies".replace("&r1", params.recognitionId), params);
    }
    getCommentsAndKinectionsReply(params) {
        return this.configService.get("api/v2/recognition/&r1/&r2/subreplies".replace("&r1", params.recognitionId).replace("&r2", params.replyId), params);
    }
    deleteCommentOrKinectionFromRecognition(recognitionId, commentId) {
        return this.configService.delete("api/v2/recognition/&r1/reply/&r2".replace("&r1", recognitionId).replace("&r2", commentId));
    }
    getKinectionOfRecognition(params) {
        return this.configService.get("api/v2/recognition/&r1/kinections".replace("&r1", params.recognitionId));
    }
    getUserStats() {
        return this.configService.get("api/v2/recognition/stats");
    }
    getPostIndexByNotification(params) {
        return this.configService.get("api/v2/recognition/notification/index", params);
    }
    getRecognitionNotificationDetails(params) {
        return this.configService.get('api/v2/recognition/notification/data/get', params);
    }
    addCustomEmojiForReaction(params) {
        let formData = new FormData();
        if (params.file) {
            formData.append("image", params.file, params.file.name);
        }
        formData.append("request", JSON.stringify({ name: params.name, reactionUniCode: params.emojiId }));
        return this.configService.post("api/v2/recognition/reactions/custom/add", formData, {});
    }
    removeCustomEmojiForReaction() {
        return this.configService.delete(
            "api/v2/recognition/reactions/custom/remove"
        );
    }
    getPopularSearchList() {
        return this.configService.get("api/v2/recognition/popular-search");
    }
    getSearchFilterListByKeyword(params) {
        return this.configService.get(
            "api/v2/recognition/filter/search?keyword=", params
        );
    }
    getTrendingTags(params) {
        return this.configService.get("api/v2/recognition/trending-tags", params);
    }
    getGroupMemberList(params, groupId) {
        return this.configService.get("api/v2/recognition/group/profile/{groupId}/group-members/get".replace('{groupId}', groupId), params);
    }
}