import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class ToastCloseService {

  private closeToaster = new BehaviorSubject<boolean>(false);

  public setCloseToastValue(value: boolean): void {
    this.closeToaster.next(value);
  }

  public getCloseToastValue(): Observable<boolean> {
    return this.closeToaster.asObservable();
  }
}
