import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from "@angular/core";
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { FilteringActionType, TagSearchRequested, TagSearchLoaded } from './filtering.actions';
import { RecognitionApiService } from 'projects/recognition/src/app/providers/api.service/recognition.api.service';

@Injectable()
export class TagSearchEffects {

  constructor(private actions$: Actions, private _recognitionApiService: RecognitionApiService) { }

  
  loadTagSearch$ = createEffect(() => this.actions$
    .pipe(
      ofType<TagSearchRequested>(FilteringActionType.TagSearchRequested),
      mergeMap(action => this._recognitionApiService.getSearchFilterListByKeyword(action.payload)
        .pipe(
          catchError(error => {
            return of({ data: [] });
          }),
        )),
      map((result) => {
        return new TagSearchLoaded({ data: result?.data?.length > 0 ? result?.data : [] });
      })
    ))
}
