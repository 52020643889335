import { createReducer, on } from "@ngrx/store";
import { set_notification_bar_data } from "../actions/notification-bar.action";

export interface NotificationBarData {
    data: any,
    isLoading: boolean
};

export const initialNotificationBarSelectorState: NotificationBarData = {
    data: null,
    isLoading: true
}

const _notificationBarDataReducer = createReducer(
    initialNotificationBarSelectorState,
    on(set_notification_bar_data, (state, props) => { return { ...state, ...props, isLoading: false } }),
);

export function notificationBarDataReducer(state, action) {
    return _notificationBarDataReducer(state, action);
}
