import { Action } from '@ngrx/store';

export class CompanyValue {
  id?:string;
  identifier: string;
  tag: string;
  description: string;
  tagName: string;
  type: string;
  name? : string
}

export enum CompanyValueActionTypes {
  CompanyValueRequested = "[COMPANY_VALUE] company values requested",
  CompanyValueLoaded = "[COMPANY_VALUE] company values loaded"
}

export class CompanyValuesRequested implements Action {
  readonly type = CompanyValueActionTypes.CompanyValueRequested
  constructor() { }
}

export class CompanyValuesLoaded implements Action {
  readonly type = CompanyValueActionTypes.CompanyValueLoaded
  constructor(public payload: { tagList: CompanyValue[] }) { }
}

export type CompanyValueActions = CompanyValuesRequested | CompanyValuesLoaded;

