import { createSelector } from '@ngrx/store';

export const selectStatsState = state => state.stats;

export const selectStats = createSelector(
    selectStatsState,
    stats => stats
)

export const isStatsLoaded = createSelector(
    selectStatsState,
    stats => stats?.statsLoaded
)