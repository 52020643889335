export default class UserBudget {
  budgetType: string;
  id: string;
  isApprover: boolean;
  lastBalanceResetDate: string;
  remainingBalance: number;
  spendingLimit: number
  unlimitedBudget: boolean;
  resetBalanceRecurrence: TimePeriod;
  perGiftThreshold: number;
  isGroupBudget: boolean;
  nextBudgetResetDate?: Date;
}

export enum TimePeriod {
  None = 'None',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Biyearly = 'Biyearly',
  Yearly = 'Yearly',
  Weekly = 'Weekly',
  BiWeekly = 'BiWeekly',
}

export enum BudgetType {
  None = 'None',
  Manual = 'Manual',
  Recurring = 'Recurring',
  RecurringWithRollover = 'RecurringWithRollover',
  Unlimited = 'Unlimited'
}
