import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { token } from 'projects/assets/constant/encrypt.token';

@Injectable({ providedIn: 'root' })

export class EncryptDecryptService {

    encrypt(value) {
        if (!value) return value;
        
        try {
            let encJson = CryptoJS.AES.encrypt(JSON.stringify(value), token).toString()
            let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))
            return encData;
        } catch {
            return value;
        }
    }

    decrypt(value) {
        if (!value) return value;
        
        try {
            let decData = CryptoJS.enc.Base64.parse(value).toString(CryptoJS.enc.Utf8)
            let bytes = CryptoJS.AES.decrypt(decData, token).toString(CryptoJS.enc.Utf8)
            return JSON.parse(bytes)
        } catch (error) {
            return value;
        };
    }
}