import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WlProgressLoaderModule } from './loader/progress-loader';
import { WlLogoLoaderModule } from './loader/logo-loader';
import { WlTopLoaderModule } from './loader/top-loader';

@NgModule({
  imports: [
    CommonModule,
    WlLogoLoaderModule,
    WlTopLoaderModule
  ],
  exports: [
    WlLogoLoaderModule,
    WlTopLoaderModule
  ]
})
export class WishlistLoaderModule { }
