import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";

@Injectable({ providedIn: "root" })
export class FeatureWalkThroughApiService {
    constructor(private configService: ConfigService) { }
    
    getAccountFeatureWalkThroughStep(params) {
        return this.configService.get('api/v2/account/tutorial/step', params);
    }
    setAccountFeatureWalkThroughStepCompleted(identifier) {
        return this.configService.post(`api/v2/account/tutorial/${identifier}/user/mapping/insert`, null)
    }
    getRecognitionFeatureWalkThroughStep(params) {
        return this.configService.get('api/v2/recognition/tutorial/step', params);
    }
    setRecognitionFeatureWalkThroughStepCompleted(identifier) {
        return this.configService.post(`api/v2/recognition/tutorial/${identifier}/user/mapping/insert`, null)
    }
}