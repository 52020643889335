import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";

@Injectable({ providedIn: "root" })
export class CatalogApiService {
    constructor(private configService: ConfigService) { }

    getUserLocationFromIpAddress = () => this.configService.get('api/v2/catalog/ip/current/location/get');
    getRewardProgramDetails = (location) => this.configService.get('api/v2/catalog/location/details', { location });
    getUserLocationFromIp = () => this.configService.get("api/v2/catalog/ip/current/location/get");
}
