import { ExternalAppType } from "../externalApp";

export let Keys = {
  LoginKeys: {
    CdnUrl: "account/feed",
    LoginUrl: "account/login",
    NotificationId: 'notification',
    SsoKeys: {
      ProviderKey: "sso_login_provider",
      MicrosoftTeamsProvider: "microsoftteams",
      MicrosoftSharepointProvider: "microsoftsharepoint",
      GoogleProvider: "google",
      MicrosoftProvider: "microsoft",
      OktaProvider: "okta",
      ExternalTokenKey: "externalToken",
      SsoTypeKey: "sso_login_type",
      LoginThroughKey: "login_through",
      MicrosoftAzureAD: "microsoftAzureAD",

      MSTeamKeys: {
        CookieKey: 'MSCookie',
        TenantIdKey: "tenantId",
        AuthSuccessCallback: "ms_teams_auth_success_callback",
        EntityTabKey: "entity_tab_key",
        RecognitionEntityTab: "wishlist-recognition-home",//subject to change when the value of TeamsRewardsTabEntityId in shared settings is changed in BE
        RewardEntityTab: "wishlist-rewards-home"//subject to change when the value of TeamsTabEntityId in shared settings is changed
      },

      MSSharepointKeys: {
        CookieKey: 'MSSharepointCookie'
      }
    },
    ExternalAppKeys: {
      MicrosoftTeamsAppType: ExternalAppType.MicrosoftTeams,
      SlackAppType: ExternalAppType.Slack,

      SlackKeys: {
        SlackAuthKey: 'slackAuth',
        SlackVerificationStateKey: 'verificationState',
        SlackAuthValue: 'slack_integration_true'
      },

      MicrosoftKeys: {
        MicrosoftAuthKey: 'microsoftAuth',
        MicrosoftAuthValue: 'msteams-connect',
        MicrosoftVerificationStateKey: 'verificationState'
      }
    },
    GoogleSSOLoginErrorKey: 'idpiframe_initialization_failed',
    GoogleSSOLoginError: 'Cookies are not enabled in the current environment. Please enable to continue login with Google.',
    BannerMessage: {
      ForgotPasswordEmailSuccess: "<span class='bold'>Great!</span> If an account exists for this email, you will receive a password reset email shortly.",
      ForgotPasswordPhoneSuccess: "<span class='bold'>Great!</span> If an account exists for this phone, you will receive a password reset sms shortly.",
      ForgotPasswordSuccessWithEmail: "<span class='bold'>Great!</span> If an account exists for this email <span class='bold'>emailAddress</span>, you will receive a password reset email shortly.",
      ForgotPasswordSuccessWithPhone: "<span class='bold'>Great!</span> If an account exists for this phone <span class='bold'>phoneNumber</span>, you will receive a password reset SMS shortly.",
      PasswordResetSuccess: '<span class="bold">Phew!</span> Your Password has been successfully reset. Please login again.'
    }
  },
  ImpersonationFailed: "Can't switch account. Please try again later or contact support.",
  LoginVerified: 'Location has been verified. Please sign in again to continue.',
  Register: "isRegister",
  SignupEmail: "signupEmail",
  userName: "userName",
  username: "username",
}


export const MSOAuthSettings = {
  appId: '5a3e9aae-d7d1-4071-905d-eee752882558',
  scopes: [
    "email",
    "openid",
    "profile"
  ]
};
