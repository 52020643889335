import { Action } from '@ngrx/store';
import { TrendingTag } from '../../modals/recogntion.tags';

export enum TrendingTagsActionTypes {
  TRENDING_TAGS_REQUESTED = "[TRENDING_TAGS] trending tags requested",
  TRENDING_TAGS_LOADED = "[TRENDING_TAGS] trending tags loaded",
  TRENDING_TAGS_ERROR = "[TRENDING_TAGS] trending tags loading error",
  TRENDING_TAGS_LOADING = "[TRENDING_TAGS] trending tags loading",
}

export interface PageQuery {
  pageIndex: number;
  pageSize: number;
}

export class TrendingTagsRequested implements Action {
  readonly type = TrendingTagsActionTypes.TRENDING_TAGS_REQUESTED;
  constructor(public payload: { query: PageQuery }) {
  }
}

export class TrendingTagsLoaded implements Action {
  readonly type = TrendingTagsActionTypes.TRENDING_TAGS_LOADED;
  constructor(public payload: { trendingTagList: TrendingTag[], hasNextPage: boolean}) {
  }
}

export class TrendingTagsLoadingError implements Action {
  readonly type = TrendingTagsActionTypes.TRENDING_TAGS_ERROR;
  constructor(public payload: { errorMessage: string }) {
  }
}


export type TrendingTagsActionActions = TrendingTagsRequested | TrendingTagsLoaded | TrendingTagsLoadingError;
