import { CommonModule } from '@angular/common';
import { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SanitizerPipeModule } from 'projects/wishlist-design/src/lib/pipe/safe';
import { WlSnackbarComponent } from './component';
import { WlSnackbarConfigLiteral, WL_SNACKBAR_CONFIG } from './model';
import { WlSnackbarService } from './service';

@NgModule({
  declarations: [
    WlSnackbarComponent
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatButtonModule,
    SanitizerPipeModule,
    MatIconModule
  ],
  exports: [
    WlSnackbarComponent
  ],
  providers: [
    WlSnackbarService
  ]
})
export class WlSnackbarModule {

  static forRoot(wlSnackbarConfigLiteral?: WlSnackbarConfigLiteral): ModuleWithProviders<WlSnackbarModule> {
    return {
      ngModule: WlSnackbarModule,
      providers: [
        WlSnackbarService,
        { provide: WL_SNACKBAR_CONFIG, useValue: wlSnackbarConfigLiteral },
      ]
    }
  }
}
