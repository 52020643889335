import { Injectable } from '@angular/core';
import { UserLocalStorageKey } from '../../modals/keys/local.storage';
import { EncryptDecryptService } from '../encryption';

declare let setLocalStorageItem: any;
declare let getLocalStorageItem: any;
declare let removeLocalStorageItem: any;
declare let clearLocalStorage: any;

@Injectable({ providedIn: 'root' })
export class LocalStorageService {

  constructor(private encryptDecryptService: EncryptDecryptService) { }

  set(key: string, value: any) {
    setLocalStorageItem(key, JSON.stringify(value));
  }

  get(key: string,): any {
    try {
      let value = getLocalStorageItem(key);
      if (value) {
        return JSON.parse(value);
      }
      else {
        return null;
      }
    }
    catch {
      return null;
    }
  }

  remove(key) {
    removeLocalStorageItem(key);
  }

  getUserDetails() {
    return this.encryptDecryptService.decrypt(this.get(UserLocalStorageKey.User));
  }

  saveUserDetails(user) {
    return this.set(UserLocalStorageKey.User, this.encryptDecryptService.encrypt(user));
  }

  clearAllStorage() {
    clearLocalStorage();
  }
}
