import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import ThankyouNotes from '../../modals/ThankyouNotes';
import { ThankYouNotesActionType } from "./thankyou.notes.action";

export interface ThankyouState extends EntityState<ThankyouNotes> {
    hasNextPage: boolean;
    errorMessage?: string;
    isLoaded? : boolean;
}

export const adapter: EntityAdapter<ThankyouNotes> = createEntityAdapter<ThankyouNotes>({
});

const intialThankyouState = adapter.getInitialState();

export function thankyouNotesReducer(state = intialThankyouState, action): ThankyouState {

    switch (action.type) {

        case ThankYouNotesActionType.thankYouNotesLoad:

            return adapter.addMany(action.payload.notes, { ...state, hasNextPage: action.payload.hasNextPage,isLoaded : true });

        case ThankYouNotesActionType.thankYouNotesLoadingError:

            return { ...state, hasNextPage: true, errorMessage: action.payload.errorMessage, isLoaded : true };

        case ThankYouNotesActionType.thankYouNotesResetCompleted:
            
            return adapter.setAll(action.payload.notes, { ...state, hasNextPage: action.payload.hasNextPage });

        default:
            return { ...state, hasNextPage: true};
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();



