import { Injectable } from '@angular/core';
import Account, { AccountStatus } from '../../modals/account';
import { UserRole } from '../../modals/keys/variable.keys';
import { StoreService } from '../../store/store.service';
import { LocalStorageService } from '../localstorage/localStorage.service';
import User from '../../modals/user';

@Injectable({ providedIn: 'root' })
export class UserGuardService {
  private account: Account;
  private userDetails: User;
  constructor(private localStorageService: LocalStorageService, private storeService: StoreService) {
    this.storeService.getAccount().subscribe(x => this.account = x);
    this.storeService.getUser().subscribe(x => this.userDetails = x);
  }

  isLoggedIn(): boolean {
    return this.localStorageService.getUserDetails();
  }

  doesUserHaveAccessWishlistLab(): boolean {
    let { loggedInUser } = this.getLoggedInUserDetails();
    return JSON.parse((loggedInUser && loggedInUser.isWishlistLabAllowed ? loggedInUser.isWishlistLabAllowed : 'false'));
  }

  doesUserHaveAccessAdminPortal(): boolean {
    let { userRole } = this.getLoggedInUserDetails();
    return (userRole.indexOf(UserRole.B2bUsers) > -1) || (this.isUserRoleAdministrators());
  }

  doesUserHaveAccessAccountPortal(): boolean {
    let { userRole } = this.getLoggedInUserDetails();
    return (userRole.indexOf(UserRole.EmployeeLogin) > -1 && !this.isUserRoleAdministrators());
  }

  doesUserHaveAccessRewardPortal(): boolean {
    let { userRole } = this.getLoggedInUserDetails();
    return (userRole.indexOf(UserRole.WalletUser) > -1 && !this.isUserRoleAdministrators());
  }

  doesRewardSenderHaveAccess(): boolean {
    let { userRole } = this.getLoggedInUserDetails();
    return ((userRole.indexOf(UserRole.RewardSender) > -1) && !this.isUserRoleAdministrators());
  }

  doesProgramAdminHaveAccess(): boolean {
    let { userRole } = this.getLoggedInUserDetails();
    return ((userRole.indexOf(UserRole.ProgramManager) > -1) && !this.isUserRoleAdministrators());
  }

  doesAccountantHaveAccess(): boolean {
    let { userRole } = this.getLoggedInUserDetails();
    return ((userRole.indexOf(UserRole.Accountant) > -1) && !this.isUserRoleAdministrators());
  }

  doesWalletUserHaveAccess(): boolean {
    let { userRole } = this.getLoggedInUserDetails();
    return ((userRole.indexOf(UserRole.WalletUser) > -1) && !this.isUserRoleAdministrators());
  }

  isChannelPartnerAccount(): boolean {
    let { userRole } = this.getLoggedInUserDetails();
    return (userRole.indexOf(UserRole.ChannelPartner) > -1 && !this.isUserRoleAdministrators());
  }

  isUserRoleAdministrators(): boolean {
    let { userRole } = this.getLoggedInUserDetails();
    return userRole.indexOf(UserRole.Administrator) > -1 || userRole.indexOf(UserRole.FinanceAdministrator) > -1;
  }

  isUserRoleFullAdministrators(): boolean {
    let { userRole } = this.getLoggedInUserDetails();
    return userRole.indexOf(UserRole.Administrator) > -1;
  }

  isUserRoleFinanceAdministrators(): boolean {
    let { userRole } = this.getLoggedInUserDetails();
    return userRole.indexOf(UserRole.FinanceAdministrator) > -1;
  }

  isPrelaunchAccount(): boolean {
    return this.account.accountStatus == AccountStatus.PreLaunch ? true : false;
  }

  getLoggedInUserDetails(): { loggedInUser: any, userRole: any } {
    let loggedInUser = this.localStorageService.getUserDetails()
    return { loggedInUser, userRole: loggedInUser && loggedInUser.roles ? JSON.parse(loggedInUser.roles) : '' };
  }

  isAccountHaveSelfServiceProgramAllowed() {
    return this.doesWalletUserHaveAccess() && this.account.hasRewardSelfService;
  }

  isRecognitionEnabled() {
    return (this.account.isRecognitionEnabled ?? false) || this.doesUserHaveAccessAccountPortal();
  }

  isUserManager(): boolean {
    return this.userDetails.isManager ?? false;
  }
}


