import { AppState } from '../../../store/reducers/index';
import { Store, select } from '@ngrx/store';
import { filter, map, mergeMap, withLatestFrom, catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core"
import { Actions, createEffect, ofType,  } from '@ngrx/effects';
import { AccountApiService } from '../../../providers/api.service';
import { isUserBudgetLoaded } from './newfeed.selectors';
import { NewFeedActionTypes, UserBudgetRequested, UserBudgetLoaded, UserBudgetLoadingError, UserBudgetResetRequested, UserBudgetResetCompleted } from './newsfeed.actions';
import { of } from 'rxjs';

@Injectable()
export class NewFeedEffects {

    constructor(private actions$: Actions, private _accountApiService: AccountApiService, private store: Store<AppState>) { }

    
    loadBudgets$ = createEffect(() => this.actions$
        .pipe(
            ofType<UserBudgetRequested>(NewFeedActionTypes.UserBudgetRequested),
            withLatestFrom(this.store.pipe(select(isUserBudgetLoaded))),
            filter(([action, isUserBudgetLoaded]) => !isUserBudgetLoaded),
            mergeMap(action => this._accountApiService.getUserBudget().pipe(
                catchError(error => {
                    this.store.dispatch(new UserBudgetLoadingError({ errorMessage: error.message }));
                    return of([]);
                })
            )),
            map(budget => new UserBudgetLoaded({ userBudget: budget?.data, isLoaded: true, lastRewardReceivedByUserName: "", lastRewardSentDaysAgo: 0 }))
        ))

    
    resetBudgets$ = createEffect(() => this.actions$
        .pipe(
            ofType<UserBudgetResetRequested>(NewFeedActionTypes.UserBudgetResetRequested),
            mergeMap(action => this._accountApiService.getUserBudget()),
            map(budget => new UserBudgetResetCompleted({ userBudget: budget?.data }))
        ))
}