import { createReducer, on } from "@ngrx/store";
import { WalletConfig } from "../../modals/account";
import { set_wallet_config } from "../actions/account.action";

export const initialWalletConfigState: WalletConfig = {
    walletBalance: null,
    currencyCode: "USD"
}

const _walletConfigReducer = createReducer(
    initialWalletConfigState,
    on(set_wallet_config, (state, props) => { return props }),
);

export function walletConfigReducer(state, action) {
    return _walletConfigReducer(state, action);
}
