import { map, mergeMap } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { CompanyValuesRequested, CompanyValuesLoaded, CompanyValueActionTypes } from './company.value.actions';
import { of } from 'rxjs';
import { UserGuardService } from '../../providers/authentication/user.guard.service';
import { RecognitionApiService } from '../../providers/api.service/recognition.api.service';

@Injectable()
export class CompanyValueEffects {

  constructor(private _actions$: Actions, private _recognitionApiService: RecognitionApiService, private _userGuardService: UserGuardService) { }


  loadTags$ = createEffect(() => this._actions$
    .pipe(
      ofType<CompanyValuesRequested>(CompanyValueActionTypes.CompanyValueRequested),
      mergeMap(action => (this._userGuardService.doesUserHaveAccessAccountPortal() ? this._recognitionApiService.getCompanyValuesList() : of({ data: [] }))),
      map((result) => {
        return new CompanyValuesLoaded({ tagList: (result?.data?.length ? result.data : []) })
      })
    ))
}

