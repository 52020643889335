import { UserStats } from '../../modals/User.Stats';
import { Action } from '@ngrx/store';

export enum UserStatsActionType {
    UserStatsRequested = "[USER_STATS] Stats Requested",
    loadUserStats = "[USER_STATS] Stats Api Call",
    loadUserStatsError = "[USER_STATS] api call error",
    UserResetRequested = "[USER_STATS] reset requested",
    UserResetCompleted = "[USER_STATS] reset completed"
}


export class UserStatsRequested implements Action {
    readonly type = UserStatsActionType.UserStatsRequested
    constructor(public isRefreshRequired: boolean) { }
}

export class UserStatsLoaded implements Action {
    readonly type = UserStatsActionType.loadUserStats
    constructor(public payload: { stats: UserStats, statsLoaded: boolean }) { }
}
export class UserStatsLoadingError implements Action {
    readonly type = UserStatsActionType.loadUserStatsError
    constructor(public payload: { errorMessage: string }) { }
}
export class UserStatsResetRequested implements Action {
    readonly type = UserStatsActionType.UserResetRequested
    constructor() { }
}
export class UserStatsResetCompleted implements Action {
    readonly type = UserStatsActionType.UserResetCompleted
    constructor(public payload: { stats: UserStats }) { }
}

export type UserStatsActionTypes = UserStatsRequested | UserStatsLoaded;