import { Component, Inject, OnInit, Injectable, HostListener, OnDestroy } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SnackbarCloseService } from '../snackbar.close.service';
import { UtilityService } from '../../utility/utility.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { WindowResizeService } from '../../window.resize/window.resize.service';
import { SnackbarAction, SnackbarData } from '../../../modals/snackbar';
import { ApiService } from '../../api.service';
import { defaultErrorMessage } from '../../../modals/keys/error.key';
import { ZendeskService } from '../../zendesk';

@Injectable({ providedIn: 'root' })
@Component({
  selector: 'show-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})

export class SnackBarComponent implements OnInit, OnDestroy {
  private isDebug = environment.isDebug;
  private snackbarCloseService$: Subscription;
  public snackbar: SnackbarData;
  public processing: boolean;
  public readonly Okay = SnackbarAction.Okay;
  public readonly Retry = SnackbarAction.Retry;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData, public snackBarRef: MatSnackBarRef<SnackBarComponent>, public router: Router, private snackbarCloseService: SnackbarCloseService, private _zendeskService: ZendeskService, private apiService: ApiService) {
    this.snackbar = data;
  }

  ngOnInit() {
    this.toggleZendeskChat(true);
    this.setToasterCloseValueDefault();
    this.closeToasterManually();
  }

  ngOnDestroy() {
    if (this.snackbarCloseService$) { this.snackbarCloseService$.unsubscribe(); }
    this.toggleZendeskChat(false);
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.closeSnackbar();
    }
  }

  setToasterCloseValueDefault() {
    this.snackbarCloseService.closeSnackbar.next(false);
  }

  closeToasterManually() {
    this.snackbarCloseService$ = this.snackbarCloseService.closeSnackbar
      .subscribe((value) => {
        if (value) {
          this.closeSnackbar();
        }
      });
  }

  closeSnackbar() {
    this.snackBarRef.dismiss();
  }

  isAdminPortalUrl(): boolean {
    let url = this.router.url;
    return (url.indexOf('admin') > -1);
  }

  toggleZendeskChat(hideChat: boolean) {
    hideChat = !this.isDebug ? hideChat : true;
    this._zendeskService.toggleZendeskOnToaster(hideChat);
  }

  callSnackbarAction(actionType: SnackbarAction): void {
    switch (actionType) {
      case SnackbarAction.ResendResetPasswordEmail:
        this.resendResetPasswordLink();
        break;
    }
  }

  resendResetPasswordLink() {
    if (this.snackbar.values) {
      this.snackbar.actionType = null;
      this.snackbar.message = "Request processing.";
      this.processing = true;
      this.apiService.resendGlobalPasswordResetLink(this.snackbar.values).subscribe((response) => {
        if (response?.success) {
          this.snackbar.message = "To create a new password, please follow the instructions emailed to your registered email address.";
        }
        else {
          this.snackbar.message = response?.message ?? defaultErrorMessage;
        }
        this.processing = false;
      }, (error) => {
        this.processing = false;
        this.snackbar.message = error?.message ?? defaultErrorMessage;
      });
    }
  }

  dismissSnackBar() {
    this.snackBarRef.dismissWithAction();
  }
}
