export enum UserRole {
  None = '',
  Administrator = 'Administrators',
  FinanceAdministrator = 'FinanceAdministrator',
  ProgramAdmin = 'ProgramAdmin',
  ProgramManager = 'ProgramManager',
  RewardSender = 'RewardSender',
  Accountant = 'Accountant',
  Employee = 'Employee',
  EmployeeLogin = 'EmployeeLogin',
  Guests = 'Guests',
  B2bUsers = "B2bUsers",
  ChannelPartner = "ChannelPartner",
  Default = 'Default',
  WalletUser = 'WalletUser',
  ManagerWithRewardSendingAccess = 'ManagerWithRewardSendingAccess'
}

export enum UserRoleDisplay {
  ProgramManger = 'Program Manager',
  RewardSender = 'Reward Sender',
  Accountant = 'Accountant',
  Employee = 'Employee'
}

export enum FullScreenPostType {
  Normal = 'Normal',
  Notification = 'Notification',
  Filtered = 'Filtered'
}

export let TagPresenter = '#';

export enum PrelaunchWarningkey {
  ConfirmText = "Ok",
  CancelText = "",
  NotActivateTitle = "Not Activated",
  ExternalApp = "External App integration are currently not active for your account. Please contact your account adminstrator for more details.",
  AttachReward = "Attach rewards are currently not active for your account. Please contact your account adminstrator for more details.",
}

export enum DefaultInvite {
  heading = 'Congrats! Wishlist sent you a reward!',
  message = 'Please enjoy this gift courtesy of Wishlist',
}

export enum RemindersKey {
  WeeklyOnMonday = 'Weekly Reminders (Mondays)',
  OnceFortniteOnMondays = 'Once a fortnite on Mondays',
  FirstMondayOfEveryMonth = 'Monthly Reminder (First Monday of every month)'
}