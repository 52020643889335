import { createSelector } from "@ngrx/store";
import { AppState } from "../../store/reducers";
import { overTimeadapter, tagadapter } from "./usage.insights.reducers";

export const selectOverTimeState = (state: AppState) => state.overtime;
export const selectStarState = (state: AppState) => state.stars;
export const selectTagState = (state: AppState) => state.tagChart;

export const selectAllOverTimeSelector = createSelector(
    selectOverTimeState,
    (overTimeadapter.getSelectors().selectAll)
)

export const isOverTimeLoadedSelector = createSelector(
    selectOverTimeState,
    overTime => overTime.isLoaded
)


export const selectAllTagSelector = createSelector(
    selectTagState,
    (tagadapter.getSelectors().selectAll)
)

export const selectAllStarsSelector = createSelector(
    selectStarState,
    stars => stars
)

export const selectIsStarsLoadedSelector = createSelector(
    selectStarState,
    stars => stars.isLoaded
)
export const selectIsTagLoadedSelector = createSelector(
    selectTagState,
    tags => tags.isLoaded
)
