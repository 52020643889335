export enum FeedSubType {
  None = 'None',               //for Type.Recognition
  General = 'General',            //for Type.Post
  ThankYouNote = 'ThankYouNote',       //for Type.Post
  Birthday = 'Birthday',           //for Type.Announcement
  StackedBirthday = 'StackedBirthday',    //for Type.Announcement
  Anniversary = 'Anniversary',        //for Type.Announcement
  StackedAnniversary = 'StackedAnniversary', //for Type.Announcement
  Promotion = 'Promotion',          //for Type.Announcement
  GettingMarried = 'GettingMarried',     //for Type.Announcement
  HavingChild = 'HavingChild',       //for Type.Announcement
  Retirement = 'Retirement',        //for Type.Announcement
  Setback = 'Setback',          //for Type.Announcement
  Other = 'Other'          //for Type.Post
}

export enum FeedType {
  Recognition = 'Recognition',
  Announcement = 'Announcement',
  Post = 'Post'
}

export interface FeedConfig {
  identifier: string;
  type: FeedType;
  subType: FeedSubType
  label: string;
  placeholder: string;
  isConfigVisible: boolean;
  mediaAttributes: { data: FeedConfigMediaAttribute[] }
  iconFileName:string;
  value:string;
  option:string;
  displayName:string;
}

export interface FeedConfigMediaAttribute {
  mediaAttribution: string;
  mediaId: string;
  mediaType: string;
  mediaUrl: string;
}

export interface Gif {
  guid: string;
  url: string;
}

export class Recognition {
  id: string;
  guid: string;
  createdOnUtc: string;
  type: number;
  feedConfig: FeedConfig;
  createdBy: RecognitionCreator;
  recipients: RecognitionCreator[];
  isSentByCurrentUser: boolean;
  isEditStateMessage: boolean;
  message: string;
  tags: Tags[];
  defaultTagList: Tags[];
  reactions: Reaction[];
  commentCount: number;
  kinectCount: number;
  kinectedUsers: KinectedUser[];
  kinectAllowed: boolean;
  rewardAllowed: boolean;
  showReward: boolean;
  commentsAndKinections: Comments[];
  postComment: any;
  sharedExperienceDetails: any; // Remove this.
  mediaAttributes: MediaData;
  sendKinection: SendKinection;
  hasMoreComments: boolean;
  stackedFeedCount: number;
  isRewardAttached?: boolean;
}

export class MediaData {
  data: MediaList[]
}

export class MediaList {
  MediaUrl: string;
  MediaId: string;
  MediaAttribution: string;
  MediaType: number;
}

export class ShareableProduct { // This class can be remove if not used.
  orderGuid: string;
  productName: string;
  bookingDate: string;
  productImageUrl: string;
  location: string;
}

export class SendKinection {
  peopleList: any[];
  isMessageAvailable: boolean;
  message: string;
  selectPeople: any;
}

export class PostComment {
  coworkerSuggesstions: boolean;
  imageFile: any
  imageGuid: string;
  imageType: any
  imageUrl: string
  isEmojisBoxShowed: boolean
  isMessageAvailable: boolean;
  message: string;
  peopleList: any[]
}

export class RecognitionCreator {
  id: number;
  guid: string;
  firstName: string;
  lastName: string;
  handle: string;
  profileImageUrl: string;
  isOffboarded: boolean;
  email: string;
  active: boolean;
  deleted: boolean;
}

export class Comments {
  guid: string;
  createdBy: RecognitionCreator;
  createdOnUtc: string;
  type: number;
  mediaType: number;
  mediaUrl: string;
  message: number;
  recipients: RecognitionCreator[];
  profileImageUrl: string;
  reactions: Reaction[];
  iskinectRemove: boolean;
  subReplyData: SubReply;
  tags: Tags[];
  isEditState: boolean;
  editStateValues: any;
}

export class Tags {
  identifier: string;
  tagType: string;
  tag: string;
  tagName: string;
  active: boolean;
}

export class SubReply {
  firstSubReplier: RecognitionCreator;
  subReplyCount: number;
  type: number;
  isMoreDataAvailable: boolean;
  subReplies: SubComments[];
  subReplyIndex: number;
}

export class SubComments {
  guid: string;
  createdBy: RecognitionCreator;
  createdOnUtc: string;
  type: number;
  mediaType: number;
  mediaUrl: string;
  message: number;
  recipients: RecognitionCreator[];
  profileImageUrl: string;
  iskinectRemove: boolean;
  subReplyData: [];
  reactions: Reaction[];
  tags: Tags[];
  isEditState: boolean;
  editStateValues: any;
}

export class Reaction {
  count: number;
  reactionDetail: ReactEmoji;
  reactedByUserName: string[];
  isReacted: boolean;
  recognitionReactIdentifier: string;
  reactedByUserMap: ReactedByUserMap[];
}

export class ReactEmoji {
  reactionIdentifier: string;
  reactionUniCode: string;
  reactionUrl: string;
  name: string;
  type: RecognitionReactionType;
  active: boolean;
}

export class KinectedUser {
  name: string;
  profileImageUrl: string;
  isGroup: boolean
}

class ReactedByUserMap {
  createdByUserId: number;
  reactedByUserName: string;
  recognitionReactIdentifier: string;
  createdByProfileUrl: string;
}

export enum RecognitionReactionType {
  None = 'None',
  Like = 'Like',
  Love = 'Love',
  Praise = 'Praise',
  HundredPercent = 'HundredPercent',
  Interesting = 'Interesting',
  Celebrate = 'Celebrate',
  Custom = 'Custom'
}

export interface RecognitionReminder {
  identifier: string,
  message: string;
}

export enum RewardOptions {
  AmountOnly = 'AmountOnly',
  SiteOnly = 'SiteOnly',
  CategoryOnly = 'CategoryOnly',
  SiteWithCategory = 'SiteWithCategory'
}
