import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserBudgetResetRequested } from '../../account/newsfeed/newsfeed.component/newsfeed.actions';
import { AppState } from '../../store/reducers';

@Injectable({ providedIn: 'root' })

export class RewardSomeoneService {
  private wasRewardsSent: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private store: Store<AppState>) { }

  public setRewardsWereSent(value: boolean = true) {
    this.wasRewardsSent.next(value);
    if (value) { this.refreshRewardState(); }
  }

  public getRewardsWereSent(): Observable<boolean> {
    return this.wasRewardsSent;
  }

  refreshRewardState() {
    this.store.dispatch(new UserBudgetResetRequested())
  }
}
