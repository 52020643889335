import { Component, ViewEncapsulation, Injectable, OnInit, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class FrostedService {
  private frosted: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private frostedWithoutHeader: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private frostedBackgroundOnDialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private frostedBackgroundOnLogout: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public setFrostedBackgroundValue(value: boolean) {
    this.frosted.next(value);
  }

  public getFrostedBackgroundValue(): Observable<boolean> {
    return this.frosted.asObservable();
  }

  public setFrostedWithoutHeaderBackgroundValue(value: boolean) {
    this.frostedWithoutHeader.next(value);
  }
  public getFrostedWithoutHeaderBackgroundValue(): Observable<boolean> {
    return this.frostedWithoutHeader.asObservable();
  }

  public setFrostedBackgroundOnDialog(value: boolean) {
    this.frostedBackgroundOnDialog.next(value);
  }

  public getFrostedBackgroundOnDialog(): Observable<boolean> {
    return this.frostedBackgroundOnDialog.asObservable();
  }

  public setFrostedBackgroundOnLogout(value: boolean) {
    this.frostedBackgroundOnLogout.next(value);
  }

  public getFrostedBackgroundOnLogout(): Observable<boolean> {
    return this.frostedBackgroundOnLogout.asObservable()
  }
}
