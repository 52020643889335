import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";
import { HttpHeaders } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { Observable, forkJoin } from "rxjs";
import { environment } from '../../../environments/environment';
import { IsoDateRegex } from "../../modals/keys/regex";
import { ExternalAppType, SSOOptionType } from "../../modals/externalApp";
import { ApiResult } from "../../modals/response";

@Injectable({ providedIn: "root" })
export class ApiService {
  public cdnUrl: string;
  private quartzBaseUrl: string = environment.quartzBaseUrl;

  constructor(private configService: ConfigService) {
    this.cdnUrl = configService.cdnUrl;
  }

  //#region /*User Login APIs*/
  guestUserLogin(user) {
    return this.configService.post("api/v2/oauth/guest", user);
  }

  getNewAccessToken(user) {
    let serializedParam = Object.keys(user).map((k) => { return encodeURIComponent(k) + "=" + encodeURIComponent(user[k]); }).join("&");
    return this.configService.post("api/v2/oauth/refresh", serializedParam, { "Content-Type": "application/x-www-form-urlencoded", });
  }

  login(params, validate: boolean) {
    if (validate) {
      return this.configService.post("api/v2/oauth/validate", params);
    }
    else {
      return this.configService.post("api/v2/oauth/login", params);
    }
  }

  signUpUserOnBoarding(payload: object) {
    return this.configService.post('api/v1/user/signup', payload);
  };

  registerOnboardingUser(payload: object) {
    return this.configService.post('api/v1/user/register', payload);
  };

  getSessionCode() {
    return this.configService.post("api/v2/oauth/code/session", {});
  }

  logout = (isMsTeams: boolean) => this.configService.post(`api/v2/oauth/cancel/token?isMsTeams=${isMsTeams}`, {});

  checkUserTfaStatus(params: object) {
    return this.configService.post("api/v2/oauth/login/status", params);
  }

  // To Be called when signup triggers from the login page.
  sendLoginRegistration(params) {
    return this.configService.post("api/v2/oauth/login/signup/notification/send", params);
  }

  sendRegistrationOnlyNotification(params) {
    return this.configService.post("api/v2/user/send/registration/notification", params);
  }

  validateCommunicationEmail(params) {
    return this.configService.post("api/v2/user/add/email/validate", params);
  }

  setCommunicationEmail(params) {
    return this.configService.post("api/v2/user/add/email", params);
  }

  shootOptForUserLogin(params) {
    return this.configService.post("api/v2/oauth/send/login/otp", params);
  }
  externalLogin(idToken, otp, provider, tenantId: string = "") {
    return this.configService.post("api/v2/oauth/external/login?otp=%s1&provider=%s2&tenantId=%s3".replace("%s1", otp).replace("%s2", provider).replace("%s3", tenantId ? tenantId : ""), idToken);
  }
  ssoLoginValidate(params) {
    return this.configService.post("api/v2/oauth/sso/validate", params);
  }
  emailResetPasswordLink(userName: string, continueUrl: string = null, accountIdentifier?: string) {
    return this.configService.post(`api/v1/user/emailresetpasswordlink?continueUrl=${continueUrl}&securityKey=${accountIdentifier}`, JSON.stringify(userName));
  }
  userResetPassword(request) {
    return this.configService.post("api/v1/user/resetpassword", request);
  }
  registerLocation(request) {
    return this.configService.post("api/v1/user/register/location", request);
  }
  resendGlobalPasswordResetLink(email) {
    return this.configService.post(`api/v1/user/resend/global/reset/password`, '"' + email + '"');
  }
  verifyTfaOtpForCorp(otp) {
    return this.configService.get("api/v1/quartz/schedule/task/check/otp/{otp}/navigate".replace("{otp}", otp), null, null, null, null, null, this.quartzBaseUrl);
  }
  getQuartzJobList() {
    return this.configService.get("api/v1/quartz/schedule/tasks/data/get", null, null, null, null, null, this.quartzBaseUrl);
  }
  triggerQuartzJobExecution(jobKey: string, jobGroup: string, otp: number | string) {
    return this.configService.post(`api/v1/quartz/schedule/task/${jobKey}/group/${jobGroup}/trigger?otp=${otp}`, {}, null, null, null, null, this.quartzBaseUrl);
  }
  getQuartzJobHistory(taskInformationIdentifier, params) {
    return this.configService.get("api/v1/quartz/schedule/task/{taskInformationIdentifier}/paged/history/get".replace("{taskInformationIdentifier}", taskInformationIdentifier), params, null, null, null, null, this.quartzBaseUrl);
  }
  getAutoCodes(params) {
    return this.configService.get('api/v2/admin/product/autocode/get', params);
  }
  uploadVendorCodeFile(formData) {
    const HttpUploadOptions = { headers: new HttpHeaders({ "Content-Type": "multipart/form-data" }) };
    return this.configService.post('api/v2/admin/product/autocode/denominations/upload', formData, HttpUploadOptions);
  }
  downloadVendorCodeFile(guid) {
    return this.configService.get('api/v2/admin/product/autocode/denominations/template/get?productId=' + guid);
  }
  createAutoCodeProduct(payload) {
    return this.configService.post('api/v2/admin/product/autocode/add', payload);
  }
  updateAutoCodeProduct(payload) {
    return this.configService.post('api/v2/admin/product/autocode/update', payload);
  }
  getFilterSuggestion(filterType) {
    return this.configService.get('api/v2/admin/product/autocode/filters/suggestion', filterType);
  }
  getAutoCodeDenominations(identifier) {
    return this.configService.get('api/v2/admin/product/autocode/denominations/get?productId=' + identifier);
  }
  //#endregion

  updateUserProfile(params) {
    let formData = new FormData();
    formData.append("request", JSON.stringify(params));
    return this.configService.put("api/v2/recognition/profile", formData, {});
  }
  //#endregion

  //#region /*Admin Portal*/

  getProductData(params) {
    return this.configService.get("api/v2/admin/product", params);
  }
  getProductDetailByProductId(productId) {
    return this.configService.get(`api/v2/admin/${productId}/product`);
  }
  getGiftProductProviderData() {
    return this.configService.get("api/v2/admin/giftcard/provider/data");
  }
  getExperienceProductProviderData() {
    return this.configService.get(
      "api/v2/admin/experience/product/provider/data"
    );
  }
  getSubCategoryById(identifier) {
    return this.configService.get(`api/v2/admin/subcategory/category/${identifier}`);
  }
  getTagsAndMarketPlaceConfigData() {
    return this.configService.get("api/v2/admin/visibility/tags/list/get");
  }
  getExistingVendorData(params) {
    return this.configService.get("api/v2/admin/exist/vendor/details/get", params);
  }
  getProductTypes() {
    return this.configService.get("api/v2/admin/product/types/get");
  }
  getDefaultFormJson(defaultFormType: number) {
    return this.configService.get(
      "api/v2/admin/get/default/booking/form?defaultJsonEnum=" +
      defaultFormType
    );
  }
  getVendorTypes() {
    return this.configService.get("api/v2/admin/vendor/types/get");
  }
  addProduct(params) {
    let formData = new FormData();
    if (params.productOriginalImageType == "File") {
      formData.append("imageFile", params.productOriginalImage, "imageFile");
      params.productOriginalImage = "";
    }
    formData.append("request", JSON.stringify(params));
    return this.configService.post("api/v2/admin/product/add", formData, {});
  }
  updateProduct(params) {
    let formData = new FormData();
    if (params.productOriginalImageType == "File") {
      formData.append("imageFile", params.productOriginalImage, "imageFile");
      params.productOriginalImage = "";
    }
    formData.append("request", JSON.stringify(params));
    return this.configService.post("api/v2/admin/product/update", formData, {});
  }
  getFareHarborVendors() {
    return this.configService.get("api/v2/admin/get/fareharbor/vendor/list");
  }
  getShopifyMerchants() {
    return this.configService.get('api/v2/admin/shopify/vendors/get')
  }
  getFareHarborItems(params: any) {
    return this.configService.get("api/v2/admin/get/fareharbor/items", params);
  }
  getShopifyItems(vendorIdentifier: string, productName: string = '') {
    return this.configService.get(`api/v2/admin/shopify/products/vendor/${vendorIdentifier}/get`, { productName: productName ? productName : '' });
  }
  
  getAttributeMinMaxMargin(identifier: string) {
    return this.configService.get(
      `api/v2/admin/product/${identifier}/margin/min/max/get`
    );
  }
  deleteGCAttribute(attributeId: string) {
    return this.configService.delete(
      "api/v2/admin/product/margin/" + attributeId + "/delete"
    );
  }

  downloadQuartsErrorLog(identifier) {
    return this.configService.get(`api/v2/quartz/task/history/${identifier}/exception/details/get`);
  }
  executeForkJoin(list): Observable<any> {
    return forkJoin(list).pipe(
      map((event) => {
        return event;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  getThisQuarterDate() {
    let date = new Date();
    let currentMonth = date.getUTCMonth();
    let thisQuarterDate = {
      startDate: new Date(),
      endDate: new Date(),
    };
    switch (currentMonth) {
      case 0:
      case 1:
      case 2:
        thisQuarterDate.startDate = new Date(date.getFullYear(), 0, 1);
        thisQuarterDate.endDate = new Date(date.getFullYear(), 3, 31);
        break;
      case 3:
      case 4:
      case 5:
        thisQuarterDate.startDate = new Date(date.getFullYear(), 3, 1);
        thisQuarterDate.endDate = new Date(date.getFullYear(), 5, 30);
        break;
      case 6:
      case 7:
      case 8:
        thisQuarterDate.startDate = new Date(date.getFullYear(), 6, 1);
        thisQuarterDate.endDate = new Date(date.getFullYear(), 8, 30);
        break;
      case 9:
      case 10:
      case 11:
        thisQuarterDate.startDate = new Date(date.getFullYear(), 9, 1);
        thisQuarterDate.endDate = new Date(date.getFullYear(), 11, 31);
        break;
      default:
        break;
    }
    return thisQuarterDate;
  }

  getLastQuarterDate() {
    let date = this.getThisQuarterDate();

    let lastQuarterDate = {
      startDate: new Date(
        date.startDate.getFullYear(),
        date.startDate.getMonth() - 3,
        1
      ),
      endDate: new Date(
        date.startDate.getFullYear(),
        date.startDate.getMonth(),
        0
      ),
    };
    return lastQuarterDate;
  }
  getNextQuarterDate() {
    let date = this.getThisQuarterDate();
    let nextQuarterDate = {
      startDate: new Date(
        date.startDate.getFullYear(),
        date.startDate.getMonth() + 3,
        1
      ),
      endDate: new Date(
        date.startDate.getFullYear(),
        date.startDate.getMonth() + 6,
        0
      ),
    };
    return nextQuarterDate;
  }
  getDateString(value) {
    if (!value) {
      return "";
    }
    let date = new Date(value);
    return (
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
    );
  }
  getDateStringFormat(value, type: number = 1): string {
    let date = new Date(value);
    let dateString = "";
    switch (type) {
      case 1:
        dateString =
          date.getMonth() + 1 + "-" + date.getDate() + "-" + date.getFullYear();
        break;
      case 2:
        dateString =
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate();
        break;
      case 3:
        dateString =
          date.getDate() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getFullYear();
        break;
      default:
        dateString =
          date.getMonth() + 1 + "-" + date.getDate() + "-" + date.getFullYear();
        break;
    }
    return dateString;
  }
  getDateTimeString(value) {
    let date = new Date(value);
    return (
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      ", " +
      this.getdateAmPm(date)
    );
  }
  getFileDownloadDateTimeString(value) {
    let date = new Date(value);
    return (
      date.getMonth() +
      1 +
      "_" +
      date.getDate() +
      "_" +
      date.getFullYear() +
      "_" +
      date.getHours() +
      "_" +
      date.getMinutes() +
      "_" +
      date.getSeconds()
    );
  }
  getdateAmPm(value, removeZero = false) {
    let date = new Date(value);
    let isPM = date.getHours() >= 12;
    let isMidday = date.getHours() == 12;
    let hours = date.getHours() - (isPM && !isMidday ? 12 : 0);
    let time =
      [
        hours >= 10 ? hours : !removeZero ? "0" + hours : hours,
        date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes(),
      ].join(":") + (isPM ? " PM" : " AM");
    return time;
  }

  getTimeWithoutOffset(value) {
    let regex =
      "^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\\.[0-9]+)?(Z)?";
    var time = value.match(regex)[0];
    return time;
  }

  getUtcDateWithNoChangeDateTime(date: Date) {
    if (!date) { return null }
    let coversionDate = isIsoDate(date) ? new Date(date.toLocaleString()) : new Date(new Date(date).toISOString());
    let utcDate = new Date(coversionDate.getTime() - (coversionDate.getTimezoneOffset() * 60000)).toISOString();
    return utcDate;

    function isIsoDate(value): boolean {
      return IsoDateRegex.test(value) ? true : false;
    }
  }

  getLocalDateWithNoChangeDateTimeFromUTC(value: Date | string): Date {
    let modifiedValue = null
    if (value) {
      if (isIsoDate(value)) {
        let coversionDate = new Date(value.toLocaleString());
        modifiedValue = new Date(coversionDate.getTime() + coversionDate.getTimezoneOffset() * 60000);
      }
      else {
        modifiedValue = new Date(value);
      }

      function isIsoDate(value): boolean {
        return IsoDateRegex.test(value) ? true : false;
      }
    }

    return modifiedValue;
  }

  generateAccessCode(params) {
    var serializedParam = Object.keys(params).map((k) => { return encodeURIComponent(k) + "=" + encodeURIComponent(params[k]); }).join("&");
    return this.configService.post(`api/v2/oauth/code/generate`, serializedParam, { "Content-Type": "application/x-www-form-urlencoded", });
  }

  getUserConfig() {
    return this.configService.get('api/v2/user/config/me');
  }

  getAllVendors() {
    return this.configService.get('api/admin/shopify/vendor/get');
  }

  downloadAttachment(identifier: string) {
    return this.configService.get(`api/v2/user/download/attachment?identifier=${identifier}`);
  }
  downloadFromLink(identifier: string, type) {
    return this.configService.get(`api/v2/admin/download/from/link?identifier=${identifier}&type=${type}`);
  }

  reactivateAccount(payload) {
    return this.configService.post(`api/v1/user/reactive/user/account`, payload);
  }

  invalidateDevices(payload) {
    return this.configService.post(`api/v1/user/device/invalidate`, payload);
  }

  getFulfillmentData(identifier: string) {
    return this.configService.get(`api/v2/user/selffulfillment?identifier=${identifier}`);
  }

  setFulfillmentData(identifier: string, size: string) {
    return this.configService.post(`api/v2/user/selffulfillment/${identifier}?option=${size}`, null);
  }

  switchAccountSendOtp = (payload) => this.configService.post(`api/v2/user/send/account/switch/otp`, payload);
  setPrivacyPolicy  = () => this.configService.post(`api/v2/user/privacy/policy/accept`,null);
  getMyRewardConfig = () => this.configService.get(`api/v2/user/my/rewards/config`);
}

