import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CommonCategoryKeys } from 'projects/recognition/src/app/modals/category';
export class SubCategoryNode {
    children: SubCategoryNode[];
    item: string;
    nodeValue: CommonCategoryKeys
}
export class SubCategoryNodeFlatNode {
    item: string;
    level: number;
    expandable: boolean;
    nodeValue: CommonCategoryKeys
}

class Node {
    id: number;
    parentId: number;
    name: string;
    displayName: string;
    imageUrl: string;
    identifier: string;
    categoryId: number;
    leftNode: number;
    rightNode: number;
}

class CategoryData {
    data: Node;
    children: CategoryData[];
}

@Injectable()
export class ChecklistDatabaseManager {
    dataChange = new BehaviorSubject<any[]>([]);

    get data(): SubCategoryNode[] {
        return this.dataChange.value;
    }

    buildCategoryTree(data: CategoryData[], level: number): SubCategoryNode[] {
        return data?.map((x) => {
            const data = x.data;
            const children = x.children;
            const node = new SubCategoryNode();
            node.item = data.displayName;

            let { displayName, id, name, identifier, categoryId, parentId } = data;
            node.nodeValue = { displayName, id, name, identifier, categoryId, parentSubCategoryId: parentId };

            if (data) {
                if (children?.length > 0) {
                    node.children = this.buildCategoryTree(children, level + 1);
                } else {
                    node.item = data.displayName;
                    node.nodeValue = data;
                    let { displayName, id, name, identifier, categoryId, parentId } = data;
                    node.nodeValue = { displayName, id, name, identifier, categoryId, parentSubCategoryId: parentId };
                }
            }

            return node;
        });
    }
}