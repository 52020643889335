import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { CompanySurveyConfig } from './survey.feed.model';

export interface SurveyState extends EntityState<CompanySurveyConfig> {
}

export class FeedSurveyLoaded implements Action {
    readonly type = SurveyActionTypes.SurveyLoaded;
    constructor(public payload) { }
}

export class FeedSurveyUpdate implements Action {
    readonly type = SurveyActionTypes.SurveyUpdated;
    constructor(public payload) {
    }
}

export enum SurveyActionTypes {
    SurveyLoaded = "[FEED SURVEY CARD] loaded survey data",
    SurveyUpdated = "[FEED SURVEY CARD] updated survey"
}

export const adapter: EntityAdapter<CompanySurveyConfig> = createEntityAdapter<CompanySurveyConfig>({
    selectId: (survey: CompanySurveyConfig) => survey.type
});

const initialSurveyState = adapter.getInitialState({
});

export function surveyReducer(state = initialSurveyState, action): SurveyState {

    switch (action.type) {

        case SurveyActionTypes.SurveyLoaded:
            return adapter.addMany(action.payload, { ...state });

        case SurveyActionTypes.SurveyUpdated:
            const updateRecognition: Update<CompanySurveyConfig> = {
                id: action.payload.type,
                changes: action.payload
            };
            return adapter.updateOne(updateRecognition, { ...state })
        default:
            return { ...state };
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
