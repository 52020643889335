import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestHandleService } from './handler.request.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private requestHandleService: RequestHandleService) { }
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.requestHandleService.intercept(req, next);
  }
}
