import { NewFeedActionTypes } from './newsfeed.actions';
import UserBudget from "../../../modals/User.Budget";

export type UserBudgetState = {
    isLoaded: boolean;
    budget: UserBudget
    errorMessage?: string;
    lastRewardReceivedByUserName: string;
    lastRewardSentDaysAgo: number;
}

export const initialState: UserBudgetState = {
    isLoaded: false,
    budget: null,
    lastRewardReceivedByUserName: null,
    lastRewardSentDaysAgo: 0
}

export function userBudgetReducer(state: UserBudgetState = initialState, action): UserBudgetState {

    switch (action.type) {
        case NewFeedActionTypes.UserBudgetLoaded:
            return { budget: action.payload.userBudget, isLoaded: action.payload.isLoaded, lastRewardReceivedByUserName: action.payload.lastRewardReceivedByUserName, lastRewardSentDaysAgo: action.payload.lastRewardSentDaysAgo }

        case NewFeedActionTypes.UserBudgetResetCompleted:
            return { budget: action.payload.userBudget, isLoaded: true, lastRewardReceivedByUserName: action.payload.lastRewardReceivedByUserName, lastRewardSentDaysAgo: action.payload.lastRewardSentDaysAgo }

        case NewFeedActionTypes.UserBudgetLoadingError:
            return { ...state, isLoaded: false, errorMessage: action.payload.errorMessage }

        default:
            return state;
    }
}

export function newsFeedLastUpdatedAtReducer(state: string = null, action): any {
    switch (action.type) {

        case NewFeedActionTypes.NewsFeedLastUpdateTime:
            return action.payload.lastUpdatedAt;

        default:
            return state;
    }
}