import { Injectable } from '@angular/core';
const CoWorkerKey = 'cache-coworker-list';
const DateKey = 'cache-coworker-date';
import * as CryptoJS from 'crypto-js';
import { token } from 'projects/assets/constant/encrypt.token';

declare let setLocalStorageItem: any;
declare let getLocalStorageItem: any;
declare let removeLocalStorageItem: any;

@Injectable({ providedIn: 'root' })
export class CoworkerCacheService {
  private today: Date;
  constructor() {
    let date = new Date();
    this.today = new Date(date.getFullYear(), date.getMonth(), date.getDate())
  }
  public isCacheExpired(): boolean {
    let isExpired: boolean = true;
    try {
      let cachedDate = this.get(DateKey) && this.get(CoWorkerKey) ? new Date(this.get(DateKey)) : null;
      let currentDate = this.today;
      if (cachedDate && currentDate <= cachedDate) { isExpired = false; }
      else { isExpired = true; }
    }
    catch (e) { isExpired = true; }

    if (isExpired) { this.removeCoWorkerCachedData(); }
    return isExpired;
  }

  private saveCoWorkerDetails(mergedItems) {
    try {
      this.set(CoWorkerKey, mergedItems);
      if (!this.get(DateKey)) {
        this.set(DateKey, this.today);
      }
    }
    catch (e) {
      this.removeCoWorkerCachedData()
    }
  }

  public removeCoWorkerCachedData() {
    this.remove(CoWorkerKey);
    this.remove(DateKey);
  }

  public setCoWorkerDetails(data: People[]): void {
    let savedItems = this.getCoWorkerDetails();
    let mergedItems = savedItems.concat(data);
    mergedItems.forEach((element) => {
      if (element.guid) { element.userGuid = element.guid; }
      else { element.guid = element.userGuid; }
      element.guid = element.guid.toLowerCase();
      element.userGuid = element.userGuid.toLowerCase();
    });
    mergedItems = mergedItems.filter((object, index, self) => { return index == self.findIndex(element => element.guid == object.guid) });

    this.saveCoWorkerDetails(mergedItems);
  }

  public getCoWorkerDetails(keyword: string = '', includeGroup: boolean = true): People[] {
    let data: any[] = this.get(CoWorkerKey);
    if (!data) {
      data = [];
    }
    keyword = keyword?.toLowerCase()?.trim();

    if (includeGroup) {
      return keyword && data ? data.filter(x => x.firstName?.toLowerCase()?.startsWith(keyword) || x.lastName?.toLowerCase()?.startsWith(keyword) || (x?.firstName + ' ' + x?.lastName)?.toLowerCase()?.startsWith(keyword) || x.email?.toLowerCase()?.startsWith(keyword) || x.handle?.toLowerCase()?.startsWith(keyword)) : (data || []);
    } else {
      return keyword && data ? data.filter(x => (x.firstName?.toLowerCase()?.startsWith(keyword) || x.lastName?.toLowerCase()?.startsWith(keyword) || (x?.firstName + ' ' + x?.lastName)?.toLowerCase()?.startsWith(keyword) || x.email?.toLowerCase()?.startsWith(keyword) || x.handle?.toLowerCase()?.startsWith(keyword)) && (!x?.isGroup || x?.isGroup == false)) : (data || []);
    }
  }

  public removeCoworkerByEmailList(emailList: string[]) {
    emailList.forEach((value) => { value = value?.toLowerCase(); });
    let allCoworkersData = this.getCoWorkerDetails();
    allCoworkersData = allCoworkersData.filter((x) => { return (emailList.indexOf(x.email?.toLowerCase()) < 0) });
    this.remove(CoWorkerKey);
    this.set(CoWorkerKey, allCoworkersData);
  }

  public removeCoworkerByGuidList(guidList: string[]) {
    guidList.forEach((value) => { value = value?.toLowerCase(); });
    let allCoworkersData = this.getCoWorkerDetails();
    allCoworkersData = allCoworkersData.filter((x) => { return (guidList.indexOf(x.guid?.toLowerCase()) < 0) });
    this.remove(CoWorkerKey);
    this.set(CoWorkerKey, allCoworkersData);
  }

  private set(key: string, value: any) {
    setLocalStorageItem(key, this.encrypt(value));
  }

  private get(key: string,): any {
    return getLocalStorageItem(key) ? this.decrypt(getLocalStorageItem(key)) : "";
  }

  private remove(key) {
    removeLocalStorageItem(key);
  }

  private encrypt(value) {
    if (!value) return value;
    
    try {
      let encJson = CryptoJS.AES.encrypt(JSON.stringify(value), token).toString()
      let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))
      return encData
    } catch {
      return value;
    }
  }

  private decrypt(value) {
    if (!value) return value;
    
    try {
      let decData = CryptoJS.enc.Base64.parse(value).toString(CryptoJS.enc.Utf8)
      let bytes = CryptoJS.AES.decrypt(decData, token).toString(CryptoJS.enc.Utf8)
      return JSON.parse(bytes)
    } catch {
      return value;
    }
  }
}

interface People {
  firstName: string;
  lastName: string;
  guid: string;
  userGuid: string;
  email: string;
  isGroup: boolean;
  isEligibleForReward: boolean;
  isEligibleForRecognition: boolean;
  sendKinection?: boolean;
  handle: string;
}
