import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";
import { map } from "rxjs/operators";
import { DeliveryMode, InviteSource } from "../../modals/reward.someone";
import { CPEnvironmentType, ConfigurationTokenType } from "../../admin/developers/models";
import { RewardProgramType } from "../../reward-setting/container/reward-program/reward-program.model";
import { HttpHeaders } from "@angular/common/http";
import { ExternalAppType, SSOOptionType } from "../../modals/externalApp";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class AccountApiService {
  constructor(private configService: ConfigService) { }

  //#region multi line function
  updateSSO(formData) {
    const HttpUploadOptions = {
      headers: new HttpHeaders({ "Content-Type": "multipart/form-data" }),
    };
    return this.configService.post('api/v2/account/sso/system/add/update', formData, HttpUploadOptions);
  }
  validatePeoplePosterBulkUploadFile(file: File) {
    let formData = new FormData();
    formData.append("files", file, file.name);
    return this.configService.post(
      "api/v2/account/settings/roster/validate/bulk/user",
      formData,
      {}
    );
  }
  addNewPeopleByBulkInPeopleRoster(batchId) {
    let formData = new FormData();
    return this.configService.post(
      "api/v2/account/settings/roster/override/bulk/user?preventDelete=false&batchId=%r1".replace(
        "%r1",
        batchId
      ),
      formData,
      {}
    );
  } 

  impersonate(params) {
    params.grant_type = "password";
    params.accountIdentifier = params.accountIdentifier || "";
    return this.configService.post("api/v1/account/impersonate/validate", params);
  }

  validateSendBulkRewardsUploadedFile(file: File) {
    let formData = new FormData();
    formData.append("sendBulkRewards", file, file.name);
    return this.configService.post(
      "api/v2/account/bulk/invite/validate?DeliveryModeFlag=0",
      formData,
      {}
    );
  }
  sendBulkRewards(file) {
    let formData = new FormData();
    formData.append("sendBulkRewards", file, file.name);
    return this.configService.post(
      "api/v2/account/bulk/invite/upload?DeliveryModeFlag=0",
      formData,
      {}
    );
  }
  uploadMediaFiles =(files: File[] = []) =>  {
    let formData = new FormData();
    if (files.length) {
      files.forEach(file => { formData.append('file', file, file.name) });
    }
    return this.configService.post(`api/v2/account/media/upload`, formData, {}, null, true, 'events');
  }
  validateBulkRewardUploadFile =(file, isPdfRewardFlow: boolean, otp: string = null, accountIdentifier: string = null) =>  {
    let formData = new FormData();
    formData.append("file", file, file.name);
    return this.configService.post(`api/v2/account/bulk/invite/upload/review?isPdfRewardFlow=${isPdfRewardFlow}&accountIdentifier=${accountIdentifier}&otp=${otp}`, formData, {});
  }
  //#endregion

getAccountSpendReportMonthly = () => this.configService.get("api/v1/account/admin/report/url/monthly");
downloadAccountSpendReportMonthly = (params: object) => this.configService.get("api/v1/account/download/monthly/report/portal", params);
getAllCountries = () => this.configService.get('api/v1/account/all/countries');
sendContactUsMessage = (params) => this.configService.post("api/v1/account/feedback", params);
getRewardOccasions =(identifier: string = "", inviteSource: InviteSource = null) =>  this.configService.get(`api/v2/account/invite/occasions?identifier=${identifier}&inviteSource=${inviteSource}`);
markNotificationsAsReadUnread = (params) => this.configService.post("api/v2/account/notifications/mark/read/unread", params);
getReactionList = () => this.configService.get('api/v2/account/get/custom-reaction');
getUserNotificationSettings =() =>  this.configService.get("api/v2/account/user/notification/settings/get");
updateUserNotificationSettings = (payload) => this.configService.put(`api/v2/account/user/notification/setting/update`, payload);
getAccountNotificationSettings =() => this.configService.get(`api/v2/account/notification/settings/get`);
updateAccountNotificationSettings = (payload) => this.configService.put(`api/v2/account/notification/setting/update`, payload);
saveRewardOccasions = (params) => this.configService.post("api/v2/account/reward/update/occasion", params);
getCompanyTags = () => this.configService.get('api/v2/account/company/tags', null).pipe(map(x => x?.data ?? []));
getRemainingBudget = (count: number, rewardAmount: number, currencyCode: string, groupIdentifiers: string[], siteIdentifier: string) => this.configService.get(`api/v2/account/remaining/budget?count=${count}&rewardAmount=${rewardAmount}&currencyCode=${currencyCode}&groupIdentifiers=${groupIdentifiers}&siteIdentifier=${siteIdentifier}`, null).pipe(map(x => x?.data));
getGlobalSearchResult = () => this.configService.get('api/v2/account/features').pipe(map(x => x?.data));
accountLogoUpdate = (payload) => this.configService.post(`api/v2/account/logo/update`, payload)
getDeveloperSettings = (currentEnvironment: CPEnvironmentType) => this.configService.get(`api/v2/account/developer/setting?environmentType=${currentEnvironment}`);
getDeveloperToken = (tokenType: ConfigurationTokenType, environmentType: CPEnvironmentType) => this.configService.post(`api/v2/account/developer/setting/configure/token?configurationTokenType=${tokenType}&environmentType=${environmentType}`, {});
configureIpAddress = (payload) => this.configService.post(`api/v2/account/developer/setting/configure/ipAddress`, payload);
configureDomain = (payload) => this.configService.post(`api/v2/account/developer/setting/configure/domain`, payload);  
getJsLibraries = (env: string) => this.configService.get(`api/v2/account/js/library/list?environmentType=${env}`);  
getDeveloperWebhook= (currentEnvironment: CPEnvironmentType) => this.configService.get(`api/v2/account/developer/webhook?environmentType=${currentEnvironment}`);
configureWebhook =(payload) => this.configService.post(`api/v2/account/developer/webhook`,payload);
getUserMetaData = () => this.configService.get(`api/v2/user/user/metadata`);
getNotificationsList =() => this.configService.get("api/v2/account/admin/notifications/get");
getRewardTagList = () => this.configService.get("api/v2/account/b2btags/get");
getRewardSiteList = (params) => this.configService.get("api/v2/account/sites/get", params);
getRewardSiteDefaultMessage = (siteId, email) =>  this.configService.get("api/v1/account/site/" + encodeURIComponent(siteId) + "/defaultmessage" + "?email=" + encodeURIComponent(email));
getAccountRewardDefaultMessage = () => this.configService.get("api/v2/account/defaultmessage");
sendRewardRequest = (params) =>  this.configService.post("api/v1/account/invites/create", params);
removeCompanyTag = (params) => this.configService.post("api/v2/account/settings/invite/tag/delete", params);
editDescriptionCompanyTag = (params) => this.configService.post("api/v2/account/settings/company/tag/edit", params);
addNewCompanyTag =(params) => this.configService.post("api/v2/account/settings/company/tags/add", params);
getGroupMemberListByGroupIdentifier =(params, groupIdentifier) => this.configService.get("api/v2/account/group/{groupIdentifier}/group-members/get".replace('{groupIdentifier}', groupIdentifier), params);
getOnboardingDetails =() =>  this.configService.get('api/v2/account/onboarding/settings/get');
updateAccountOnboardingDetails =(payload) => this.configService.post('api/v2/account/onboarding/settings/update', payload);
getPreviewSiteList =(params) =>  this.configService.get('api/v2/account/product-preview/sites/get', params);
downloadBulkRewardUploadTemplate = (params: { deliveryMode: DeliveryMode, accountIdentifier: string } = null) =>this.configService.get(`api/v2/account/bulk/invite/template/get`, params);
getAutoCompleteRewardSiteList = (params: Object) =>  this.configService.get("api/v2/account/site/basic/info/get", params);
validateBulkRewardEditPeople = (params: Object, isPdfRewardFlow: boolean) => this.configService.post(`api/v2/account/bulk/invite/validate/verify?isPdfRewardFlow=${isPdfRewardFlow}`, params);
saveBulkRewardEditPeople = (params: Object, isPdfRewardFlow: boolean) =>  this.configService.post(`api/v2/account/bulk/invite/validate/update?isPdfRewardFlow=${isPdfRewardFlow}`, params);
sendBulkReward = (batchId, otp: string = null, accountIdentifier: string = null) => this.configService.post(`api/v2/account/bulk/invite/override/invite?batchId=${batchId}&otp=${otp}&accountIdentifier=${accountIdentifier}`, null);
removeBulkRewardValidate = (batchId) => this.configService.delete(`api/v2/account/bulk/invite/validate/${batchId}/delete`);
downloadBulkRewardReport = (params) => this.configService.get(`api/v2/account/bulk/invite/report/download`, params);
getGlobalPasswordResetInfo = ()  => this.configService.get('api/v2/account/password/reset/info');
setGlobalPasswordReset = () => this.configService.post('api/v2/account/global/password/reset', null);
createRecognitionReminder = (payload) => this.configService.post('api/v2/account/recognition/reminder/create', payload);
getRecognitionReminders = (params) => this.configService.get('api/v2/account/recognition/reminder', params);
getRecognitionReminderById = (id) => this.configService.get(`api/v2/account/recognition/reminder/${id}/get`);
updateRecognitionReminder = (identifier: string, payload) => this.configService.post(`api/v2/account/recognition/reminder/${identifier}/update`, payload);
deleteReminder = (identifierList: string[]) => this.configService.delete('api/v2/account/recognition/reminder/delete?identifierList=' + JSON.stringify(identifierList));
undoDeleteReminder = (identifier: string) => this.configService.post(`api/v2/account/recognition/reminder/${identifier}/delete/undo`, {});
getTimeZones = () => this.configService.get('api/v2/account/timezones');
updateSurveySetting = (payload) => this.configService.post('api/v2/account/custom-survey/configure', payload);
getCustomSurveyConfig = (type) => this.configService.get(`api/v2/account/custom-survey/get?Type=${type}`);
getDataForBudgetSet = (searchKey, isGroupSearch = false) => this.configService.get('api/v2/account/user-group/budget/info', { searchKey, isGroupSearch });
searchRewardProgram = (searchKey, identifier?: string) => this.configService.get('api/v2/account/reward/program/search', { searchKey, pageSize: 25, pageIndex: 0, identifier });
getApprovalsConfiguration = () => this.configService.get("api/v2/account/approval/configuration");
updateApprovalStatus = (params) => this.configService.post("api/v2/account/approval/configure", params);
getCountryByCurrency = (currencyIdentifier: string) => this.configService.get(`api/v2/account/currency/${currencyIdentifier}/data/get`);
getTags = (params) => this.configService.get("api/v2/account/settings/company/page/tags", params);
getSuggestedRewardData = (payload) => this.configService.post('api/v2/account/reward/suggest-amount', payload);
getRewardCategories = (payload) => this.configService.post(`api/v2/account/reward/categories`, payload);
getDemoAccountToken = () => this.configService.get(`api/v2/wallet-marketplace/demo/account/token/get`);
getAccountRewardSummary = (params) => this.configService.post(`api/v2/account/reward/catalog/summary`, params);
getApprovalDetails = () => this.configService.get(`api/v2/account/approval/additional/details`);
getAllAdminOfAccount = () => this.configService.get("api/v2/account/admins/accountId/get");
updateSurveyTransparencyMode = (transparencyMode) => this.configService.post(`api/v2/account/custom-survey/transparency-mode?transparencyMode=${transparencyMode}`, null);
getCustomSurveyTransparencyMode = () =>  this.configService.get(`api/v2/account/custom-survey/transparency-mode`);
updateRewardSetting = (enable) => this.configService.post(`api/v2/account/reward/settings?isRewardAllowed=${enable}`, {});
getRewardBudgetManager = () => this.configService.get('api/v2/account/reward/budget/manager/get');
getWalletBouquet = (identifier: string, params) => this.configService.get(`api/v2/account/reward/${identifier}/bouquet`, params);
getProductCatalogByCategoryId = (categoryIdentifier: string, params: object) => this.configService.post(`api/v2/account/reward/category/${categoryIdentifier}/catalog`, params);
getRewardProductDetails = (attrIdentifier: string, params) => this.configService.post(`api/v2/account/reward/product/${attrIdentifier}/details`, params);
getProductDataMatrixAdmin = (attrIdentifier: string, params: object) => this.configService.post(`api/v2/account/reward/product/${attrIdentifier}/matrix`, params);
getRewardPrograms = (params) => this.configService.get(`api/v2/account/reward/programs`, params);  
getProgramTypes = (programType: RewardProgramType) => this.configService.get(`api/v2/account/reward/program/${programType}/types`);  
createUpdateRewardProgram = (payload) => this.configService.post(`api/v2/account/reward/program`, payload);  
validateRewardProgram = () => this.configService.get(`api/v2/account/reward/program/validate`);  
cancelOnboardingAmazon = (payload) => this.configService.post(`api/v2/account/reward/program`, payload);  
getAdminDashboard = (params?: { identifier: string }) => this.configService.get("api/v2/account/admin/dashboard", params);  
updateRewardThreshold = (amount: number) => this.configService.post(`api/v2/account/reserve/threshold/update?amount=${amount}`, null);  
notifyUnregisteredUsers = () => this.configService.post(`api/v2/account/notify/unregistered/users`, null);  
downloadAdminDashBoardReports = (amount: number) => this.configService.post(`api/v2/account/reserve/admin/dashboard/reports/download`, null);  
getActiveRewardPrograms = (params: { pageIndex: number; pageSize: number, identifier?: string }) => this.configService.get(`api/v2/account/active/reward/programs/get`, params);  
getAccountRewardBudget = (params: { pageIndex: number; pageSize: number, isGroupData: boolean, identifier?: string }) => this.configService.get(`api/v2/account/reward/budget/info/get`, params);  
downloadAdminDashboardReport = (params) => this.configService.get("api/v2/account/admin/dashboard/reports/download", params);  
addAlternateEmailOfUser = (payload) => this.configService.post(`api/v2/account/alternate/email/verification`, payload);  
reOnboardOffboardedUser = (payload) => this.configService.post(`api/v2/account/settings/roster/reonboard/user`, JSON.stringify(payload));  
getCurrencies = () => this.configService.get(`api/v1/account/currencies/all`);  
getAccountConfigData = () => this.configService.get("api/v2/account/config");  
getAutoCompletePeopleRoster = (params) => this.configService.get("api/v2/account/user/autocomplete/get", params);  
getWalletRequestedFund = (params) => this.configService.get("api/v2/account/approval/wallet/invites", params);  
approveDenyFundRequest = (formData) => this.configService.post("api/v2/account/approval/wallet/invite", formData, {});  
getRewardProgramDetails = (siteIdentifier: string, accountIdentifier: string = null) => this.configService.get("api/v2/account/get/reward-program/categories", { siteIdentifier: siteIdentifier, accountIdentifier: accountIdentifier });  
getGifList = (params) => this.configService.get("api/v2/account/gifs", params);  
getProfile = (params) => this.configService.get("api/v2/account/admin/portal/user/profile/get", params);  
getRewardsGif = (params) => this.configService.get("api/v2/account/gif/library/get", params);  
getRewardSites = (params) => this.configService.get("api/v1/account/sites", params);
getRewardSiteDetails = (siteId) => this.configService.get("api/v1/account/sites/" + encodeURIComponent(siteId) + "/summary");  
getRequestedRewards = (params) => this.configService.get("api/v2/account/settings/people/invites/approval/get", params);  
downloadSendBulkRewardsTemplete = () => this.configService.get("api/v2/account/rewards/invite/bulkupload/template/get?flagInvitePdf=false", "", { "Content-Type": "application/json" }, "arraybuffer"); 
getUpcomingGifts = (params) => this.configService.get("api/v2/account/upcoming/gift/get", params);  
requestInvoiceAmount = (params, amount: number) => this.configService.post(`api/v1/account/invoice/request?amount=${amount}`, params);  
downloadThankyouNoteExcel = (params) => this.configService.get("api/v2/account/thank-you/notes/excel/download", params);  
requestedRewardsApprovalRequestUpdate = (params) => this.configService.post("api/v2/account/reward/multiple/invite/approval/request/update", params);  
sendApprovalReminderToApprover = (identifier) => this.configService.get('api/v2/account/send/approval/reminder?identifier=' + identifier);  
getHistoricRewards = (params) => this.configService.get("api/v2/account/invites/get", params);  
commonShare = (params) => this.configService.post("api/v2/account/common/share", params);  
downloadHistoricRewardsExcel = (params) => this.configService.get("api/v2/account/invites/historic/reward/excel/download", params);  
downloadUpcomingRewardsExcel = (params) => this.configService.get("api/v2/account/upcoming/gift/excel/download", params);  
updateEmailAndResendReward = (params) => this.configService.get("api/v1/account/invite/resend", params);  
sendReminderHistoricReward = (params) => this.configService.post("api/v2/account/send/rewards/reminder", params);  
updateUpcomingRewardMessage = (params) => this.configService.post("api/v2/account/upcoming/gift/customize/message", params);  
deleteUpcomingRewards = (params) => this.configService.post("api/v2/account/remove/upcoming/gift", params);  
getAccountInvoicesData = (params) => this.configService.get("api/v2/account/settings/company/account/invoices/get", params);  
downloadAccountReceiptsExcel = (params) => this.configService.post("api/v2/account/braintreeTransaction/excel/download", params, { "Content-Type": "application/json" });  
viewAccountingInvoice = (params) => this.configService.get("api/v1/account/settings/company/account/view/invoice", params);  
downloadAccountingInvoice = (params) => this.configService.get("api/v2/account/settings/account/download/invoice", params);  
getAccountReceiptsData = () => this.configService.get("api/v1/account/braintree/transactions/all/accounts");  
viewAccountingReceipt = (params) => this.configService.get("api/v1/account/settings/company/account/view/invoice", params);  
downloadAccountingReceipt = (params) => this.configService.post("api/v1/account/transaction/download", params);  
getAutoCompleteUsageDataAdmin = (params) => this.configService.get("api/v2/account/usage/admin/autocomplete/get", params);  
getAccountAdminList = (params) => this.configService.get("api/v2/account/usage/data/admin/autocomplete/get", params);  
getRewardOverTime = (params) => this.configService.get("api/v2/account/usage/data/rewards/over/time", params);  
getRewardsRedeemed = (params) => this.configService.get("api/v2/account/usage/data/reward/redeemed", params);  
getUsageDataTags = (params) => this.configService.get("api/v2/account/usage/data/tag", params);  
getTopStarRewardLeader = () => this.configService.get("api/v2/account/usage/data/top/star");  
downloadAdminUsageData = (params) => this.configService.get("api/v2/account/manager/usage/excel/download", params);  
getCoreValues = (params) => this.configService.get("api/v2/account/settings/company/page/core-values", params);  
removeCoreValues = (identifier) => this.configService.delete("api/v2/account/settings/company/core-value/%r1/delete".replace("%r1", identifier));  
editCoreValueDescription = (params) => this.configService.post("api/v2/account/settings/company/core-value/edit", params);  
addNewCoreValue = (params) => this.configService.post("api/v2/account/settings/company/core-values/add", params);  
getPeopleRosterEmployees = (params) => this.configService.get("api/v2/account/settings/roster/get/user", params);  
getPeopleRosterAdmins = (params) => this.configService.get("api/v2/account/settings/roster/get/admins", params);  
setPeopleRosterOffboardPeoples = (params) => this.configService.post("api/v2/account/settings/roster/delete/user/details", params);  
changePeopleRosterPeoplesPrivileges = (params) => this.configService.post("api/v2/account/settings/roster/change/privilege", params);  
editPeopleRosterAdminBudget = (params) => this.configService.post("api/v2/account/settings/roster/admin/budget/update", params);  
downloadPeopleRoster = (params) => this.configService.get("api/v2/account/settings/roster/download/employee/details", params);  
addNewPeopleByQuickInPeopleRoster = (params) => this.configService.post("api/v2/account/settings/roster/add/user", params);  
downloadPeopleRosterBulkUploadTemplate = () => this.configService.post("api/v2/account/settings/roster/bulk/upload/template/get", "");
getPeopleRosterPeopleTagsReceived = (params) => this.configService.get("api/v1/account/b2bemployee/tag/received", params);  
getPeopleRosterPeopleTagsSent = (params) => this.configService.get("api/v1/account/tag/sent", params);  
saveChangesOfPeopleRosterPeople = (params) => this.configService.post("api/v2/account/settings/roster/user/update", params);  
pauseUnpauseRewardsPeopleRosterPeople = (params) => this.configService.post("api/v2/account/settings/people/roster/pause/unpause", params);  
getThankyouNotes = (params) => this.configService.get("api/v2/account/thank-you-note/pagination/get", params);  
getThankyouNotesPdfString = (params) => this.configService.get("api/v2/account/thank-you/notes/Pdf/download", params);  
getUserManagerEmployees = (params) => this.getPeopleRosterEmployees(params);  
getUserManagerAdmins = (params) => this.getPeopleRosterAdmins(params);  
updateEmailAndResendRewardEmail = (identifier, email) => this.configService.post(`api/v2/account/invites/${identifier}/send?email=${email}`, {});  
getExternalApps = () => this.configService.get("api/v2/account/external/app/data/get");  
getSingleSignOnConfig = () => this.configService.get("api/v2/account/sso/system/data/get");  
deleteExternalApp = (externalApp) => this.configService.post("api/v2/account/delete/external/app", externalApp);  
getSlackConfiguration = () => this.configService.get("api/v2/account/slack/credentials/get");  
setSlackOAuthToken = (token) => this.configService.post(`api/v2/account/slack/token/${token}/incoming/webhook/save`, {});  
editEmployee = (obj) => this.configService.post("api/v2/account/roster/edit/user", obj);  
validateEmployee = (employeeObject) => this.configService.post("api/v2/account/roster/user/validate", employeeObject);  
saveValidateEmployee = (employeeObject) => this.configService.post("api/v2/account/roster/b2bemployee/validate/update", employeeObject);  
removeValidateEmployee = (batchId) => this.configService.delete(`api/v2/account/roster/b2bemployee/validate/${batchId}/delete`);  
isGroupsEnabled = (): Observable<any> => this.configService.get('api/v2/account/enable/groups');  
getAllGroups = (params) => this.configService.get("api/v2/account/groups/paged/data/get", params);  
getDepartments = (searchTerm) => this.configService.get(`api/v2/account/account/departments/autocomplete/get?searchKeyword=${searchTerm}`);  
getGroupLeaders = (searchTerm: string = "", identifier: string) => this.configService.get(`api/v2/account/groups/group-leader/autocomplete/get?searchKeyword=${searchTerm}&groupIdentifier=${identifier}`);
updateGroups = (payload) => this.configService.post("api/v2/account/groups/update", payload);  
checkGroupAliasValidity = (searchKeyword) => this.configService.get(`api/v2/account/group/alias/validity/check?searchKeyword=${searchKeyword}`);  
getPeopleProfileGroups = (identifier) => this.configService.get(`api/v2/account/group/details/get?userGuid=${identifier}`);  
getRewardSettings = () => this.configService.get("api/v2/account/notify/manager/account/get");  
setRewardSettings = (isRewardSettingOn: boolean) => this.configService.post(`api/v2/account/notify/manager/account/update?isRewardSettingOn=${isRewardSettingOn}`, {});  
getBusinessGoalData = (params) => this.configService.get("api/v2/account/business-goal", params);  
addBusinessGoalData = (payload) => this.configService.post("api/v2/account/business-goal/add", payload);  
updateBusinessGoal = (payload) => this.configService.post("api/v2/account/business-goal/update", payload);  
deleteBusinessGoal = (identifierList: string[]) => this.configService.delete(`api/v2/account/business-goal/delete?identifierList=${JSON.stringify(identifierList)}`);  
setRewardProgramBudget = (payload) => this.configService.post("api/v2/account/reward/program/budget/update", payload);  
setGroupBudget = (payload) => this.configService.post("api/v2/account/group/budget/update", payload);
downloadCertificate = (sSOProvider: SSOOptionType) => this.configService.get(`api/v2/account/sso/system/okta/certificate/get`, { sSOProvider });
getOrderedRewards = (params) => this.configService.get("api/v1/account/orders", params);
getOrderReportData = (params) => this.configService.get("api/v1/account/orders/excel/download", params);
getTokenData = () => this.configService.get("api/v1/account/channelpartner/token/get");
generateApiToken = (params) => this.configService.get("api/v1/account/channelpartner/create/token", params);
getWebhookData = (params) => this.configService.get("api/v1/account/webhook/get", params);
saveWebhookData = (params) => this.configService.post("api/v1/account/webhook/save", params);
getApiCallList = () => this.configService.get("api/v1/account/api/list");
getApiParameters = (params) => this.configService.get("api/v1/account/api/parameters", params);
submitApiRequest = (params) => this.configService.post("api/v1/account/submit/request", params);
tryToDeleteProductAttributes = (attributeGuid: string) => this.configService.post(`api/v2/admin/delete/attribute/${attributeGuid}/request`, {});
connectRipplingHRToAccount = (params) => this.configService.post("api/v1/account/rippling/connect", params);
microsoftTeamsSelfIntegration = (request) => this.configService.post("api/v2/account/microsoft-teams/self/integration", request);
initializeGustoHRConnect = () => this.configService.get("api/v2/account/gusto/connect/initialize");
connectGustoHRToAccount = (code) => this.configService.post(`api/v2/account/gusto/connect/setup?code=${code}`, null);
disconnectGustoHr = () => this.configService.delete("api/v2/account/gusto/disconnect");
getUserBudget = () => this.configService.get("api/v2/account/admins/data/get");
downloadSentRewardPDF = (params) => this.configService.post("api/v1/account/invitepdf", params);
userEventTracking = (params) => this.configService.post("api/v2/account/external/analytics", params);
verifyRecoveryEmailByToken = (params) => this.configService.post("api/v1/user/alternate/email/verify/token", params);
initializeBambooHRConnect = (params) => this.configService.get("api/v2/account/bamboohr/connect/initialize", params);
connectBambooHRToAccount = (code: string, subDomain: string) => this.configService.post(`api/v2/account/bamboohr/connect/setup?code=${code}&subDomain=${subDomain}`, null);
disconnectBambooHr = () => this.configService.delete("api/v2/account/bamboohr/disconnect");
reactivateAccountOtpVerify = (payload) => this.configService.post(`api/v1/user/reactive/user/account/otp/verify`, payload);
scimConnect = () => this.configService.get("api/v2/account/scim/token");
toggleAutomatedNotification = (externalAppType: ExternalAppType) => this.configService.put(`api/v2/account/toggle/automated/notifications?externalAppType=${externalAppType}`, {});
checkOnboardingTokenExpiry = (params) => this.configService.post("api/v2/account/check/token/validity", params);
getQrCodeForLogin = (params) => this.configService.post("api/v2/account/tfa/login/get/qrcode", params);
sendLoginOtpToPhoneNumber = (params) => this.configService.post("api/v2/account/tfa/login/sms/activate", params);
addSecurityKeyLogin = (params) => this.configService.post("api/v2/account/tfa/login/add/securitykey", params);
addPhoneNumberForTfaLogin = (params) => this.configService.post("api/v2/account/tfa/login/add/phone", params);
sendOtpSmsForLogin = (params) => this.configService.post("api/v1/account/tfa/send/login/sms", params);
verifyUserPassword = (params) => this.configService.post("api/v2/account/tfa/password/confirm", params);
getCurrentStatusOf2FAOfUser = () => this.configService.get("api/v2/account/tfa/status");
getSecurityKeyQrCode = (params) => this.configService.post("api/v2/account/tfa/get/qrcode", params);
addRemoveSecurityKey = (params) => this.configService.post("api/v2/account/tfa/add/securitykey", params);
sendOtpToPhoneNumber = (params) => this.configService.post("api/v2/account/tfa/sms/activate", params);
oneNumberForTwoFactorAuthentication = (params) => this.configService.post("api/v2/account/tfa/add/phone", params);
validatePasswordOnBoarding = (params) => this.configService.post("api/v1/user/validate/password", params);
deactivateTwoFactorAuthentication = (params) => this.configService.post("api/v2/account/tfa/deactivate", params);
getCustomRewards = (params) => this.configService.get("api/v2/account/product/custom", params);
getRedeemedCustomRewards = (params) => this.configService.get("api/v2/account/custom/redeemed/reward/get", params);
sendRewardReminders = (rewardIds: string[]) => this.configService.post("api/v2/account/custom/reward/redemption/reminder/email/resend", rewardIds);
getDefaultFormJsonOfCustomReward = () => this.configService.get("api/v2/account/product/custom/default-booking-form");
createUpdateCustomReward = (params) => this.configService.post("api/v2/account/product/custom", params);
deleteCustomReward = (identifier: string) => this.configService.delete("api/v2/account/product/custom/" + identifier);
getApprovalDetailsWithParams = (params) => this.configService.get(`api/v2/account/approval/additional/details`, params);
oktaLogin = (email) => this.configService.post("api/v1/account/sso/login", JSON.stringify(email));
oktaLoginValidate = (requestParams) => this.configService.get("api/v1/account/validate", requestParams);
emailResetPasswordLinkForLoggedInUser = () => this.configService.post("api/v2/account/emailresetpasswordlink", null);
getUserCurrencyCode = (params: { identifier: string }) => this.configService.get("api/v2/account/get/user/currency", params);
getUserRewards = (params) => this.configService.get("api/v2/account/rewards", params);
createDirectGiftCardOrder = (params) => this.configService.post("api/v1/account/order/create", params);
getRewardManagementData = (params) => this.configService.get("api/v1/account/invites", params);
getRewardDetails = (params) => this.configService.get(`api/v1/account/site/tokens/replace/${params.identifier}/${params.rewardManagementDetailShow}?overrideInvite=${params.overrideInvite}`);
downloadInvitePdf = (params: { identifiers: string[], skipRevoked: boolean }) => this.configService.post(`api/v1/account/invitepdf?skipRevoked=${params.skipRevoked}`, params.identifiers);
getAccountReports = (accountId: number) => this.configService.get(`api/v1/account/report/url/monthly`, { accountId });
downloadInvoicePdf = (invoiceId: number) => this.configService.get(`api/v1/account/settings/company/account/download/invoice?invoiceId=${invoiceId}`);
viewInvoicePdf = (invoiceId: number) => this.configService.get(`api/v1/account/settings/company/account/view/invoice?invoiceId=${invoiceId}`);
revokeOrVoidInvite = (params) => this.configService.post(`api/v1/account/invites/${params.identifier}/revoke/${params.revokeInvite}/${params.voidInvite}?memo=${params.message}`, {});
resendInvite = (params) => this.configService.post(`api/v1/account/invites/${params.identifier}/send?userName=${params.email}`, {});
reOpenInvite = (params) => this.configService.get(`api/v1/account/invite/reopen?identifier=${params.identifier}&reasonToDeleteBooking=${params.message}`);
downloadRewardReport = (params) => this.configService.post(`api/v1/account/alltransaction/invites/excel/download?filters=${params.filters}`, {});  
getRewardSitesV1 = (params) => this.configService.get(`api/v1/account/sites`, params);
getAccountOptionalTags = () => this.configService.get(`api/v1/account/b2btags`);
getSiteSummary = (siteId: number) => this.configService.get(`api/v1/account/sites/${siteId}/summary`);
getB2bEmployee = (params) => this.configService.get(`api/v1/account/b2bemployee/id`, params);
downloadMonthlyReport = (id: number) => this.configService.get(`api/v1/account/download/monthly/report/portal?accountMonthlyReportId=${id}`);
getAllCurrencies = () => this.configService.get(`api/v1/account/currencies/all`);
getSitesData = (params) => this.configService.get("api/v1/account/site/data", params);
getPersonalizedReward = (pageIndex: number ,pageSize: number) => this.configService.get(`api/v2/user/my/rewards/personalized/collection`, {pageSize, pageIndex});
getMyRewardBannerData = (payload) => this.configService.post("api/v2/wallet-marketplace/collections", payload);
getUserDetails = (userGuid: string, isVendorUser: boolean) => this.configService.get(`api/v2/account/settings/roster/get/user/details/${userGuid}`, { isVendorUser });
}