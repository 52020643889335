import { Injectable } from "@angular/core";
import { UserRole } from '../../modals/keys/variable.keys';
import User from "../../modals/user";

@Injectable({ providedIn: 'root' })
export class UserRoleService {
  private user: User;
  constructor() {
  }

  public getUserRoleFromStore(roles: string[] = []): string {
    let userRole = UserRole.None
    {
      if (roles.indexOf(UserRole.Administrator) > -1) {
        userRole = UserRole.Administrator;
      } else if (roles.indexOf(UserRole.FinanceAdministrator) > -1) {
        userRole = UserRole.FinanceAdministrator;
      }
      else if (roles.indexOf(UserRole.ProgramManager) > -1) {
        userRole = UserRole.ProgramAdmin;
      }
      else if (roles.indexOf(UserRole.Accountant) > -1) {
        userRole = UserRole.Accountant;
      }
      else if (roles.indexOf(UserRole.RewardSender) > -1) {
        userRole = UserRole.RewardSender;
      }
      else if (roles.indexOf(UserRole.EmployeeLogin) > -1) {
        userRole = UserRole.Employee;
      }
      else if (roles.indexOf(UserRole.WalletUser) > -1) {
        userRole = UserRole.WalletUser;
      }
    }
    return userRole;
  }
}
