import { Component, ViewEncapsulation, Injectable, OnInit, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class LayoutDialogService {
    private recoveryEmail: BehaviorSubject<{ value: boolean, logout: boolean }> = new BehaviorSubject(null);

    public initLayoutDialogService() {
        this.recoveryEmail = new BehaviorSubject(null);
    }

    public setRecoveryEmailDialogValue(values: { value: boolean, logout: boolean }) {
        this.recoveryEmail.next(values);
    }

    public getRecoveryEmailDialogValue(): Observable<{ value: boolean, logout: boolean }> {
        return this.recoveryEmail.asObservable();
    }
}
