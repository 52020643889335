import { createSelector } from '@ngrx/store';
import { AppState } from "../../../store/reducers";


export const selectUserBudgetState = (state: AppState) => state.userBudget;
export const lastUpdateDateTimeState = (state: AppState) => state.lastUpdatedAtNewsFeed;

export const isUserBudgetLoaded = createSelector(
    selectUserBudgetState,
    states => states.isLoaded
)

export const selectUserBudget = createSelector(
    selectUserBudgetState,
    states => states.budget
)

export const lastUpdateDateTime = createSelector(
    lastUpdateDateTimeState,
    dateTime => dateTime
)