import { AppState } from '../../store/reducers/index';
import { Store } from '@ngrx/store';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core"
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { ActivityActionTypes, RecentActivityRequested, RecentActivityLoaded, RecentActivityLoadingError } from './notification.action';
import { of } from 'rxjs';
import { RecognitionApiService } from '../../providers/api.service/recognition.api.service';

@Injectable()
export class RecentActivityEffects {
  private existingPayload: { unseenCount: number, hasPreviousNotification: boolean };

  constructor(private actions$: Actions, private store: Store<AppState>, private _recognitionApiService: RecognitionApiService) { }

  
  loadActivity$ = createEffect(() => this.actions$
    .pipe(
      ofType<RecentActivityRequested>(ActivityActionTypes.RECENT_ACTIVITY_REQUESTED),
      mergeMap((action) => {
        this.existingPayload = action.existingPayload;
        return this._recognitionApiService.getNotificationsList(action.payload.query).pipe(
          catchError(error => {
            this.store.dispatch(new RecentActivityLoadingError({ errorMessage: error.message }));
            return of({
              data: []
            });
          }),
        )
      }),
      map(response => {
        let { data } = response;
        return new RecentActivityLoaded({ recentActivities: data?.data ? data.data : [], totalCount: data?.totalCount, hasNextPage: data?.hasNextPage, unseenCount: this.existingPayload.unseenCount, hasPreviousNotification: this.existingPayload.hasPreviousNotification })
      }),
    ))
}

