import { Action } from '@ngrx/store';
import { RecognitionTag } from '../../../../modals/recogntion.tags';

export enum FilteringActionType {
  PopularSearchRequested = "[FILTERING] popular search requested",
  PopularSearchLoaded = "[FILTERING] popular search loaded",
  PopularSearchLoadingError = "[FILTERING] popular search error",
  TagSearchRequested = "[FILTERING] tag search requested",
  TagSearchCompleted = "[FILTERING] tag search completed"
}

export class PopularSearchRequested implements Action {
  type: string = FilteringActionType.PopularSearchRequested;
  constructor() { }
}

export class PopularSearchLoaded implements Action {
  type: string = FilteringActionType.PopularSearchLoaded;
  constructor(public payload: { data: RecognitionTag[] }) { }
}

export class PopularSearchLoadingError implements Action {
  type: string = FilteringActionType.PopularSearchLoadingError;
  constructor(public errorMessage: string) { }
}

export class TagSearchRequested implements Action {
  type: string = FilteringActionType.TagSearchRequested;
  constructor(public payload: { keyword: string, onlyTags: boolean }) { }
}

export class TagSearchLoaded implements Action {
  type: string = FilteringActionType.TagSearchCompleted;
  constructor(public payload: { data: RecognitionTag[] }) { }
}

export type RecognitionFilteringActions = PopularSearchRequested | PopularSearchLoaded | PopularSearchLoadingError | TagSearchRequested | TagSearchLoaded;
