import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })

export class ConfigService {
  private apiUrl = environment.apiUrl;
  public cdnUrl = 'https://static.enjoywishlist.com/';

  constructor(private http: HttpClient) { }

  public get(url: string, params?: any, headers: any = { 'Content-Type': 'application/json' }, responseType: any = 'json', reportProgress: boolean = false, observe: any = 'body', baseUrl: string = this.apiUrl): Observable<any> {
    let hasParams = false;
    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach(function (key) {
        httpParams = httpParams.set(key, params[key]);
        hasParams = true;
      });
    }
    return this.http
      .get(baseUrl + url, {
        params: hasParams ? httpParams : null,
        headers: headers,
        responseType: responseType,
        reportProgress: reportProgress,
        observe: observe,
        withCredentials: true
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public post(url: string, body: any, headers: any = { 'Content-Type': 'application/json' }, responseType: any = 'json', reportProgress: boolean = false, observe: any = 'body', baseUrl: string = this.apiUrl): Observable<any> {
    return this.http
      .post(baseUrl + url, body, {
        headers: headers,
        responseType: responseType,
        reportProgress: reportProgress,
        observe: observe,
        withCredentials: true
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public put(url: string, body: any, headers: any = { 'Content-Type': 'application/json' }, responseType: any = 'json', reportProgress: boolean = false, observe: any = 'body', baseUrl: string = this.apiUrl): Observable<any> {
    return this.http
      .put(baseUrl + url, body, {
        headers: headers,
        responseType: responseType,
        reportProgress: reportProgress,
        observe: observe,
        withCredentials: true
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public delete(url: string, params?: any, headers: any = { 'Content-Type': 'application/json' }, responseType: any = 'json', reportProgress: boolean = false, observe: any = 'body', baseUrl: string = this.apiUrl): Observable<any> {
    let hasParams = false;
    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach(function (key) {
        httpParams = httpParams.set(key, params[key]);
        hasParams = true;
      });
    }
    return this.http
      .delete(baseUrl + url, {
        params: hasParams ? httpParams : null,
        headers: headers,
        responseType: responseType,
        reportProgress: reportProgress,
        observe: observe,
        withCredentials: true
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  public patch(url: string, body: any, params?: any, headers: any = { 'Content-Type': 'application/json' }, responseType: any = 'json', reportProgress: boolean = false, observe: any = 'body', baseUrl: string = this.apiUrl): Observable<any> {
    let hasParams = false;
    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach(function (key) {
        httpParams = httpParams.set(key, params[key]);
        hasParams = true;
      });
    }
    return this.http
      .patch(baseUrl + url, body, {
        params: hasParams ? httpParams : null,
        headers: headers,
        responseType: responseType,
        reportProgress: reportProgress,
        observe: observe,
        withCredentials: true
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
}
