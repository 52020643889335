import { Action } from '@ngrx/store';
import { Recognition } from '../../../modals/recognition';

export class RecognitionRenderNormalQueryParams {
  pageIndex: number;
  pageSize: number;
}

export enum RecognitionRenderNormalActionTypes {
  RecognitionRenderNormalRequested = "[Recognition_Render_Normal] recognition render requested",
  RecognitionRenderNormalLoaded = "[Recognition_Render_Normal] recognition render loaded",
  RecognitionRenderNormalUpdated = "[Recognition_Render_Normal]  recognition render updated",
  RecognitionRenderNormalDeleted = "[Recognition_Render_Normal] recognition render deleted",
  RecognitionRenderNormalError = "[Recognition_Render_Normal] recognition render loading error",
  RecognitionRenderNormalResetRequested = "[Recognition_Render_Normal] recognition render reset requested",
  RecognitionRenderNormalResetCompleted = "[Recognition_Render_Normal] recognition render reset Completed",
}

export class RecognitionRenderNormalRequested implements Action {
  type = RecognitionRenderNormalActionTypes.RecognitionRenderNormalRequested;
  constructor(public payload: { query: RecognitionRenderNormalQueryParams, loading: boolean }) {
  }
}

export class RecognitionRenderNormalLoaded implements Action {
  type = RecognitionRenderNormalActionTypes.RecognitionRenderNormalLoaded;
  constructor(public payload: { data: Recognition[], hasNextPage: boolean, loading: boolean }) {
  }
}

export class RecognitionRenderNormalLoadingError implements Action {
  type = RecognitionRenderNormalActionTypes.RecognitionRenderNormalError;
  constructor(public payload: { errorMessage?: string }) {
  }
}

export class RecognitionRenderNormalUpdated implements Action {
  type = RecognitionRenderNormalActionTypes.RecognitionRenderNormalUpdated;
  constructor(public payload: { recognitionId: string, recognition: Recognition, hasNextPage: boolean }) {
  }
}

export class RecognitionRenderNormalResetRequested implements Action {
  type = RecognitionRenderNormalActionTypes.RecognitionRenderNormalResetRequested;
  constructor() { }
}

export class RecognitionRenderNormalResetCompleted implements Action {
  type = RecognitionRenderNormalActionTypes.RecognitionRenderNormalResetCompleted;
  constructor(public payload: { data: Recognition[], hasNextPage: boolean, loading: boolean }) {
  }
}

export class RecognitionRenderNormalDeleted implements Action {
  type = RecognitionRenderNormalActionTypes.RecognitionRenderNormalDeleted;
  constructor(public payload: { recognitionId: string, hasNextPage: boolean }) {
  }
}

export type RecognitionRenderNormalActions = RecognitionRenderNormalRequested | RecognitionRenderNormalLoaded | RecognitionRenderNormalLoadingError | RecognitionRenderNormalUpdated | RecognitionRenderNormalDeleted;
