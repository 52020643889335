<div class="component">
  <mat-icon class="close-dialog material-icons-round" (click)="closeDialog()">close</mat-icon>
  <div class="dialog-content">
    <div class="dialog-header">
      <span>Are you sure?</span>
    </div>
    <div class="dialog-text">
      <span>You want to disconnect {{hrSystemName}} with Wishlist?</span>
    </div>
    <form #requestInvoiceForm="ngForm">
      <div class="dialog-action">
        <button mat-button (click)="closeDialog()"
          class="secondary-btn all-button transform-none">Cancel</button>
        <button mat-button (click)="disconnect()" [disabled]="processing"
          class="primary-btn filtered-button transform-none">Confirm</button>
      </div>
    </form>
  </div>
</div>
