import { AppState } from './index';
import { ActionReducer, INIT } from '@ngrx/store';
import { UserActions } from '../actions/index';

export function logoutReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
    return (state, action) => {
        if (action != null && action.type === UserActions.logout.type) {
            return reducer(undefined, { type: INIT });
        }
        return reducer(state, action);
    };
}