import { Injectable } from "@angular/core";
import { InfoApiService } from "../api.service";
import { map, catchError } from "rxjs/operators";
import { permissionErrorMessage } from "../../modals/keys/error.key";
import { throwError } from "rxjs";
import { StoreService } from "../../store/store.service";
import { set_wallet_config } from "../../store/actions/account.action";
import { CookieStorageService } from "../cookiestorage/cookieStorage.service";

@Injectable({ providedIn: 'root' })
export class WalletConfigService {
    constructor(private apiService: InfoApiService, private storeService: StoreService, private _cookieStorageService: CookieStorageService) {
    }

    public getWalletUserConfigDetails() {
        return this.apiService.getWalletUserConfigData().pipe(
            map((response) => {
                this.setAccountPermission(response?.data);
                return response?.data;
            }),
            catchError((error) => {
                if (!(error.status && (error.status == 403 || error.status == 401))) {
                    error.message = permissionErrorMessage;
                }
                return throwError(error);
            })
        );
    }

    private setAccountPermission(response) {
        let { walletBalance, currencyCode } = response;
        this.storeService.getStore().dispatch(set_wallet_config({ walletBalance, currencyCode }));
    }
}