import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })

export class EncodeDecodeService {

  public encodeValue(value: {}): string {
    try { return window.btoa(window.unescape(encodeURIComponent(JSON.stringify(value)))); }
    catch { return null; }
  }

  public decodeValue(value: string): any {
    try { return JSON.parse(decodeURIComponent(window.escape(window.atob(value)))); }
    catch { return null; }
  }
}