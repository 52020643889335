import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class FilterDataService {
  private isFilterEnabled: Subject<boolean>;

  constructor() {
    this.isFilterEnabled = new Subject();
  }

  public setIsFilterEnabled(val: boolean = true): void {
    this.isFilterEnabled.next(val);
  }

  public getIsFilterEnabled(): Observable<boolean> {
    return this.isFilterEnabled.asObservable();
  }
}
