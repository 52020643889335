import { QueryParams } from './../../modals/QueryParams';
import { Action } from '@ngrx/store';
import { Reward } from '../../modals/User.Reward';

export enum UserRewardActionTypes {
    RewardRequested = "[USER_REWARD] user reward requested",
    RewardLoaded = "[USER_REWARD] user reward loaded",
    RewardLoading = "[USER_REWARD] user reward loaded",
    RewardResetRequested = "[USER_REWARD] user reward reset requested",
    RewardResetCompleted = "[USER_REWARD] user reward reset completed",
    RewardLoadingError = "[USER_REWARD] user reward loading error",
    isRewardAllowedRequested = "[USER_REWARD] reward allowed requested",
    isRewardAllowedLoaded = "[USER_REWARD] reward allowed loaded",
}

export class UserRewardRequested implements Action {
    readonly type = UserRewardActionTypes.RewardRequested;
    constructor(public payload: { queryParams: QueryParams }) { }
}

export class UserRewardLoaded implements Action {
    readonly type = UserRewardActionTypes.RewardLoaded;
    constructor(public payload: { userReward: Reward, hasNextPage: boolean, isLoaded: boolean }) {
    }
}

export class UserRewardResetRequested implements Action {
    readonly type = UserRewardActionTypes.RewardResetRequested;
    constructor() { }
}

export class UserRewardResetCompleted implements Action {
    readonly type = UserRewardActionTypes.RewardResetCompleted;
    constructor(public payload: { userReward: Reward, hasNextPage: boolean }) {
    }
}

export class UserRewardLoading implements Action {
    readonly type = UserRewardActionTypes.RewardLoading;
    constructor(public payload: { isLoading: boolean }) { }
}
export class UserRewardLoadingError implements Action {
    readonly type = UserRewardActionTypes.RewardLoadingError;
    constructor(public payload: { errorMessage: string }) { }
}
export class RewardAllowedRequested implements Action {
    readonly type = UserRewardActionTypes.isRewardAllowedRequested;
    constructor() { }
}
export class RewardAllowedLoaded implements Action {
    readonly type = UserRewardActionTypes.isRewardAllowedLoaded;
    constructor() { }
}

export type NewFeedActions = UserRewardRequested | UserRewardLoaded | UserRewardLoading;
