export class AzureAnalyticsDto {
  identifier: string;
  type: AzurePlatformType;
  eventName: AzureProductTrackingEvent;
  productAttrGuid: string;
  productGuid: string;
  userId: number;
  statusCodes: number;
  errorMessage: string;
  inviteGuid: string;
  eventStartTimeUtc: string;
  eventEndTimeUtc: string;
  rewardType: AzureCategoryType;
  productName: string;
  capacityType: string;
  capacityValue: number;
}

export enum AzureCategoryType {
  Experience = 'Experience',
  GiftCard = 'GiftCard',
  LiveEvent = 'LiveEvent',
  Travel = 'Travel',
  Custom = 'Custom',
  Swag = 'Swag',
  Viator = 'Viator',
  ViatorV2 = 'ViatorV2',
  AutoCode = 'AutoCode',
  FareHarbor = 'FareHarbor',
  HotelEngine = 'HotelEngine',
  Hotel = 'Hotel',
  DreamshipV2 = "DreamshipV2",
  Physical = "Physical"
}

export enum AzurePlatformType {
  WalletMarketPlace,
  MarketPlace
}

export enum AzureStatusCodes {
  Ok = 200,
  BadRequest = 400
}

export enum AzureProductTrackingEvent {
  ProductDetailPage,
  ProductAvailability,
  ProductHold,
  ProductPayment,
  ProductBooking,
}