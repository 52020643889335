import { createReducer, on } from "@ngrx/store";
import { update_insights_filters } from "../actions/insight-dashboard-filter.action";

export interface IInsightDashboardFilter {
    filters: IFilters;
}
interface IFilters {
    groupIdentifiers: string[],
    location: string[] | string,
    roles: string[],
    startDate: string,
    endDate: string,
    isFilterApplied: boolean
}
export const initialInsightsDashboardFilters: IInsightDashboardFilter = {
    filters: null,
}

const _insightsDashboardFilterReducer = createReducer(
    initialInsightsDashboardFilters,
    on(update_insights_filters, (state, props) => ({ ...state, ...props })),
);

export function insightDashboardFilterReducer(state, action) {
    return _insightsDashboardFilterReducer(state, action);
}
