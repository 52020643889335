import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { ConfigService } from "./config.service";

@Injectable({ providedIn: "root" })
export class WalletApiService {
  constructor(private configService: ConfigService) { }
  getUserOrderedRewards(params) {
    return this.configService.get('api/v2/wallet-marketplace/orders', params);
  }
  getUserOrderedRewardDetails(identifier) {
    return this.configService.get('api/v2/wallet-marketplace/order/' + identifier);
  }
  emailOrderLinkFromOrderStatus(identifier) {
    return this.configService.post(`api/v2/wallet-marketplace/order/${identifier}/email/send`, null);
  }
  isOrderCancellable(identifier: string) {
    return this.configService.get(`api/v2/wallet-marketplace/cancel/order/${identifier}/check`);
  }
  cancelOrderedRewardBooking(identifier, reasonCode) {
    return this.configService.post(`api/v2/wallet-marketplace/cancel/order/${identifier}?bookingCancellationData=${reasonCode}`, null);
  }
  contactSupportForUserOrder(identifier, userInformation) {
    return this.configService.post(`api/v2/wallet-marketplace/raise/ticket/order/${identifier}?userInformation=${userInformation}`, null);
  }
  initRecoveryEmailAddProcess(email) {
    return this.configService.post(`api/v2/wallet-marketplace/alternate/email/${email}/verification/process/initiate`, null);
  }
  addGiftCardToWallet(identifier: string, isAutoAddRequest: boolean = false) {
    return this.configService.post(`api/v2/wallet-marketplace/gift/card/invite/${identifier}/add?isAutoAddRequest=${isAutoAddRequest}`, null);
  }
  makeOpenBookingPayment(orderIdentifier, params) {
    return this.configService.post(`api/v2/wallet-marketplace/open/booking/order/${orderIdentifier}/payment`, params);
  }
  getCurrencyList() {
    return this.configService.get(`api/v2/wallet-marketplace/wallet/currency/list/get`);
  }
  updateUserCurrency(currencyGuid) {
    return this.configService.post(`api/v2/wallet-marketplace/wallet/currency/update`, JSON.stringify(currencyGuid));
  }
  getMarketplaceConfig(params: any | object = null, currentLocation = {}) {
    return this.configService.post(`api/v2/wallet-marketplace/config?inviteIdentifier=${params?.inviteIdentifier ?? ''}&inviteChallenge=${params?.inviteChallenge ?? ''}`, currentLocation);
  }
  getProductCategoriesFilter(categoryIdentifier: string, params) {
    return this.configService.get(`api/v2/wallet-marketplace/category/${categoryIdentifier}/filters/get`, params)
  }
  getCatalogSummary(params) {
    return this.configService.get(`api/v2/wallet-marketplace/category/catalog/summary`, params);
  }
  getFavoriteProducts(params) {
    return this.configService.get('api/v2/wallet-marketplace/favourite/product/get', params)
  }
  addFavoriteProduct(productAttributeIdentifier) {
    return this.configService.post(`api/v2/wallet-marketplace/favourite/product/${productAttributeIdentifier}/add`, null)
  }
  removeFavoriteProduct(productAttributeIdentifier) {
    return this.configService.delete(`api/v2/wallet-marketplace/favourite/product/${productAttributeIdentifier}/delete`, null)
  }
  getProductCatalog(categoryIdentifier: string, params: object) {
    return this.configService.get(`api/v2/wallet-marketplace/category/${categoryIdentifier}/catalog/get`, params)
  }
  getProductDetails(identifier: string, params: object) {
    return this.configService.get(`api/v2/wallet-marketplace/product/${identifier}/details/get`, params);
  }
  getProductDataMatrix(productAttributeId: string, params: object) {
    return this.configService.get(`api/v2/wallet-marketplace/product/${productAttributeId}/matrix/get`, params);
  }
  getProductAttributeAvailability(productAttributeId: string, params: object) {
    return this.configService.post(`api/v2/wallet-marketplace/product/${productAttributeId}/availability/get`, params, params, null, null, 'response');
  }
  getHoldAvailabilityOfProductAttribute(productAttributeId: string, params: object) {
    return this.configService.post(`api/v2/wallet-marketplace/product/${productAttributeId}/booking/hold`, params, null, null, null, 'response');
  }
  getTravelPackageList(categoryIdentifier: string, params: object) {
    return this.getProductCatalog(categoryIdentifier, params);
  }
  getBraintreePaymentToken() {
    return this.configService.get('api/v2/catalog/paymenttoken');
  }
  createProductBookingOrder(inviteIdentifier: string, inviteChallenge: string, params: object, files: File[]) {
    let formData = new FormData();
    if (files?.length > 0)
      files.forEach((file) => { formData.append('files', file, file.name); });
    formData.append('request', JSON.stringify(params))

    return this.configService.post(`api/v2/wallet-marketplace/booking/create?inviteIdentifier=${inviteIdentifier}&inviteChallenge=${inviteChallenge}`, formData, {});
  }
  getBookingSurveyValues(identifier: string) {
    return this.configService.get(`api/v2/wallet-marketplace/order/${identifier}/survey/data/get`);
  }
  submitBookingSurveyValues(identifier: string, params: object) {
    return this.configService.post(`api/v2/wallet-marketplace/order/${identifier}/survey/submit`, params);
  }
  getViatorProductReviews(attrIdentifier: string, params) {
    return this.configService.get(`api/v2/wallet-marketplace/product/${attrIdentifier}/reviews`, params);
  }
  searchMarketplace = (params) => this.configService.get(`api/v2/wallet-marketplace/keyword/search`, params);

  getCategoriesWithSubCat = () => this.configService.get(`api/v2/wallet-marketplace/subcategories/get`);

  getSubcategoriesByMpRequest = (params) => this.configService.get(`api/v2/wallet-marketplace/subcategories`, params);

  getSuggestions = (params) => this.configService.get(`api/v2/wallet-marketplace/suggestions/get`, params);
  
  getMpCatalog = (params) => this.configService.post("api/v2/wallet-marketplace/catalog", params);

  getMpCatalogWithHttpResponse = (params) => this.configService.post("api/v2/wallet-marketplace/catalog", params, { 'Content-Type': 'application/json' }, 'json', false, 'response');

  savePhoneNumber = (phone: string) => this.configService.put(`api/v2/recognition/profile/phone?phoneNumber=${phone}`, null);

  verifyPhoneNumber = (otp: string) => this.configService.put(`api/v2/recognition/verify/profile/phone?otp=${otp}`, null);

  addSaveForLater = (productAttributeIdentifier) => this.configService.post(`api/v2/wallet-marketplace/saveforlater/product/${productAttributeIdentifier}/add`, null);

  deleteSaveForLater = (productId: number) => this.configService.delete(`api/v2/wallet-marketplace/saveforlater/product/${productId}/delete`, null)

  getSavedForLaterProducts = (params) =>  this.configService.get('api/v2/wallet-marketplace/saveforlater/product/get', params);
    
  requestWalletFund = (fromData, paylod) =>  this.configService.post('api/v2/wallet-marketplace/fund/request', fromData, {});
  
  getUserWallet = () =>  this.configService.get("api/v2/user/wallet");
}