import { createAction, createFeatureSelector, createReducer, on, props } from "@ngrx/store";
import { MostUsedTags, RewardAndRecognitionReceiver } from "./leaderboard.model";

export interface RnRewardLeaderboardYtdUserDtoState {
    topRewardReceivers: RewardAndRecognitionReceiver[];
    topRecognitionReceivers: RewardAndRecognitionReceiver[];
    mostUsedTags: MostUsedTags[],
    isLoaded: boolean
}

export const setLeaderboardFeed = createAction('[Leaderboard] Set Feed', props<RnRewardLeaderboardYtdUserDtoState>());

const initialLeaderboardState: RnRewardLeaderboardYtdUserDtoState = { topRewardReceivers: [], topRecognitionReceivers: [], mostUsedTags: null, isLoaded: false };

export const leaderboardReducer = createReducer(
    initialLeaderboardState,
    on(setLeaderboardFeed, (state, payload) => ({
        ...state,
        ...payload,
    }))
);

export const selectLeaderboardState = createFeatureSelector<RnRewardLeaderboardYtdUserDtoState>('leaderboard');