import { BusinessGoalActionTypes, BusinessGoal } from './business.goal.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface BusinessGoalState extends EntityState<BusinessGoal> {
}

export const buisnessGoalAdapter: EntityAdapter<BusinessGoal> = createEntityAdapter<BusinessGoal>({
  selectId: (businessGoal: BusinessGoal) => businessGoal.identifier
});

const intialBusinessGoalState = buisnessGoalAdapter.getInitialState();

export function businessGoalReducer(state = intialBusinessGoalState, action): BusinessGoalState {

  switch (action.type) {

    case BusinessGoalActionTypes.BusinessGoalLoaded:

      return buisnessGoalAdapter.addMany(action.payload.goalList, { ...state });

    default:
      return { ...state };
  }
}

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal
} = buisnessGoalAdapter.getSelectors();



