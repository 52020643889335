import * as Forge from 'node-forge';

export class RsaEncryptionClass {

  static encrypted(key: string, valueToEncrypt: string) {
    if (key && valueToEncrypt && valueToEncrypt.toString().trim()) {
      const rsa = Forge.pki.publicKeyFromPem(key);
      return btoa(rsa.encrypt(valueToEncrypt.toString()));
    }
  };
}


