import { Component, Inject, OnInit, Injectable, HostListener, OnDestroy } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ToastCloseService } from '../toaster.close.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SnackbarAction, SnackbarData } from '../../../modals/snackbar';
import { ApiService } from '../../api.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FrostedService } from '../../frosted/frosted.service';
import { HrSystemDisconnectConfirmationComponent } from '../../../common/hrSystem.disconnect.confirm.dialog';
import { ZendeskService } from '../../zendesk';
import { AdminPageRoute } from '../../../common/page.route';

@Injectable({ providedIn: 'root' })
@Component({
  selector: 'show-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})

export class ToastComponent implements OnInit, OnDestroy {
  private isDebug = environment.isDebug;
  private toastCloseService$: Subscription;
  public snackbar: SnackbarData;
  processing: boolean;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData, public snackBarRef: MatSnackBarRef<ToastComponent>, public router: Router, private toastCloseService: ToastCloseService, private _zendeskService: ZendeskService, private apiService: ApiService, private frostedService: FrostedService, private dialog: MatDialog) {
    this.snackbar = data;
    this._zendeskService.toggleZendeskOnToaster(true);
  }

  ngOnInit() {
    this.toggleZendeskChat(true);
    this.setToasterCloseValueDefault();
    this.closeToasterManually();
  }

  ngOnDestroy() {
    if (this.toastCloseService$) { this.toastCloseService$.unsubscribe(); }
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.closeToaster();
    }
  }

  setToasterCloseValueDefault() {
    this.toastCloseService.setCloseToastValue(false);
  }

  closeToasterManually() {
    this.toastCloseService$ = this.toastCloseService.getCloseToastValue()
      .subscribe((value) => {
        if (value) {
          this.closeToaster();
        }
      });
  }

  closeToaster() {
    this.snackBarRef.dismiss();
    this.toggleZendeskChat(false);
  }

  toggleZendeskChat(hideChat: boolean) {
    hideChat = !this.isDebug ? hideChat : true;
    this._zendeskService.toggleZendeskOnToaster(hideChat);
  }

  callSnackbarAction(actionType: SnackbarAction): void {
    switch (actionType) {
      case SnackbarAction.DisconnectGusto:
        this.disconnectGusto();
        break;
    }
  }

  private disconnectGusto() {
    this.snackbar.actionType = null;
    this.processing = true;

    this.frostedService.setFrostedBackgroundValue(true);
    let dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.height = '222px';
    dialogConfig.maxHeight = '222px';
    dialogConfig.width = '417px';
    dialogConfig.maxWidth = '417px';
    let reference = this.dialog.open(HrSystemDisconnectConfirmationComponent, dialogConfig);

    reference.afterClosed()
      .subscribe((response) => {
        this.router.navigate([AdminPageRoute.Home]);
        this.frostedService.setFrostedBackgroundValue(false);
      });
  }
}
