import { createReducer, on } from "@ngrx/store";
import Account from "../../modals/account";
import { set_account_details, update_account_details } from "../actions/account.action";

export const initialAccountState: Account = {
    accountId: 0,
    accountStatus: null,
    companyLogo: "",
    accountName: "",
    inviteTypesAllowed: [],
    feedStatsWidgetVisible: false,
    isAccountSettingsEnabled: false,
    isChannelPartnerAccount: false,
    isInternationalEnabled: false,
    isWalletRedemptionAllowed: false,
    isWishlistLabAllowed: false,
    isHrSystemEnabled: false,
    isSpotRewardAllowed: false,
    isCreditCardEnabled: false,
    hasCustomSurveys: false,
    isCustomRewardEnabled: false,
    isDefaultUserRoleEnabled: false,
    defaultRoles: [],
    accountLaunchDateUtc: "",
    isRecognitionEnabled: false,
    isSeeBudgetAllowed: false,
    setAlternateEmail: false,
    isSpotRewardAllowedForSender: false,
    isSpotProgramSelectionEnabled: false,
    isOnboardingSurveyRequired: false,
    hasRewardSelfService: false,
    isLeaderBoardEnabled: false,
    isRewardSpecificityAllowed: false,
    isRecognitionAiEnabled: false,
    cpType: null,
    accountSecurityKey: null,
    isSmsLoginEnabled: false,
    isPrivacyPolicyAccepted:true,
}

const _accountReducer = createReducer(
    initialAccountState,
    on(set_account_details, (state, props) => { return props }),
    on(update_account_details, (state, props) => { return { ...state, ...props } }),
);

export function AccountReducer(state, action) {
    return _accountReducer(state, action);
}