import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { FilteringActionType } from './filtering.actions';
import { RecognitionTag } from '../../../../modals/recogntion.tags';


export interface TagSearchedState extends EntityState<RecognitionTag> {
  loading?: boolean;
  loaded?: boolean;
  errorMessage?: string;
}

export const tagSearchStateAdapter: EntityAdapter<RecognitionTag> = createEntityAdapter<RecognitionTag>({
  selectId: (tag: RecognitionTag) => tag.identifier
});

const initialTagSearchedState = tagSearchStateAdapter.getInitialState({
  loaded: false
});

export function tagSearchStateReducer(state = initialTagSearchedState, action): TagSearchedState {
  switch (action.type) {
    case FilteringActionType.TagSearchRequested:
      return { ...state, loading: true }

    case FilteringActionType.TagSearchCompleted:
      return tagSearchStateAdapter.addMany(action.payload.data, { ...state, loading: false });
    default:
      return { ...state }
  }
}

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal
} = tagSearchStateAdapter.getSelectors();
