<div class="snack-bar" [ngClass]="{'login-snack-bar' : snackbar.isLoginPage}">
  <div class="snack-bar-message" [ngClass]="{'button-enabled' : snackbar.actionType && snackbar.actionType != Okay && snackbar.actionType != Retry }">
    <span [innerHtml]="snackbar.message"></span>
  </div>
  <div class="snack-bar-actions">
    <div class="text-right">
      <div class="btn-action"
        *ngIf="snackbar.actionType && snackbar.actionType != Okay && snackbar.actionType != Retry">
        <button mat-button class="primary-btn"
          (click)="callSnackbarAction(snackbar.actionType)">{{snackbar.actionType}}</button>
      </div>
      <div class="close-action">
        <mat-icon *ngIf="!snackbar.isLoginPage; else LoginPageTemplate" class="close-icon material-icons-round"
          (click)="closeSnackbar();">close</mat-icon>
        <ng-template #LoginPageTemplate>
          <div class="pointer" (click)="dismissSnackBar()">{{snackbar.actionType ? snackbar.actionType : ''}}</div>
        </ng-template>
      </div>
    </div>
  </div>
</div>