import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })

export class ZendeskService {

  manageZenDeskChatService(isDebug: boolean) {
    if (isDebug) {
      try {
        let ze = window['zE'];
        if (ze) {
          ze('webWidget', 'hide');
        }
        else {
          throw new Error("");
        }
      } catch (e) {
        setTimeout(() => {
          let ze = window['zE'];
          if (ze) {
            ze('webWidget', 'hide');
          }
        }, 2000)
      }
    }
    else {
      try {
        let ze = window['zE'];
        if (ze) {
          ze('webWidget', 'show');
        }
        else {
          throw new Error("");
        }
      } catch (e) {
        setTimeout(() => {
          let ze = window['zE'];
          if (ze) {
            ze('webWidget', 'show');
          }
        }, 2000)
      }
    }
  }

  toggleZendeskOnToaster(isHide: boolean) {
    if (isHide) {
      try {
        let ze = window['zE'];
        if (ze) {
          ze('webWidget', 'hide');
        }
        else {
          throw new Error("");
        }
      } catch (e) {
        setTimeout(() => {
          let ze = window['zE'];
          if (ze) {
            ze('webWidget', 'hide');
          }
        }, 1000);
      }
    }
    else {
      try {
        let ze = window['zE'];
        if (ze) {
          ze('webWidget', 'show');
        }
        else {
          throw new Error("");
        }
      } catch (e) {
        setTimeout(() => {
          let ze = window['zE'];
          if (ze) {
            ze('webWidget', 'show');
          }
        }, 1000);
      }
    }
  }
}